.salesman-customers-page {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .hv {
      flex: 1 1 auto;
    }
    .custom-control {
      flex: 0 0 auto;
      margin-left: auto;
    }
  }

  .table-app {
    .btn {
      .app-icon {
        margin-right: $spacer / 2;
      }
    }

    .late_payment {
      & > .yes {
        color: $danger;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .customer-salesmen-item {
      display: block;
    }

    .city,
    .country,
    .account-type {
      width: 140px
    }

    .business-name,
    .action-type,
    .store-code {
      width: 200px;
    }
  }
}


.salesman-choice-order-mode-modal {
  .actions {
    .btn {
      .app-icon {
        margin-right: $spacer / 2;
      }
    }

    .btn + .btn {
      margin-left: $spacer / 2;
    }
  }
}

.salesman-home-page {

  .card-body {
    padding: $card-spacer-x * 2 $card-spacer-x;
  }

  .member-area-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & > .menu-item {
      flex: 1 0 0;
      margin-left: $grid-gutter-width / 2;
      margin-right: $grid-gutter-width / 2;
    }
  }
}

.add-prospect-modal {
  section {
    margin-bottom: $spacer * 2;

    h6 {
      margin-bottom: $spacer;
      text-transform: uppercase;
    }
  }

  .modal-body {
    & > .row {
      & > [class*="col-"],
      & > .col {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
      }
    }
  }
}

.salesman-carts-page {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .hv {
      flex: 1 1 auto;
    }
    .custom-control {
      flex: 0 0 auto;
      margin-left: auto;
    }
  }

  .table-app {
    .btn {
      .app-icon {
        margin-right: $spacer / 2;
      }
    }

    .late_payment {
      & > .yes {
        color: $danger;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .city,
    .country,
    .account-type {
      width: 140px
    }

    .action-type {
      width: 90px;
    }

    .business-name,
    .store-code {
      width: 200px;
    }
  }
}

.callout-salesman {
  .actions {
    margin: $spacer 0 $spacer / 2 0;
    text-align: center;

    button {
      display: block;
      width: 100%;
      margin-bottom: $spacer;

      .app-icon {
        font-size: $font-size-base;
        margin-right: .4rem;
      }

      @include media-breakpoint-up(sm) {
        display: inline-block;
        margin-bottom: 0;
        width: auto;

        & + button {
          margin-left: 1rem;
        }
      }
    }
  }
}