#member-area-row-top .btn-back {
  text-decoration: none;
  .app-icon {
    margin-right: $spacer / 2;
  }
}

.member-area {
  padding-bottom: $spacer;

  .member-area-sidebar {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }

    .member-area-menu {
      background-color: $gray-dark;

      .menu-item {
        background-color: transparent;
      }


      @include media-breakpoint-up(md) {
        max-width: 146px;
      }
    }
  }

  .account-banner {
    margin-top: $spacer;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .member-card {
    border-radius: $border-radius;
    border-color: transparent;
    box-shadow: $box-shadow;

    .card-header {
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
      }
    }
    .card-header,
    .card-footer {
      background-color: transparent;
      border-bottom: none;
      border-top: none;
      padding-bottom: 0;
    }

    .card-footer {
      padding-top: 0;
      padding-bottom: $spacer;
    }
  }

  .customer-dashboard {
    .customer-last-orders {
      margin-top: $spacer * 2;
    }
  }

  .statistics-yearly-stats-card {
    margin-bottom: $spacer * 2;

    .card-body {
      .list {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > .card-stat {
          max-width: 240px;
          flex: 1 1 240px;
          background-color: $hawkes-blue;
          margin-left: $grid-gutter-width / 2;

          &:first-of-type {
            margin-left: 0;
          }
          &:nth-child(even) {
            background-color: $pale-turquoise;
          }
        }
      }
    }
  }

  .card-stat {
    border-radius: $border-radius * 2;

    & > .card-body {
      padding: $card-spacer-y * 1.5 $card-spacer-x !important;
    }

    .value {
      font-size: 1.6rem;
      margin-bottom: 0;
    }
  }
}

.no-sidebar {
  .member-area {
    .member-area-sidebar {
      display: none;
    }
  }
}
.picture-export-table,
.order-table,
.invoice-table {
  .actions {
    text-align: center;
    padding: 0.55rem;

    .btn {
      text-decoration: none;
      color: lighten($text-color, 20%);
      font-size: 1.2rem;
      padding: 0;

      + .btn {
        margin-left: $spacer * 0.8;
      }

      @include hover-focus-active() {
        color: $text-color;
      }
    }
  }

  .cell-order {
    .link {
      display: block;
      cursor: pointer;
      color: $primary;
      text-decoration: underline;
    }
  }
}

.invoice-table {
  .invoice-state {
    font-weight: bold;
    &.wait {
      color: #ff9c00;
    }
    &.late {
      color: #ff6060;
    }
    &.paid {
      color: #5ab73a;
    }
  }
}

.helper-card {
  border: 0 none;

  .card-title {
    color: $primary;

    .app-icon {
      margin-right: $spacer / 2;
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    list-style-position: inside;
  }
}
