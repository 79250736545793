//.product-detail-modal + .modal-backdrop + .planogram-detail-modal {
//  z-index: 1048;
//}

.planogram-detail {
  .planogram-detail-content {
    & >div:last-child {
      margin-top: $spacer;
    }
  }

  .planogram-name {
    font-size: 1.2em;
    font-weight: 600;
  }

  .planogram-image-section {
    .planogram-image-slide-thumb {
      cursor: pointer;
      img {
        border: 1px solid transparent;
        transition: border-bottom-color ease-in $animate-css-animation-duration,
        border-left-color ease-in $animate-css-animation-duration,
        border-right-color ease-in $animate-css-animation-duration,
        border-top-color ease-in $animate-css-animation-duration
      ;
      }

      @include hover-focus-active() {
        img {
          border-color: $primary;
        }
      }

      &.swiper-slide-thumb-active {
        img {
          border-color: $primary;
        }
      }
    }

    button {
      box-shadow: none;
      outline: none;
    }

    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-left");
          color: $text-color;
          font-size: 1.8em;
        }
      }

      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-right");
        }
      }
    }

    .swiper-thumbs {
      .swiper-button-prev,
      .swiper-button-next {
        height: calc(var(--swiper-navigation-size) / 44 * 27);
        transform: translateY(25%);
        &.swiper-button-disabled {
          display: none!important;
        }
      }
    }

    &-multiple {
      .swiper {
        .swiper-button-prev,
        .swiper-button-next {
          display: inline-block;
        }
      }
    }

    .copyright {
      margin-top: 1rem;
      font-size: 12px;
      line-height: 1.2;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .planogram-image {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
      background-size: 80%;
    }

    .swiper {
      height: 100%;

      .swiper-button-prev,
      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-left");
          color: $text-color;
          font-size: 1.8em;
        }
      }

      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-right");
        }
      }
    }

    &+.swiper {
      &-initialized {
        margin-top: $spacer;
      }
    }

    &-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      img {
        &.swiper-lazy {
          transition: opacity ease-in $animate-css-animation-duration;
          animation-duration: $animate-css-animation-duration;
          animation-fill-mode: both;
          opacity: 0;

          &-loaded {
            opacity: 1;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      &-detail {
        border: 8px solid $gray-100;
      }
    }
  }

  .btn-planogram-image-zoom {
    transition: background-color ease-in $animate-css-animation-duration;
    position: absolute;
    right: $spacer / 2;
    bottom: $spacer / 2;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    z-index: 100;

    @include hover-focus-active() {
      background-color: $black;
    }
  }

  .cart-item-errors {
    margin: $spacer / 2 0;
    & > p {
      &:first-child {
        margin-top: 0;
      }

      &.invalid-feedback {
        display: block;
        margin-bottom: 0;
        font-weight: bold;
        color: darken($warning, 10%)!important;
      }
    }
  }

  &-placeholder {
    .rect-shape,
    .round-shape,
    .text-row,
    .rect-shape,
    .round-shape,
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }

    .planogram-image {
      .rect-shape {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      margin-bottom: $spacer / 2;
    }

    .text-row {
      margin-top: .2rem!important;
      max-height: .6rem!important;
      overflow: hidden!important;
    }

    .planogram-name-placeholder {
      margin-top: 0!important;
    }

    .planogram-price-placeholder,
    .planogram-reference-placeholder {
      max-width: 120px!important;
    }

    .planogram-price-placeholder {
      height: 15px!important;
      margin-bottom: 1rem;
    }

    .planogram-ean-placeholder {
      max-width: 140px!important;
    }

    .planogram-shipping-location-placeholder {
      max-width: 160px!important;
    }

    .planogram-brand-placeholder {
      height: 55px!important;
      max-width: 250px;
    }

    .planogram-quantity-placeholder {
      height: 35px!important;
    }
  }

  .btn-mass-add-to-cart {
    .app-icon {
      margin-right: $spacer / 2;
    }
  }

  .planogram-actions {
    margin-top: $spacer;
    text-align: center;
    > div {
      margin-bottom: $spacer;
      &:last-child {
        margin-bottom: 0;
      }
    }


    @include media-breakpoint-up(xl) {
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .planogram-detail-inner-section {
    margin-bottom: 1rem;

    & > .lbl {
      display: block;
      margin-bottom: $spacer / 2;
      text-transform: uppercase;

      &:after {
        content: ": ";
      }
      //margin-right: $spacer;
    }
  }

  .planogram-selector,
  .planogram-module-selector {
    .lbl {
      margin-bottom: $spacer / 2;
      text-transform: uppercase;

      &:after {
        content: ": ";
      }
    }

  }

  .restock-alert.mode-button button {
    display: inline-flex;
    flex-direction: revert;
    align-items: center;
    justify-content: center;

    .lbl {
      margin-bottom: 0;
      &:after {
        content: "";
      }
    }
  }

  .planogram-module-selector {
    .nav-tabs {
      //margin-bottom: .1rem;
      border: 0 none;

      .nav-link {
        padding: 0 0.250rem;
        background: transparent;
        border: none;

        &:first-child {
          padding-left: 0;
        }


        .planogram-module-tab-name {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          & > .module-name {
            display: block;
            cursor: pointer;
            margin-bottom: 0;
            border: 2px solid $black;
            border-radius: 50%;
            overflow: hidden;

            &.with-color {
              .name {
                display: none;
              }
            }

            &:not(.with-color) {
              .name {
                background: white;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                color: $text-color;
                font-weight: 500;
              }
            }

            .color {
              display: block;
              width: 30px;
              height: 30px;
            }
          }


          .caret {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 12px 12px 12px;
            border-color: transparent transparent $gray-300 transparent;
            display: inline-block;
            opacity: 0;
          }
        }

        &.active {
          & > .planogram-module-tab-name {
            & > .module-name {
              border-color: $primary;
            }

            & > .caret {
              opacity: 1;
              border-bottom-color: $gray-400;
              margin-top: .5rem;
            }
          }
        }
      }
    }

    .tab-pane {
      padding: $spacer $spacer / 2;
      border: 1px solid $gray-300;
      background-color: lighten($body-bg, 4%);
    }

    .planogram-module {
      .planogram-action {
        text-align: left;
      }

      .product-line {
        border-bottom: 1px solid #ccc;
        padding: $spacer / 2 0;
        margin-bottom: $spacer / 2;

        .col-attribute {
          max-width: 90px;
        }

        .col-reference {
          max-width: 90px;
        }
      }

      table {

        th, td {
          text-align: center;
          vertical-align: middle;
        }

        thead {
          .col-qty {
            text-align: left;
          }
        }

        .col-qty {
          .quantity-selector {
            margin-left: auto;
            max-width: 208px;
            min-width: 192px;

            @include media-breakpoint-down(xl) {

              &:not(.saving) {
                .quantity-selector-quantity {
                  .input-group-content {
                    .app-icon-inner {
                      display: none;
                    }
                  }
                }
              }

              &.saving {
                .quantity-selector-quantity {
                  .input-group-content {
                    position: relative;
                    .app-icon-inner {
                      position: absolute;
                      right: 10px;
                      top: 11px;
                    }
                  }
                }
              }

            }

            &.multiple {
                .quantity-selector-quantity {
                  display: flex;
                  .input-group {
                    flex: 0 0 70px;
                    width: 70px;
                  }

                  .btn-multiple {
                    flex: 0 0 auto;
                    width: auto;
                    margin-left: 1rem;
                    max-width: none;

                    .lbl {
                      display: none;
                    }
                  }
                }
            }

            &.with-packing {
              .quantity-selector-packing {
                .app-icon.equal {
                  @include media-breakpoint-down(xl) {
                    margin: 0 .3rem;
                  }
                }

                .input-group-prepend {
                  .app-icon.equal {
                    @include media-breakpoint-down(sm) {
                      margin: 0 .3rem;
                    }
                  }
                }

                .value {
                  background-color: transparent;
                }
              }
            }

            @include media-breakpoint-up(sm) {
              max-width: 260px;
            }

            &.with-price {
              @include media-breakpoint-up(sm) {
                max-width: 350px;
              }

              &.multiple {
                .quantity-selector-quantity {
                  max-width: 124px;

                  .btn-multiple,
                  & > .input-group {
                    max-width: 100%;
                  }
                }
                .quantity-selector-packing {
                  min-width: 80px;
                }

                .quantity-selector-price {
                  & > .input-group {
                    & > .form-control {
                      padding-left: 0;
                    }
                  }
                }
              }


              @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                .quantity-selector-price {
                  //flex: 1 1 auto;
                  //width: 100%;

                  .product-quantity-total-amount {
                    border-left: 0 none;
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }

        .module-product-qty {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          i {
            display: block;
          }

          span {
            font-style: italic;
          }
        }

        .catalog-image {
          width: 50px;
          border: 1px solid $gray-200;
        }

        .product-attribute {
          justify-content: flex-start;
          .lbl {
            display: none;
          }

          .value {
            font-weight: normal;
          }
        }

        .product-field {
          justify-content: flex-start;
          .product-reference {
            font-size: 1em;
            font-weight: normal;
          }
        }
      }
    }
  }

  .planogram-selector {
    .selectors {
      .form-check {
        position: relative;
        margin: .125rem .25rem .125rem 0;
        label {
          cursor: pointer;
          padding: $btn-padding-y / 2 $btn-padding-x * 1.5;
          border: 1px solid $black;
          border-radius: $border-radius;
          color: $black;
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          visibility: hidden;

          &:checked {
            //opacity: 1;
            //visibility: visible;
          }
        }

        input:checked + label {
          //border-color: $primary;
          border-color: $gray-700;
          //color: $primary;
          color: $gray-700;
        }

        @include hover-focus-active() {
          label {
            //border-color: $primary;
            border-color: $gray-700;
            //color: $primary;
            color: $gray-700;
          }
        }

        &.is-selected {
          label {
            //border-color: $primary;
            border-color: $gray-700;
            //color: $primary;
            color: $gray-700;
          }
        }

        &.not-available {
          opacity: .4;

          @include hover-focus-active() {
            opacity: 1;
          }
        }
      }
    }
  }
}

.planogram-detail-modal {
  overflow-x : visible!important;

  .modal-dialog {
    overflow : visible!important;
  }

  .planogram-scroll-buttons {
    display: none;
    position: absolute;
    right: -15px;
    top: 0;

    &-inner {
      display: flex;
      flex-direction: column;
      position: fixed;
      height: calc(100vh - #{$modal-dialog-margin-y-sm-up});
    }

    .btn-planogram-detail-scroll {
      z-index: 1035;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      outline: none;
      box-shadow: none;

      &-next {
        margin-top: auto;
        margin-bottom: $modal-dialog-margin-y-sm-up;
      }
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }

    @include media-breakpoint-up(xxxl) {
      right: -30px;
    }
  }

  .modal-header {
    border-bottom: 0 none;


    .planogram-detail-navigation {
      background: $gray-200;
      flex: 1;

      & > .row {
        & > div {
        }
      }

      .planogram-card {
        width: 50px;
      }

      button {
        padding: 0;
        background: transparent;
        border: 0 none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        outline: none;

        &.btn-prev {
          margin-right: auto;

          .app-icon,
          .lbl {
            margin-left: .5rem;
          }
        }

        &.btn-next {
          margin-left: auto;
          .app-icon,
          .lbl {
            margin-right: .5rem;
          }
        }

        .lbl {
          font-size: 0.95em;

          &:not(.abbr) {
            display: none;
          }
        }
      }

      img {
        width: 50px;
        height: 50px;
      }

      @include media-breakpoint-up(md) {
        button {
          .lbl {
            display: inline-block!important;
            font-size: 0.95em;

            &.abbr {
              display: none!important;
            }
          }
        }
      }
    }

  }

  .modal-footer {
    border-top: 0 none;
  }

  .modal-dialog {
    border-radius: 10px;
    overflow: hidden;
    max-width: 96%;

    @include media-breakpoint-up(xl) {
      max-width: 90%;
    }

    @include media-breakpoint-up(xxxl) {
      max-width: map_get($container-max-widths, 'xxl');
    }
  }

  .modal-body,
  .modal-footer,
  .modal-header {
    padding: 0;

  }

  .modal-footer,
  .modal-header {
    padding: 0;
    overflow: hidden;
    & > * {
      margin: 0;
    }
  }

  .modal-body {
    position: relative;

    .planogram-detail-content {
      padding: $modal-inner-padding;
    }

    .planogram-heading {
      padding-right: 1em;
    }

    .close {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 2rem;
      font-weight: 100;
      z-index: 100;
    }
  }

  //@include media-breakpoint-up(lg) {
  //    .modal-body {
  //      overflow: hidden;
  //    }
  //
  //    .planogram-detail {
  //    height: 100%;
  //
  //    & >.planogram-detail-content {
  //      height: 100%;
  //
  //      & > .row {
  //        height: 100%;
  //        & >div {
  //          height: 100%;
  //        }
  //
  //        .planogram-end {
  //          height: 100%;
  //          overflow-y: auto;
  //        }
  //      }
  //    }
  //  }
  //}
}

.planogram-detail-navigation-popover {
  .planogram-suggestion {
    //.catalog-image {
    //  max-width: 50px;
    //  max-height: 50px;
    //}
  }
}