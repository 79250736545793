.slide-menu {
  width: 280px;
  background-color: $white;
  padding-top: $menu-sticky-height;
  z-index: 1039;

  li {
    border-bottom: $menu-child-border-width solid $menu-child-border-color;
    //padding: $menu-mobile-link-padding-y $menu-mobile-link-padding-x;
    color: $mobile-menu-link-color;

    & > a {
      color: inherit;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: $menu-mobile-link-padding-y + $nav-link-padding-y $menu-mobile-link-padding-x + $nav-link-padding-x / 2;
    }

    &.show-all > a,
    & > .slide-menu__backlink {
      font-size: .9em;
      color: $white;
      background-color: $secondary;
      padding-top: $menu-mobile-link-padding-y + ($nav-link-padding-y / 2);
      padding-bottom: $menu-mobile-link-padding-y + ($nav-link-padding-y / 2);

      &:before {
        margin-right: .4em;
        font-family: $font-Flaticon-family;
        content: map-get($flaticon-map, "chevron-left");
      }
    }

    &.has-children {
      & > a {
        &:after {
          margin-left: auto;
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "chevron-right");
        }
      }
    }

    &.show-all {
      & > a {
        background-color: $primary;

        & > .icon {
          margin-right: $spacer / 2;
        }

        &:before {
          display: none;
        }
      }
    }

    & > .nav-link {
      & > .slide-menu__decorator {
        margin-left: auto;
      }
    }

    @include hover-focus-active() {
      border-color: $mobile-menu-hover-bg-color;
      background-color: $mobile-menu-hover-bg-color;

      & > .nav-link {
        color: $mobile-menu-hover-color;
      }
    }

    &.menu-item {
      &.menu-item-discounts {
        .nav-link {
          span {
            color: $primary;
          }

          @include hover-focus-active() {
            span {
              color: darken($primary, 5%);
            }
          }
        }
      }
    }
  }

  .slide-menu__backlink {
    text-transform: uppercase;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1038;
  }

  @include media-breakpoint-up(md) {
    padding-top: $menu-sticky-height * 1.2;
  }

  @include media-breakpoint-up(md) {
    .member-salesman & {
      padding-top: $menu-sticky-height * 1.8;
    }
  }
}
