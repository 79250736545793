.mobile-side-menu {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-head {
    display: none;
  }

  &-body {
    flex: 1 1 auto;
  }

  .mobile-menu {
    height: 100%;

    &-inner {
      height: 100%;
      overflow: hidden;
    }

    &-page-list {
      transition: transform $animate-css-animation-duration;
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 560px;

      > div {
        flex: .5 1 50%;
        width: 50%;
        height: 100%;
      }

      &.show-next-page {
        transform: translateX(-280px);
      }
    }

    .mobile-menu-page-2 {
      .btn-back {
        margin-bottom: 0;
        text-align: left;
        padding-left: $card-spacer-x;
      }

      .product-list-family-tree {
        & > .family-tree-entry:not(.family-tree-entry-heading) {
          padding: 0 $grid-gutter-width / 2;
        }
      }

      .family-tree-entry {

        &-heading {
          & > span {
            text-indent: 0;
          }
        }

        &-shipping_location {

          & > a {
            line-height: 1.2;
          }

          .app-icon {
            display: inline-block;
            text-indent: 0;
          }
        }
      }
    }

    .navbar-nav {
      & > .nav-item {
        border-bottom: $menu-child-border-width solid $menu-child-border-color;
        padding: $menu-mobile-link-padding-y $menu-mobile-link-padding-x;
        color: $mobile-menu-link-color;

        & > .nav-link {
          color: inherit;
        }

        @include hover-focus-active() {
          border-color: $mobile-menu-hover-bg-color;
          background-color: $mobile-menu-hover-bg-color;

          & > .nav-link {
            color: $mobile-menu-hover-color;
          }
        }

        &.open,
        &.active {
          background-color: $mobile-menu-selected-bg-color;
          .nav-link {
            color: $mobile-menu-selected-color;
          }
        }

        &.nav-item-category.has-children,
        &.nav-item-tree {
          & > .nav-link {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            &:after {
              content: "\f114";
              margin-left: auto;
              display: block;
              font-family: "Flaticon";
              font-style: normal;
              font-weight: normal;
              font-feature-settings: normal;
              font-variant: normal;
              line-height: 1;
              text-decoration: inherit;
              text-rendering: optimizeLegibility;
              text-transform: none;
              font-smoothing: antialiased;
            }
          }
        }

        &.nav-item-category.has-children {
          & > .nav-link {
            &:after {
              content: "\f11a";
            }
          }

          & > .link-item-children {
            display: none;
          }

          &.open {
            & > .nav-link {
              &:after {
                content: "\f11b";
              }
            }
            & > .link-item-children {
              display: block;
            }
          }
        }
      }
    }
  }
}