#page-wrap {
  padding-top: $menu-sticky-height;

  @include media-breakpoint-up(md) {
    padding-top: $menu-sticky-height-md;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $menu-desktop-base-height;
  }

  &.salesman,
  &.customer-multi_store {
    @include media-breakpoint-up(md) {
      padding-top: $menu-sticky-height-md * 1.5;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $menu-desktop-base-height * 1.3;
    }

    @include media-breakpoint-up(xl) {
      padding-top: $menu-desktop-base-height * 1.3;
    }

  }

  .salesman-layout & {
    @include media-breakpoint-up(md) {
      padding-top: $menu-sticky-height-md;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $menu-desktop-base-height;
    }

    @include media-breakpoint-up(xl) {
      padding-top: $menu-desktop-base-height / 1.4;
    }
  }

  .salesman-layout.selected-customer & {
    @include media-breakpoint-up(md) {
      padding-top: $menu-desktop-base-height;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $menu-desktop-base-height * 1.5;
    }

    @include media-breakpoint-up(xl) {
      padding-top: $menu-desktop-base-height * 1.4;
    }
  }
}

html.cart-list-banner-show {
  #page-wrap {
    padding-bottom: 90px;
    @include media-breakpoint-up(md) {
      padding-bottom: 52px;
    }
  }
}