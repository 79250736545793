.product-search {
  margin-bottom: $spacer;

  .search-by-buttons {
    margin-top: $spacer / 4;
    position: static;
    .btn {
      .app-icon {
        margin-right: $spacer / 4;
      }
    }

    & > [class*="col-"],
    & > .col {
      & > div {
        width: 100%;
        position: static;
      }
    }
  }

  .btn-product-search-toggle {
    display: inline-block;
    line-height: 1;
    border: 0 none;
    background-color: transparent;
    font-size: 1.45em;
    padding: 0;
    margin-left: $spacer;

    .close {
      display: none;
      font-size: 2.1rem;
      font-weight: 300;
      opacity: 1;
    }
  }

  .product-search-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-search-suggestion {
    position: static;

    .row {
      & > [class*="col-"],
      & > .col {
        position: static;
      }
    }
  }

  .btn-submit {
    height: 100%;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $white;
    border: 1px solid $input-border-color;
    border-left: 0 none;
  }

  .form-control {
    border-right-width: 0;
  }

  .btn,
  .form-control {
    outline: none;
    box-shadow: none;
    border-color: $input-border-color;
  }

  .btn-submit {
    max-width: 38px;
  }

  .btn-reset {
    border-right: 0;
    border-radius: 0;
  }

  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .product-search-inner {
    animation-duration: $animate-css-animation-duration * 2;
    animation-fill-mode: both;
    transition: height linear $animate-css-animation-duration;
    transition-delay: $animate-css-animation-duration * 2;
    position: fixed;
    top: $menu-sticky-height;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gray-200;
    overflow: hidden;
    height: 0;

    display: flex;
    flex-direction: column;

    form {
      flex: 0 0 auto;
      transition: box-shadow linear $animate-css-animation-duration;
      box-shadow: 0 0 0 0 transparent;
    }

    .product-search-result {
      overflow: hidden;
      overflow-y: scroll;

      & > div {
        padding: $spacer;
      }
    }

    .form-row {
      margin-left: 0;
      margin-right: 0;

      & > div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .product-search-result-inner {
      .row {
        & > div {
          margin-bottom: $spacer;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .product-card,
    .family-card {
      cursor: pointer;
    }

    .family-card {
      transition: all 0.2s ease-in-out;
      border: 1px solid transparent;
      padding: $btn-padding-y 0;
      .value {
        display: block;
      }

      @include hover-focus-active() {
        margin-left: 4px;
      }
    }

    .product-card {
      .product-heading,
      .product-name {
        margin: 0;
      }

      .breadcrumb {
        flex-wrap: nowrap;
        margin-top: .2rem;
        padding: 0;

        &-item {
          white-space: nowrap;
          display: flex;
          min-width: 0;
          a {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: $gray-600;
          }
        }
      }
    }
  }

  &.open {

    .btn-product-search-toggle {
      .search {
        display: none;
      }

      .close {
        display: block;
      }
    }

    .product-search-inner {
      height: auto;
      animation-name: fadeInUp;
      overflow: visible;

      form {
        box-shadow: $box-shadow-lg;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .product-search-suggestion {
      position: relative;
    }

    .product-search-inner {
      top: 132px;
    }
  }

  @include media-breakpoint-up(lg) {

    .product-search-inner {
      overflow: visible;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      height: auto;
      display: block;

      form {
        box-shadow: 0 0 0 0 transparent;
      }

      .product-search-result {
        position: absolute;
        top: 50%;
        left: 0;
        background-color: white;
        z-index: 15;
        max-height: 50vh;
      }
    }

    .btn-product-search-toggle {
      display: none;
    }

    &.open {
      .product-search-inner {
        form {
          box-shadow: 0 0 0 0 transparent;
        }
      }
    }

    &.has-families {
      .product-search-inner {
        .product-search-result {
          width: 100%;
        }
      }
    }

    &.has-products {
      .product-search-inner {
        .product-search-result {
          width: 100%;
        }
      }

      &.has-families {
        .product-search-inner {
          .product-search-result {
            width: 800px;

            .product-search-result-inner {
              & > .row {

                & > [class*="col-"],
                & > .col {
                  padding-left: $grid-gutter-width;
                  padding-right: $grid-gutter-width;
                }

                & > div:first-child {
                  border-right: 1px solid $gray-300;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-search-by-modal {

  & > .modal-dialog {

    @include media-breakpoint-up(xl) {
      max-width: map-get($container-max-widths, 'xl');
      .modal-footer,
      .modal-body {
        padding-left: $modal-dialog-margin * 8;
        padding-right: $modal-dialog-margin * 8;
      }
    }
  }

  .modal-header,
  .modal-body {
    background-color: $gray-100;
  }

  .alert {
    margin-top: $spacer;

    p {
      margin-bottom: 0;
    }
  }

  .modal-header {
    border-bottom: 0 none;
    .modal-title {
      margin-left: auto;
      margin-right: auto;
      font-size: $h2-font-size;
    }
  }

  .quantity-selector {
    &.multiple {
      .btn-multiple {
        max-width: 120px;
      }
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 2rem;
    font-weight: 100;
    z-index: 100;
  }

  .help {

    .title {
      color: $primary;
      text-transform: uppercase;
      text-indent: 26px;

      .app-icon {
        font-size: .9rem;
      }
    }

    ol {
      & > li {
        &::marker {
          font-weight: bold;
        }
      }
    }
  }

  .product-search-item {
    margin: $spacer 0;
    padding-top: $spacer;
    border-top: 1px solid $gray-300;

    &:first-of-type {
      margin-top: 0;
      padding-top: 0;
      border-top: 0 none;
    }

    .product-quantity-placeholder {
      margin-left: auto;
      max-width: 156px;
    }

    @include media-breakpoint-up(md) {
      .col-image {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 180px;
      }
    }

    .favorite {
      display: inline-block;
      &:not(.checked) {
        color: $text-color;
      }

      .app-icon {
        margin-left: $spacer / 2;
      }

    }

    .product-quantity-action {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-down(sm) {
        margin-top: $spacer;

        .product-action,
        .quantity-selector {
          margin-left: 0!important;
        }

        .product-action .favorite {
          justify-content: flex-start;
          text-align: left;
        }
      }

      .restock-alert {
        &.mode-button {
          .app-icon {
            font-size: 1.1rem;
            margin-right: .2rem!important;
          }
        }
      }
    }

    .product-action {
      margin-left: auto;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      .btn {
        padding: 0;
        color: $text-color;
        text-transform: uppercase;
        text-decoration: underline;
      }

      .btn,
      .favorite {
        text-align: right;
        justify-content: flex-end;
        width: 100%;
      }

      .favorite {
        margin-top: $spacer / 2;
      }
    }

    .product-content {
      max-width: 100%;

      .lbl {
        width: 170px;
      }

      .product-field {
        &-reference,
        &-ean {
          .lbl {
            text-transform: uppercase;
          }
        }
      }

      .product-price-list {
        margin-top: $spacer / 4;
        font-weight: bold;
        .price {
          margin-left: 0;
        }
      }

      @include media-breakpoint-up(md) {
        max-width: 280px;
      }
    }

    .product-info {
      margin-top: $spacer;
      display: table;

      & > div {
        display: table-row;

        .lbl,
        .value {
          display: table-cell;
          font-weight: normal;
          font-size: .8rem;
        }
      }
    }
    .product-packing {
      .lbl {
        &:after {
          content: " : ";
          font-size: .85em;
          margin-right: .5rem;
          margin-left: .2rem;
        }
      }
    }

    &.product-search-item-portrait {
      .quantity-selector {
        margin: $spacer 0;
      }

      .col-image {
        max-width: 220px;
      }

      .product-action {
        margin-top: $spacer / 2;
        flex-direction: row;
        .btn {
          text-align: left;
          margin-right: auto;
        }

        .favorite {
          margin-left: auto;
        }
      }
    }
  }

  .modal-body {
    padding-bottom: $modal-header-padding-y * 2;
  }

  .modal-footer {
    display: block;

    .exit-search {
      margin: $spacer * 1.5;
      text-align: center;

      .btn {
        padding: $btn-padding-y-lg $btn-padding-x-lg * 3;
      }
    }

    .title {
      text-align: center;
    }
  }

  &.product-search-by-barcode-modal {

    .help {
      @include media-breakpoint-up(lg) {
        height: 100%;
        padding-right: $spacer * 3;
        border-right: 1px solid $black;
      }
    }

    .placeholder {
      .or {
        display: none;
        font-size: 1.1rem;
        text-transform: uppercase;
        position: absolute;
        left: 0;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      .title {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: $spacer;
      }

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
    }

    .footer {
      text-align: center;

      .title {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 1.1em;
      }

      .app-icon {
        margin-right: $spacer;
        font-size: 2rem;
      }
    }

    .previous-products {
      margin-top: $spacer * 2;
      padding-top: $spacer * 2;
      border-top: 1px solid $gray-400;

      .hv {
        margin-bottom: $spacer * 2;
      }
    }

    .user-form {
      margin-bottom: $spacer * 1.5;

      .heading {
        margin-bottom: $spacer * 2;
        text-align: center;

        .title {
          margin-bottom: $spacer / 3;
        }

        .subtitle {
          font-size: 1.1em;
          text-transform: uppercase;
        }
      }

      .title {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: $spacer;
      }

      form {
        margin-left: auto;
        margin-right: auto;
        max-width: 440px;
        .form-control {
          letter-spacing: 10px;
          text-align: center;
        }

        .input-group-text {
          .btn {
            border-width: 0;
          }
        }
      }
    }

    &:not(.fetching) {
      .user-form {
        form {
          .input-group-text {
            padding: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  &.product-search-by-image-modal {
    .preview-section {
      color: $gray-600;
      background-color: $white;
      padding: 0.5rem 2rem;
      margin:0 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        text-align: center;
        text-transform: uppercase;
      }

      .preview-inner {
        max-width: 200px;
        max-height: 200px;
        overflow: hidden;
      }

      .btn-link {
        color: $gray-600;
        font-size: 0.95rem;
        text-decoration: underline;
      }
    }

    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: $gray-400;
      border-style: dashed;
      background-color: $gray-100;
      color: $gray-600;
      outline: none;
      transition: border .24s ease-in-out;

      @include media-breakpoint-up(lg) {
        height: 100%;
      }

      .dropzone-info {
        margin-top: $spacer / 2;
      }

      .invalid-feedback {
        display: block;
      }

      .dropzone-message {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;

        .btn {
          margin-top: $spacer / 2;
        }
      }

      p {
        margin-bottom: 0;
      }

      &.active,
      &:hover {
        border-color: $primary;
        color: $primary;

        .btn {
          border-color: $primary;
          color: $primary;

          @include hover-focus-active() {
            background-color: $white;
          }
        }
      }

      &:disabled {
        opacity: 0.6;
      }
    }
  }
}