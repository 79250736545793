.table {
  thead {
    background-color: $gray-100;

    th, td {
      text-align: center;
      text-transform: uppercase;
      font-size: .8rem;
      border-bottom: 2px solid darken($gray-100, 2%);
    }
  }

  tbody {
    td {
      text-align: center;
    }
  }

  .placeholder {
    .rect-shape,
    .round-shape,
    .text-row,
    .rect-shape,
    .round-shape,
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }

    .text-row {
      min-width: 140px;
    }

    .btn-fake {
      max-height: 44px;
    }

    .btn-fake + .btn-fake {
      margin-top: $spacer;
    }
  }
}

.table-app-section {
  position: relative;

  .table-app {

    .table-app-loader {
      text-align: center;
    }

    .clickable {
      cursor: pointer;
    }

    th, td {
      padding: $table-cell-padding;
      vertical-align: middle;
    }

    thead {
      background-color: $gray-100;

      th, td {
        text-transform: uppercase;
        font-size: .8rem;
        border-bottom: 2px solid darken($gray-100, 2%);

        .form-group {
          margin-bottom: 0;
          padding-bottom: 0;
          position: relative;
        }

        .filter {
          .app-icon {
            display: none;
            position: absolute;
            right: 0.375rem;
            top: 9px;
            font-size: 1.1rem;
          }

          .form-control.fetching ~ .app-icon {
            display: block;
          }
        }
      }

      .sort-by {
        font-size: 1rem;
        margin-left: $spacer / 3;
        line-height: 1;
        display: inline-block;

        .btn-sort {
          padding: 0;
        }

        .app-icon {
          color: $gray-500;

          &.active {
            color: $black;
          }
        }
      }
    }

    &-filter-row {
      th, td {
        input, select,textarea {
          border: 0 none;
          border-radius: 0;
        }

        .filter-date-range {

          button {
            border-left: 0 none;
          }

          .css-17wfq1-bodyStyles,
          .css-v32714-getStyles-getStyles,
          .css-1dhcrsq-CalendarMenu-CalendarMenu-CalendarMenu {
            color: $text-color;
          }

          .css-1yo0gey-getStyles-getStyles,
          .css-5xrb76-getStyles-getStyles-getStyles {
            color: $gray-700;
          }

          .css-ou1yd2-getStyles-getStyles-getStyles {
            background-color: darken($primary, 20%);
            color: $white;
          }

          .css-yb5eat-getStyles-getStyles {
            background-color: $primary;
            color: $white;
          }

          .css-4800fw-getInputContainerStyles,
          .css-f6fixk-getInputContainerStyles {
            background-color: $input-bg;
            background-clip: padding-box;
            border: 0 none;
            height: $input-height;

            @include border-radius($input-border-radius, 0);

            &:focus-within {
              box-shadow: none;

              @include box-shadow($input-box-shadow);

              border-color: transparent;
            }
          }

          input {
            display: block;
            width: 100%;
            height: $input-height;
            padding: $input-padding-y $input-padding-x;
            font-family: $input-font-family;
            @include font-size($input-font-size);
            font-weight: $input-font-weight;
            line-height: $input-line-height;
            color: $input-color;
            background-color: $input-bg;
            background-clip: padding-box;
            border: 0 none;

            @include transition($input-transition);

            // Unstyle the caret on `<select>`s in IE10+.
            &::-ms-expand {
              background-color: transparent;
              border: 0;
            }

            // Remove select outline from select box in FF
            &:-moz-focusring {
              color: transparent;
              text-shadow: 0 0 0 $input-color;
            }

            // Customize the `:focus` state to imitate native WebKit styles.
            @include form-control-focus($ignore-warning: true);

            // Placeholder
            &::placeholder {
              color: $input-placeholder-color;
              // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
              opacity: 1;
            }

            // Disabled and read-only inputs
            //
            // HTML5 says that controls under a fieldset > legend:first-child won't be
            // disabled if the fieldset is disabled. Due to implementation difficulty, we
            // don't honor that edge case; we style them as disabled anyway.
            &:disabled,
            &[readonly] {
              &:not(.flatpickr-input) {
                background-color: $input-disabled-bg;
              }
              // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
              opacity: 1;
            }
          }
        }

        input[type="text"],
        input[type="number"],
        input[type="search"],
        input[type="date"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],
        input[type="time"],
        input[type="email"] {
          &.fetching {
            &:after {

            }
          }
        }
      }

      &-top {
        margin-bottom: $spacer;
      }
    }

    tbody {
      position: relative;

      .loader-row {
        padding: 0;
        margin: 0;

        td, th {
          padding: 0;
          margin: 0;
        }
      }

      .table-app-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($gray-300, .5);
        z-index: 999;
      }
    }

    tfoot {
      .pagination {
        margin-top: $card-spacer-y;
        justify-content: center;
        margin-bottom: 0;
      }
    }


    &.loading {
      thead {
        th, td {
          .filter {
            .form-control.fetching ~ .app-icon {
              display: block;
            }
          }
        }
      }
    }
  }
}