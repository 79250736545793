.mass-add-to-cart-modal {

  .modal-header {
    background-color: $gray-dark;
    color: $white;
    padding-top: $card-spacer-y;
    padding-bottom: $card-spacer-y;

    .modal-title {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    button {
      color: $white;
      opacity: 1;
    }

    .app-icon {
      margin-right: $spacer;
    }
  }

  .modal-content {
    border-color: $gray-dark;

    .modal-body {
      @include media-breakpoint-up(md) {
        padding-top: $modal-inner-padding * 2;
        padding-bottom: $modal-inner-padding * 2;
      }
    }
  }

  .quantity-selector {
    margin-left: 0;
  }

  .invalid-feedback {
    display: block;
  }

  .alert {
    p {
      margin-bottom: 0;
    }
  }

  &.success {
    .modal-body {
      padding: 0;

      section {
        padding: $alert-padding-y $alert-padding-x;
      }

      .success-section {
        font-weight: bold;
        text-align: center;
        background-color: lighten($success, 30%);
        color: darken($success, 20%);
      }

      .warning-section {
        background-color: #feeee7;
        .heading {
          text-align: center;
          margin-bottom: $spacer;
          .title {
            text-transform: uppercase;
          }

          .title, .subtitle {
            margin-bottom: 0;
          }
        }

        .footer {
          text-align: center;

          button {
            background: $white;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.25rem;

            .app-icon {
              font-size: $font-size-base;
              margin-right: .2rem;
            }

            .lbl {
              font-size: $font-size-base * 0.75;
              line-height: 1;
              position: relative;
              top: -2px;
            }

            @include media-breakpoint-up(md) {
              .app-icon {
                font-size: $font-size-lg;
                margin-right: .5rem;
              }

              .lbl {
                font-size: $font-size-base;
              }

              &.btn-outline-primary {
                color: darken($primary, 10%);
              }

              &.btn-outline-success {
                color: darken($success, 10%);
              }
            }

            @include hover-focus-active() {
              background: transparent;

              &.btn-outline-success {
                background-color: $white;
              }

              &.btn-outline-primary {
                border-color: darken($primary, 10%);
                background-color: $primary;
                color: $white;
              }
            }
          }
        }

        .list {
          .product-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: .5rem;
            width: 100%;

            .product-image {
              flex: 0 0 auto;
              width: 50px;
              height: 50px;
            }

            .product-heading {
              flex-basis: 0;
              flex-grow: 1;
              min-width: 0;
              max-width: 100%;

              font-size: 0.788rem;
              margin-left: $spacer;
              text-align: left;

              .product-name {
                margin-bottom: 0;
              }

              .product-field {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
}

#popover-mass-add-to-cart {
  ul {
    list-style-type: initial;

    li {
      list-style-type: initial;
      list-style-position: inside;
    }
  }

  .mass-add-to-cart-message-above {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .mass-add-to-cart-message-under {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.add-to-cart-alert {
  .btn {
    box-shadow: none!important;
  }
  .btn-primary {
    border-color: $primary!important;
  }
}