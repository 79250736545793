$product-grid-cell-width: 210px;
$product-grid-def-row-cell-multiple: 120px;
$product-grid-cell-def-width: 60px;
$product-grid-header-height: 48px;

.products-page {
  .product-list-member-salesman & {
    .col-sidebar {
      .btn-toggle-collapse {
        display: block;
      }
    }

    .catalog-list-actions {
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap!important;
        max-height: none!important;

        & > .form-inline {
          flex-wrap: wrap!important;
          align-items: center!important;
          justify-content: center!important;
        }
      }

      @include media-breakpoint-between(sm, md) {
        & > .form-inline {
          flex: 1 1 auto!important;
          max-width: 100%!important;
          justify-content: flex-start!important;
          flex-direction: row!important;
          flex-wrap: nowrap!important;
        }
      }
    }
  }
}

.display-mode-grid_data {
  & > body {
    overflow: hidden;

    .product-list-header-grid,
    .products-page-container {
      z-index: $zindex-modal - 10;
    }

    .product-list-header-grid {
      top: 0;
      left: 0;
      right: 0;
      background-color: $white;
      position: fixed;
      height: $product-grid-header-height;

      &-inner {
        padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .btn-logo {
          padding: 0;
          img {
            max-height: 30px;
          }
        }

        .btn-close {
          padding: 0;
          color: $text-color;
          font-size: 1.5rem;
        }

      }
    }

    .products-page-container {
      background-color: $white;
      position: fixed;
      top: $product-grid-header-height;
      left: 0;
      right: 0;
      bottom: 0;

      & > .products-page-content {
        overflow: hidden;
        height: calc(100vh - #{$product-grid-header-height});
        width: 100%;
        margin: 0;

        & > [class*="col-"],
        & > .col {
          height: 100%;
          overflow: hidden;

          padding-top: $grid-gutter-width;
          padding-bottom: $grid-gutter-width;
        }

        & > .col-sidebar {
          padding-top: 0;
          overflow: scroll!important;

          .cms-block {
            display: none;
          }
        }

        & > .col-product-list {
          .product-list-section {

            display: flex;
            flex-direction: column;
            height: 100%;

            & > .product-list-header {
              flex: 0 0 auto;

              .product-filter-list {
                &.desktop {
                  .product-filter {
                    margin-top: 0;
                  }
                }
              }
            }

            & > .product-list-content {
              flex: 1 1 auto;
              overflow: hidden;

              .product-list-grid-container {
                overflow: hidden;
                height: 100%;

                & > .product-list-grid {
                  overflow: hidden;
                  height: 100%;
                }
              }

              .grid {
                height: 100%;
                overflow: scroll;

                .product-content {

                  & > .row {
                    & > div {
                      &.col-10 {
                        flex: 0 0 47%;
                        max-width: 47%;
                      }
                    }
                  }

                  .quantity-selector {
                    .app-icon-inner {
                      display: none!important;
                    }

                    .btn {
                      padding: 0.375rem 0.35rem;
                    }
                  }

                  .product-heading {
                    display: none;
                  }

                  .product-extra {
                    font-size: 0.7rem;

                    & > div {
                      padding-right: .4rem;
                    }
                  }
                }


                & > .grid-row-def {
                  position: sticky;
                  top: 0;
                  z-index: 15;

                  .def-sub-col-content {
                    display: flex;
                    flex-direction: column;

                    .def-sub-col {
                      flex: 1 1 auto;
                    }
                  }
                }

                & > .grid-row {
                  &:not(.grid-row-def) {
                    & > .def {
                      position: sticky;
                      z-index: 10;
                      left: 0;
                    }

                    .def-sub-row {
                      .def-row {
                        position: sticky;
                        z-index: 10;
                        left: $product-grid-cell-def-width;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-list-section {

  .product-list-header {
    .btn-mass-add-to-cart {
      .app-icon {
        margin-right: $spacer / 2;
      }

      @include media-breakpoint-down(md) {
        display: block;
        width: 100%;
      }
    }

    .product-list-action-section {
      & > .product-picker-action {
        width: 100%;

        .product-picker-action-content {
          margin-bottom: $spacer / 4;
          display: flex;
          flex-direction: row;

          .dropdown {
            .dropdown-item {
              .app-icon {
                margin-right: $spacer / 1.5;
              }
            }
          }

          & > button {
            flex: 1 1 auto;
          }

          .btn {
            .app-icon {
              margin-right: $spacer / 2;
            }
          }

          .btn-cancel {
            display: none;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        @include media-breakpoint-up(md) {
          width: auto;
          margin-right: $spacer / 2;
          margin-bottom: 0;
          display: block;

          .product-picker-action-content {
            .btn-cancel {
              display: inline-block;
              color: $text-color;
            }
          }
        }
      }
      & > .mass-add-to-cart-section {
        width: 100%;

        .btn-container {
          display: block;

          & > .btn {
            display: block;
            width: 100%;
          }
        }

        @include media-breakpoint-up(md) {
          width: auto;
          .btn-container {
            display: inline-block;
          }

          .btn {
            display: inline-block;
            width: auto;
          }

          margin-right: $spacer / 2;
          font-size: 12px;
        }
      }
    }
  }

  .product-list-content {
    .product-card {

      .product-content {
        .product-heading {

          .product-name {
            margin-bottom: 0;
          }

          &.already-ordered {
            .product-heading-inner {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;

              .product-already-ordered {
                display: inline-block;
                margin-right: $spacer / 4;
              }
            }
          }
        }

        .product-extra {
          .product-field {
            padding-right: 0.7rem;
            flex: 1 1 100%;
            justify-content: flex-start;

            .lbl {
              text-transform: uppercase;
              &:after {
                display: none;
              }
            }

            .value {
              margin-left: auto;
            }
          }
        }

        .product-member-information {
          .product-field {
            .lbl {
              display: inline-block;
              white-space: nowrap;
            }
          }
        }
      }

      .quantity-selector {
        &.out-of-stock {
          & > form {
            opacity: .8;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .product-overlay {
          display: none!important;
        }
        .product-content {
          .quantity-selector {
            margin-left: 0;
            margin-top: $spacer / 2;
          }

          & > .row {
            align-items: flex-start!important;
            justify-content: flex-start!important;

            & > div {
              padding-left: $grid-gutter-width / 4;
              padding-right: $grid-gutter-width / 4;

              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }

      @include media-breakpoint-up(xxl) {
        .product-member-info {
          .extra {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
          }
        }
      }
    }

    .product-list-grid-container {
      .react-nano-scrollbar-wrapper {
        position: relative;
        overflow: visible;
        display: block;

        .react-nano-scrollbar-track-x {
          position: sticky;
          bottom: 4px;
          opacity: 1!important;
        }
      }

      .product-list-grid {
        margin-bottom: $spacer;

        .grid {
          .column {
            padding: $table-cell-padding;
            border: $table-border-width solid $table-border-color;

            &.def {
              text-transform: uppercase;
              text-align: center;
              word-break: break-word;
              background-color: lighten($secondary, 30%);
              color: $white;
              font-weight: bold;

              .lbl {
                font-size: .8em;
              }

              .btn {
                &.btn-secondary {
                  &:not(:hover) {
                    &:not(:focus) {
                      &:not(:active) {
                        &:not(:disabled) {
                          background-color: lighten($secondary, 5%);
                          border-color: lighten($secondary, 5%);
                        }
                      }
                    }
                  }
                }

                &.btn-primary {
                  &:not(:hover) {
                    &:not(:focus) {
                      &:not(:active) {
                        &:not(:disabled) {
                          background-color: lighten($primary, 5%);
                          border-color: lighten($primary, 5%);
                        }
                      }
                    }
                  }
                }

                margin-right: $table-cell-padding / 2;
                padding: .1em .4em;
              }
            }

            &.def-row {
              text-orientation: upright;
              writing-mode: vertical-lr;
              vertical-align: middle;
              align-self: stretch;
              align-items: center;
              justify-content: center;
              padding: 0;
              text-align: center;
              display: flex;

              .btn {
                margin-right: 0;
                margin-bottom: $table-cell-padding / 2;
              }
            }
          }

          & > div:first-child {
            .def-row {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $body-bg;
              position: sticky;
              left: 0;

              &.multiple {
                flex: 0 0 $product-grid-def-row-cell-multiple;
                max-width: $product-grid-def-row-cell-multiple;
                width: $product-grid-def-row-cell-multiple;
              }
            }

            .def-sub-col {
              display: flex;
              .def-col {
                flex: 0 0 $product-grid-cell-width;
                max-width: $product-grid-cell-width;
                width: $product-grid-cell-width;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &.no-subs {
              .def-col {
                flex: 0 0 $product-grid-cell-width;
                max-width: $product-grid-cell-width;
                width: $product-grid-cell-width;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          .grid-row {
            display: flex;
            flex-wrap: nowrap;
          }

          .def-row {
            flex: 0 0 $product-grid-cell-def-width;
            max-width: $product-grid-cell-def-width;
            width: $product-grid-cell-def-width;
          }

          .products {
            flex: 0 0 $product-grid-cell-width;
            max-width: $product-grid-cell-width;
            width: $product-grid-cell-width;

            .row {
              & > [class*="col-"] + [class*="col-"],
              & > [class*="col-"] + .col,
              & > .col + [class*="col-"],
              & > .col + .col {
                margin-top: $table-cell-padding;
              }
            }
          }
        }
      }
    }

    .product-data-grid {
      display: flex;
      width: 100%;
      flex-direction: column;
      border: $table-border-width solid $table-border-color;
      margin-bottom: $spacer;

      .product-data-grid-def {
        text-align: center;
        word-break: break-all;
      }

      //.product-data-grid-row {
      //  display: flex;
      //  flex-direction: row;
      //  flex-wrap: nowrap;
      //  justify-content: flex-start;
      //  align-content: flex-start;
      //
      //  & > div:first-child {
      //    flex: 0 0 $product-grid-def-row-cell-multiple;
      //  }
      //
      //  .product-data-grid-column {
      //    display: flex;
      //    flex-direction: column;
      //    align-items: center;
      //    justify-content: center;
      //    flex: 0 0 240px;
      //    padding: $table-cell-padding;
      //    border: $table-border-width solid $table-border-color;
      //
      //    &.product-data-grid-column-def {
      //      background-color: lighten($secondary, 30%);
      //      color: $white;
      //      font-weight: bold;
      //    }
      //  }
      //
      //  .product-data-grid-items {
      //    align-items: flex-start;
      //    justify-content: flex-start;
      //
      //    .row {
      //      width: 100%;
      //      align-items: center;
      //      justify-content: center;
      //      flex-wrap: wrap;
      //
      //
      //      & > [class*="col-"] + [class*="col-"],
      //      & > [class*="col-"] + .col,
      //      & > .col + [class*="col-"],
      //      & > .col + .col {
      //        margin-top: $spacer;
      //      }
      //    }
      //  }
      //}
    }
  }
}

.products-mobile-side-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.products-mobile-side-menu {
  right: 0;
  top: 0;
}

.filter-option-popover {
  max-width: 310px;
  img {
    width: 286px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 376px;
    img {
      width: 346px;
    }
  }
}

.sweet-alert {
  font-size: .9rem!important;
  .text-muted {
    //font-size: 1rem!important;
  }

  //&.add-to-cart-alert {
  //  width: 80%!important;
  //  @include media-breakpoint-up(md) {
  //    max-width: 33em!important;
  //  }
  //  @include media-breakpoint-up(lg) {
  //    max-width: 40em!important;
  //  }
  //}
}

.tooltip-member-info-content {
  .product-field {

    .sub-product-field {
      + .sub-product-field {
        margin-left: .2rem;
      }
    }

    .extra {
      margin-left: .2rem;
      font-style: italic;
    }

    .lbl {
      display: inline-block;
      white-space: nowrap;
    }

    .lbl {
      &:after {
        content: " : ";
        font-size: .85em;
        margin-right: .5rem;
        margin-left: .2rem;
      }
    }

    .value {
      font-weight: 600;
      line-height: 1;
    }
  }
}