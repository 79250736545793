@font-face {
  font-family: $font-Flaticon-family;
  src: url("./../../fonts/flaticon.ttf?670a3057f2f583ae475ffdc0066929f9") format("truetype"),
  url("./../../fonts/flaticon.woff?670a3057f2f583ae475ffdc0066929f9") format("woff"),
  url("./../../fonts/flaticon.woff2?670a3057f2f583ae475ffdc0066929f9") format("woff2"),
  url("./../../fonts/flaticon.eot?670a3057f2f583ae475ffdc0066929f9#iefix") format("embedded-opentype"),
  url("./../../fonts/flaticon.svg?670a3057f2f583ae475ffdc0066929f9#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-information:before {
  content: map-get($flaticon-map, "information");
}
.flaticon-time-left:before {
  content: map-get($flaticon-map, "time-left");
}
.flaticon-contact:before {
  content: map-get($flaticon-map, "contact");
}
.flaticon-settings:before {
  content: map-get($flaticon-map, "settings");
}
.flaticon-calendar:before {
  content: map-get($flaticon-map, "calendar");
}
.flaticon-heart-o:before {
  content: map-get($flaticon-map, "heart-o");
}
.flaticon-list:before {
  content: map-get($flaticon-map, "list");
}
.flaticon-home:before {
  content: map-get($flaticon-map, "home");
}
.flaticon-handshake:before {
  content: map-get($flaticon-map, "handshake");
}
.flaticon-delivery-truck:before {
  content: map-get($flaticon-map, "delivery-truck");
}
.flaticon-guarantee:before {
  content: map-get($flaticon-map, "guarantee");
}
.flaticon-phone-o:before {
  content: map-get($flaticon-map, "phone-o");
}
.flaticon-discount:before {
  content: map-get($flaticon-map, "discount");
}
.flaticon-emergency:before {
  content: map-get($flaticon-map, "emergency");
}
.flaticon-box:before {
  content: map-get($flaticon-map, "box");
}
.flaticon-delivery-truck-2:before {
  content: map-get($flaticon-map, "delivery-truck-2");
}
.flaticon-check:before {
  content: map-get($flaticon-map, "check");
}
.flaticon-trash-alt:before {
  content: map-get($flaticon-map, "trash-alt");
}
.flaticon-minus:before {
  content: map-get($flaticon-map, "minus");
}
.flaticon-plus:before {
  content: map-get($flaticon-map, "plus");
}
.flaticon-chevron-right:before {
  content: map-get($flaticon-map, "chevron-right");
}
.flaticon-arrow-right:before {
  content: map-get($flaticon-map, "arrow-right");
}
.flaticon-shopping-cart:before {
  content: map-get($flaticon-map, "shopping-cart");
}
.flaticon-user:before {
  content: map-get($flaticon-map, "user");
}
.flaticon-heart-o-2:before {
  content: map-get($flaticon-map, "heart-o-2");
}
.flaticon-search:before {
  content: map-get($flaticon-map, "search");
}
.flaticon-chevron-down:before {
  content: map-get($flaticon-map, "chevron-down");
}
.flaticon-chevron-up:before {
  content: map-get($flaticon-map, "chevron-up");
}
.flaticon-chevron-left:before {
  content: map-get($flaticon-map, "chevron-left");
}
.flaticon-arrow-left:before {
  content: map-get($flaticon-map, "arrow-left");
}
.flaticon-heart:before {
  content: map-get($flaticon-map, "heart");
}
.flaticon-loader-reload:before {
  content: map-get($flaticon-map, "loader-reload");
}
.flaticon-loader-loading-stripes:before {
  content: map-get($flaticon-map, "loader-loading-stripes");
}
.flaticon-menu:before {
  content: map-get($flaticon-map, "menu");
}
.flaticon-warning:before {
  content: map-get($flaticon-map, "warning");
}
.flaticon-danger:before {
  content: map-get($flaticon-map, "danger");
}
.flaticon-arrow-right-angle:before {
  content: map-get($flaticon-map, "arrow-right-angle");
}
.flaticon-warehouse-2:before {
  content: map-get($flaticon-map, "warehouse-2");
}
.flaticon-warehouse:before {
  content: map-get($flaticon-map, "warehouse");
}
.flaticon-logout:before {
  content: map-get($flaticon-map, "logout");
}
.flaticon-receipt:before {
  content: map-get($flaticon-map, "receipt");
}
.flaticon-pencil:before {
  content: map-get($flaticon-map, "pencil");
}
.flaticon-delivery-truck-1:before {
  content: map-get($flaticon-map, "delivery-truck-1");
}
.flaticon-delivery:before {
  content: map-get($flaticon-map, "delivery");
}
.flaticon-export:before {
  content: map-get($flaticon-map, "export");
}
.flaticon-import:before {
  content: map-get($flaticon-map, "import");
}
.flaticon-shop:before {
  content: map-get($flaticon-map, "shop");
}
.flaticon-shopping-store:before {
  content: map-get($flaticon-map, "shopping-store");
}
.flaticon-check-1:before {
  content: map-get($flaticon-map, "check-1");
}
.flaticon-barcode:before {
  content: map-get($flaticon-map, "barcode");
}
.flaticon-information-1:before {
  content: map-get($flaticon-map, "information-1");
}
.flaticon-bell:before {
  content: map-get($flaticon-map, "bell");
}
.flaticon-info:before {
  content: map-get($flaticon-map, "info");
}
.flaticon-question:before {
  content: map-get($flaticon-map, "question");
}
.flaticon-phone:before {
  content: map-get($flaticon-map, "phone");
}
.flaticon-phone-small:before {
  content: map-get($flaticon-map, "phone-small");
}
.flaticon-closed-envelope:before {
  content: map-get($flaticon-map, "closed-envelope");
}
.flaticon-calendar-o:before {
  content: map-get($flaticon-map, "calendar-o");
}
.flaticon-camera:before {
  content: map-get($flaticon-map, "camera");
}
.flaticon-upload:before {
  content: map-get($flaticon-map, "upload");
}
.flaticon-link:before {
  content: map-get($flaticon-map, "link");
}
.flaticon-freight-container:before {
  content: map-get($flaticon-map, "freight-container");
}
.flaticon-lift-container-2:before {
  content: map-get($flaticon-map, "lift-container-2");
}
.flaticon-lift-container-1:before {
  content: map-get($flaticon-map, "lift-container-1");
}
.flaticon-container-crane:before {
  content: map-get($flaticon-map, "container-crane");
}
.flaticon-container:before {
  content: map-get($flaticon-map, "container");
}
.flaticon-logout-bold:before {
  content: map-get($flaticon-map, "logout-bold");
}
.flaticon-logout-1:before {
  content: map-get($flaticon-map, "logout-1");
}
.flaticon-id-card:before {
  content: map-get($flaticon-map, "id-card");
}
.flaticon-statistics:before {
  content: map-get($flaticon-map, "statistics");
}
.flaticon-download:before {
  content: map-get($flaticon-map, "download");
}
.flaticon-tree-structure:before {
  content: map-get($flaticon-map, "tree-structure");
}
.flaticon-budget:before {
  content: map-get($flaticon-map, "budget");
}
.flaticon-add-to-cart:before {
  content: map-get($flaticon-map, "add-to-cart");
}
.flaticon-unavailable-box:before {
  content: map-get($flaticon-map, "unavailable-box");
}
.flaticon-trolley:before {
  content: map-get($flaticon-map, "trolley");
}
.flaticon-panel-open:before {
  content: map-get($flaticon-map, "panel-open");
}
.flaticon-panel-close:before {
  content: map-get($flaticon-map, "panel-close");
}
.flaticon-available-box:before {
  content: map-get($flaticon-map, "available-box");
}
