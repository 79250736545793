$carousel-app-button-width: 26px;

.carousel-app {
  .carousel-app-heading {
    text-align: center;
    margin-bottom: $spacer;
  }

  .carousel-app-footer,
  .carousel-app-content {
    margin-bottom: $spacer * 1.8;
  }

  .carousel-app-content {
    position: relative;
  }

  &-full-width {
    .carousel-app-navigation {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      @include media-breakpoint-down(sm) {
        padding-left :0;
        padding-right: 0;
      }
    }

    .carousel-app-container {
      &:not(.container-fluid) {
        @each $breakpoint, $container-max-width in $container-max-widths {
          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            width: $container-max-width - $carousel-app-button-width * 4;
          }
        }
      }
    }

    .swiper {
      .swiper-button-prev {
        left: 0;
        &:before {
          content: map-get($flaticon-map, "arrow-left");
        }
      }

      .swiper-button-next {
        right: 0;
        &:before {
          content: map-get($flaticon-map, "arrow-right");
        }
      }
    }

    [class*="swiper-button-"] {
      position: absolute;
      margin: 0;
      top: 50%;
      margin-top: -$carousel-app-button-width / 2;
      width: $carousel-app-button-width;
      height: $carousel-app-button-width;

      z-index: 200;
      color: $text-color;
      background: none;
      border: 0 none;
      font-size: 1.4rem;

      @include flat_icon();

      &:after {
        content: "";
        display: none;
      }

      @include hover-focus-active() {
        color: $primary;
      }

      &.disabled {
        opacity: .45;
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .product-carousel-footer {
    text-align: center;

    .btn-detail {
      @include font-size(1em);
      padding: $btn-padding-y-lg * 1.5 $btn-padding-x-lg * 3;
    }
  }
}

.modal {
  .modal-body {
    .carousel-app {
      &-full-width {
        .carousel-app-container {
          width: auto!important;
          max-width: 100%;
          @include media-breakpoint-up(md) {
            max-width: 94%;
          }

          @include media-breakpoint-up(lg) {
            max-width: 94%;
          }
        }
      }
    }
  }
}