@keyframes clockwise {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}
@keyframes anticlockwise {
  0% { transform: rotate(360deg);}
  100% { transform: rotate(0deg);}
}
@keyframes clockwiseError {
  0% { transform: rotate(0deg);}
  20% { transform: rotate(30deg);}
  40% { transform: rotate(25deg);}
  60% { transform: rotate(30deg);}
  100% { transform: rotate(0deg);}
}
@keyframes anticlockwiseErrorStop {
  0% { transform: rotate(0deg);}
  20% { transform: rotate(-30deg);}
  60% { transform: rotate(-30deg);}
  100% { transform: rotate(0deg);}
}
@keyframes anticlockwiseError {
  0% { transform: rotate(0deg);}
  20% { transform: rotate(-30deg);}
  40% { transform: rotate(-25deg);}
  60% { transform: rotate(-30deg);}
  100% { transform: rotate(0deg);}
}

.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -160px;

  h1 {
    margin: 100px auto 0 auto;
    color: $black;
    font-size: 10rem; line-height: 10rem;
    font-weight: 200;
    text-align: center;
  }
  h2 {
    margin: 20px auto 30px auto;
    font-size: 1.5rem;
    font-weight: 200;
    text-align: center;
  }
  h1, h2 {
    transition: opacity 0.5s linear, margin-top 0.5s linear;
  }

  &.loading {
    h1, h2 {
      margin-top: 0;
      opacity: 0;
    }

    .gear.one, .gear.three {
      animation: clockwise 3s linear infinite;
    }
    .gear.two {
      animation: anticlockwise 3s linear infinite;
    }
  }
}

.error-section {
  .alert {
    transition: opacity 0.5s linear, margin-top 0.5s linear;
    margin-top: 0;
    opacity: 1;
  }

  .gears {
    position: relative;
    margin: 0 auto;
    width: auto;
    height: 220px;
  }

  .gear {
    position: relative;
    z-index: 0;
    width: 120px; height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    background: $black;
  }
  .gear:before{
    position: absolute; left: 5px; top: 5px; right: 5px; bottom: 5px;
    z-index: 2;
    content: "";
    border-radius: 50%;
    background: $body-bg;
  }
  .gear:after {
    position: absolute; left: 25px; top: 25px;
    z-index: 3;
    content: "";
    width: 70px; height: 70px;
    border-radius: 50%;
    border: 5px solid $black;
    box-sizing: border-box;
    background: $body-bg;
  }
  .gear.one {
    left: -130px;
  }
  .gear.two {
    top: -75px;
  }
  .gear.three {
    top: -235px;
    left: 130px;
  }
  .gear .bar {
    position: absolute; left: -15px; top: 50%;
    z-index: 0;
    width: 150px; height: 30px;
    margin-top: -15px;
    border-radius: 5px;
    background: $black;
  }
  .gear .bar:before {
    position: absolute; left: 5px; top: 5px; right: 5px; bottom: 5px;
    z-index: 1;
    content: "";
    border-radius: 2px;
    background: $body-bg;
  }
  .gear .bar:nth-child(2) {
    transform: rotate(60deg);
  }
  .gear .bar:nth-child(3) {
    transform: rotate(120deg);
  }
  .gear.one {
    animation: anticlockwiseErrorStop 2s linear infinite;
  }
  .gear.two {
    animation: anticlockwiseError 2s linear infinite;
  }
  .gear.three {
    animation: clockwiseError 2s linear infinite;
  }

  &.app-warning,
  &.app-error {
    max-width: 80%;
    padding: 2rem;
    margin: 4em auto;
    background: $gray-100;
    box-shadow: $box-shadow;

    .app-error-head {
      text-align: center;
      transition: opacity 0.5s linear, margin-top 0.5s linear;
      margin: 20px auto 60px auto;
    }

    .btn {
      margin-top: $spacer;
    }

    h2, p {
      text-align: center;
    }

    h2 {
      font-weight: bold;
      color: $danger;
      font-size: 1.5rem;
      text-transform: uppercase;

      .app-icon {
        margin-right: $spacer / 2;
      }
    }

    p {
      color: darken($danger, 30%);
      font-weight: bold;
    }
  }

  &.app-warning {
    h2 {
      color: $warning;
    }
  }

  &.not-found-section {
    box-shadow: none;
    background-color: transparent;
    .gears {
      margin-top: $spacer * 4;
    }
  }

  &.loading {
    .gear.one, .gear.three {
      animation: clockwise 3s linear infinite;
    }
    .gear.two {
      animation: anticlockwise 3s linear infinite;
    }

    &.app-error {
      .app-error-head {
        margin-top: 0;
        opacity: 0;
      }
    }
  }
}

.not-found-layout,
.not-found-page {
  background-color: $white;
}