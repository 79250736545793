.order-table {

  .has-callback {
    cursor: pointer;
  }

  &-placeholder {
    .rect-shape,
    .round-shape,
    .text-row,
    .rect-shape,
    .round-shape,
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }
  }
}
