.language-switcher {
  margin-bottom: $spacer / 2;
  .dropdown {
    & > button {
      align-items: center;
      padding-left: 0;
      padding-bottom: 0;

      & > .current-locale {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .locale-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .flag-icon {
      margin-right: $spacer / 2;
      border-radius: 50%;
      position: relative;
      width: 18px;
      height: 18px;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        transform: rotate(-18deg);
        left: 1px;
        top: 1px;
      }
    }

    .show-icon {
      &.show-name,
      &.show-native {
        .flag-icon {
          margin-right: $spacer / 2;
        }
      }
    }

    &.show-name {
      .name {
        display: inline-block;
      }
    }

    &.show-abbr {
      .abbr {
        display: inline-block;
      }
    }

    &.show-native {
      .native {
        display: inline-block;
      }
    }
    &.show-flag {
      .flag-icon {
        display: inline-block;
      }
    }
  }
}