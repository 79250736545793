.breadcrumb-section {
  background-color: $white;
  margin-bottom: $breadcrumb-section-margin-bottom;

  .breadcrumb {
    margin-top: 0;
    align-items: center;
    justify-content: center;

    a {
      color: $breadcrumb-active-color;
      @include hover-focus-active() {
        color: inherit;
        text-decoration: underline;
      }
    }

    .breadcrumb-item {
      padding-left: 0;
      &:before {
        content: "";
      }

      &:after {
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-left: $breadcrumb-item-padding;
        color: $breadcrumb-divider-color;
        content: escape-svg($breadcrumb-divider);
      }

      &.active {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;

        &:before, &:after {
          content: "";
        }

        span {
          color: $gray-800;
          display: block;
          text-align: center;
          font-family: $headings-variant-font-family;
          line-height: $headings-line-height;
          @include font-size($h1-font-size);
          font-weight: 300;
        }
      }

      &:last-child {
        &:after {
          display: none!important;
        }
      }
    }
  }
}