$container-volume-arrow-width : 6px;

.container-volumes {
  margin-top: $spacer / 2;
  margin-bottom: $spacer;

  border-top: 1px solid $gray-400;
  padding-top: .5rem;

  .container-volume {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  & > .row {
    align-items: flex-start!important;
  }

  .resume {

    .lbl {
      display: inline-block;

      &:after {
        content: ": ";
      }
    }

    .app-icon {
      font-size: 0.9em;
      margin-left: $spacer / 4;
    }

    .value {
      font-weight: bold;
      margin-left: $spacer / 4;
    }
  }

  .container-volume-list {
    margin-left: -$grid-gutter-width / 4;
    margin-right: -$grid-gutter-width / 4;

    & > [class*="col-"],
    & > .col {
      padding-left: $grid-gutter-width / 4;
      padding-right: $grid-gutter-width / 4;
    }
  }

  .cart-light-item & {
    margin-bottom: $spacer;
    margin-top: 0;

    .container-volume {
      margin: 0 $container-volume-arrow-width / 2;
      margin-bottom: $spacer / 2;
      @include media-breakpoint-down(md) {
        max-width: 190px;
      }
    }
  }
}

.container-volume {
  position: relative;
  min-width: 80px;
  padding-top: $container-volume-arrow-width;
  padding-right: 14px!important;

  .arrow {
    position: absolute;
    top: - $container-volume-arrow-width * 1.2;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $container-volume-arrow-width $container-volume-arrow-width / 2 0 $container-volume-arrow-width / 2;
    border-color: $success transparent transparent transparent;
    z-index: 100;
  }

  &-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: visible;
    position: relative;

    & > .volume {
      flex: 1 1 auto;
      position: relative;
      overflow: visible;

      &:first-of-type {
        & > .progress {
          border-top-left-radius: $progress-border-radius;
          border-bottom-left-radius: $progress-border-radius;
        }
      }

      &:last-of-type {
        .info {
          right: -22px;
        }

        & > .progress {
          border-top-right-radius: $progress-border-radius;
          border-bottom-right-radius: $progress-border-radius;
        }
      }

      .info {
        font-size: .65em;
        z-index: 10;
        position: absolute;
        bottom: -14px;
        right: -6px;
        display: block;

        .arrow {
          border-color: $secondary transparent transparent transparent;
          position: static;
          left: auto;
          right: auto;
          bottom: auto;
          top: auto;
        }
      }

      .progress {
        z-index: 9;
        height: $progress-height * 2;
        border-radius: 0;
        border-right: 3px solid $white;
      }

      &:last-of-type {
        .progress {
          border-right: 0 none;
        }
      }

      &.step-1 {
        .progress-bar {
          background-color: $orange!important;
        }
      }

      &.step-2 {
        .progress-bar {
          background-color: $yellow!important;
        }
      }

      &.step-3 {
        .progress-bar {
          background-color: $success!important;
        }
      }
    }
  }
}


.container-volume-info {
  p {
    margin-bottom: 0;
  }
}
