// Base styles
// -------------------------

.callout {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: 1rem;
  border: 1px solid $gray-600;
  border-left-width: 2px;
  border-radius: 2px;
  margin-bottom: 1rem;

  // Headings for larger alerts
  .heading-title,
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }

  p:last-child {
    margin-bottom:0;
  }

  code {
    border-radius: $border-radius;
  }

  +.callout {
    margin-top:-5px;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the callout.

.callout-default {
  @include callout-variant($gray-600);
}
.callout-primary {
  @include callout-variant($primary);
}
.callout-success {
  @include callout-variant($success);
}
.callout-info {
  @include callout-variant($info);
}
.callout-warning {
  @include callout-variant($warning);
}
.callout-danger {
  @include callout-variant($danger);
}
.callout-secondary {
  @include callout-variant($secondary);
}
