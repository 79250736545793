.hv, .hv1, .hv2, .hv3, .hv4, .hv5, .hv6 {
  font-family: $headings-variant-font-family;
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
  &.hv {
    @include font-size($h1-hv-font-size);
  }
}
h2, .h2 {
  &.hv {
    @include font-size($h2-hv-font-size);
  }
}
h3, .h3 {
  &.hv {
    @include font-size($h3-hv-font-size);
  }
}
h4, .h4 {
  &.hv {
    @include font-size($h4-hv-font-size);
  }
}
h5, .h5 {
  &.hv {
    @include font-size($h5-hv-font-size);
  }
}
h6, .h6 {
  &.hv {
    @include font-size($h6-hv-font-size);
  }
}

.hv1 { @include font-size($h1-hv-font-size); }
.hv2 { @include font-size($h2-hv-font-size); }
.hv3 { @include font-size($h3-hv-font-size); }
.hv4 { @include font-size($h4-hv-font-size); }
.hv5 { @include font-size($h5-hv-font-size); }
.hv6 { @include font-size($h6-hv-font-size); }
