.shipping-location-best-seller {

  &.card {
    border: 0 none;
    background-color: transparent;
    .card-body,
    .card-header {
      background-color: $white;
    }

    .card-footer,
    .card-header {
      background-color: $white;
      border: 0 none;
    }

    .card-header {
      text-align: center;

      .title {
        margin-bottom: $spacer / 6;
      }

      .subtitle {
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .card-body,
    .card-footer {
      padding: $card-spacer-y $card-spacer-x / 2;
    }

    .card-footer {
      padding-left: 0;
      padding-right: 0;

      .actions {
        margin-top: $card-spacer-y;
        text-align: right;

        .app-icon {
          margin-left: $spacer / 2;
        }
      }
    }

    .card-body {
      cursor: pointer;

      @include hover-focus-active() {
        .btn {
          background: $dark!important;
          color: color-yiq($dark)!important;
        }
      }
    }

    .card-footer {
      background-color: transparent;
    }
  }

  &-image {
    width: 100%;
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
      background-size: 80%;
      z-index: 2;
    }

    .btn {
      display: inline-block;
      position: relative;
      margin-bottom: $spacer;
      z-index: 4;
      border-radius: $border-radius-lg;
      padding: $btn-padding-y-lg * 1.5 $btn-padding-x-lg * 3;

      &:not(:active) {
        &:not(:hover) {
          &:not(:focus) {
            background: color-yiq($dark);
            color: $dark;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition: $animate-css-animation-duration filter linear;
      object-fit: cover;

      &.placeholder {
        filter: blur(1rem);
      }

      &.picture {
        filter: blur(0);
      }
    }

    &-list {
      img {
        transition: top ease-in $animate-css-animation-duration;

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 1;

        + img {
          top: 100%;
        }
      }
    }
  } // .product-image
}