.pagination-section {
  margin-bottom: 1rem;

  & > .page-select {
    margin-top: .5rem;

    .form-group {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;

      .custom-select {
        width: auto;
        margin-left: .5rem;
      }
    }

    //.form-label {
    //  margin-bottom: $label-margin-bottom;
    //}
  }
}

.pagination {
  margin-bottom: 0;
  justify-content: center;
  .page-item {
    margin-right: .5rem;

    &.goto-next-item,
    &.goto-prev-item {
      .page-link {
        padding-left: .6rem;
        padding-right: .6rem;
      }
    }

    &.goto-first-item,
    &.goto-last-item {
      .page-link {
        padding-left: .8rem;
        padding-right: .8rem;
        padding-top: .3rem;
        font-size: 1rem;
      }
    }

    &:first-child {
      .page-link {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    &:last-child {
      margin-right: 0;
      .page-link {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  & > .page-select {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .page-link {
    border-radius: 50%;
  }

  &-placeholder {
    .page-link {
      padding: 0;
      background-color: transparent!important;
      border-color: transparent!important;
      .rect-shape {
        border-radius: 50%;
      }
    }
  }
}