.product-list-family-tree {
  .family-tree-entry {
    background-color: $white;

    &-children {
      transition: opacity ease-in $animate-css-animation-duration, height ease-in $animate-css-animation-duration;
      opacity: 0;
      height: 0;
      visibility: hidden;
      overflow: hidden;

      // Enable `flex-grow: 1` for decks and groups so that card blocks take up
      // as much space as possible, ensuring footers are aligned to the bottom.
      flex: 1 1 auto;
      // Workaround for the image size bug in IE
      // See: https://github.com/twbs/bootstrap/pull/28855
      min-height: 1px;
    }

    .btn-collapse {
      margin-left: auto;
    }

    & > a {
      display: block;
      color: $text-color;
      line-height: 1.4;
    }

    .family-tree-entry {
      margin-bottom: $spacer / 4;

      & > a {
        &.active {
          + .family-tree-entry-children {
            opacity: 1;
            height: auto;
            visibility: visible;
          }
        }
      }
    }

    &.opened {
      & > a {
        margin-bottom: $card-spacer-x / 2;

        + .family-tree-entry-children {
          opacity: 1;
          height: auto;
          visibility: visible;
          padding: 0 $card-spacer-x / 2;
        }
      }
    }

    &-family,
    &-sub_family {
      margin-left: 1px;

      & > a {
        margin-bottom: $spacer / 4;
        font-size: .95em;
        display: flex;
        flex-direction: row;
        align-items: center;
        //&:before {
        //  display: inline-block;
        //  content: " ";
        //  margin-right: .5rem;
        //  height: 5px;
        //  width: 5px;
        //  background-color: $gray-500;
        //  border-radius: 50%;
        //}

        &.active {
          &:before {
            background-color: $primary;
          }
        }
      }
    }

    &-department,
    &-family {
      & > .family-tree-entry-children {
        position: relative;
      }
    }

    &-heading {
      margin-bottom: $card-spacer-x;
      & > span {
        user-select: none;
        color: $text-color;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        text-indent: 0;

        & > span {
          text-transform: uppercase;
        }

        background-color: $gray-100;
        padding: $card-spacer-y $card-spacer-x;
        border-bottom: $card-border-width solid $gray-100;

        .app-icon {
          margin-right: .5rem;
        }
      }
    }

    &-shipping_location {
      margin-bottom: $card-spacer-x;

      &:not(.has-children) {
        & > a {
          justify-content: flex-start;
        }

        &.opened {
          margin-bottom: $card-spacer-x!important;
        }
      }

      & > .family-tree-entry-children {
        & > .family-tree-entry {
          &-shipping_location {
            & > a {
              //pointer-events: none;
              margin-bottom: $card-spacer-x / 2;
              position: relative;

              &.active {
                color: inherit!important;
              }

              .app-icon {
                display: none;
              }

              .btn-collapse {
                display: none;
              }

              & > span {
                background-color: $white;
                z-index: 4;
                position: relative;
                padding-right: $spacer;
              }

              &:after {
                z-index: 1;
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 1px;
                height: 1px;
                background-color: $gray-300;
              }

              + .family-tree-entry-children {
                opacity: 1;
                height: auto;
                visibility: visible;
                padding: 0 $card-spacer-x / 2;
              }

              //@include hover-focus-active() {
              //  margin-left: 0;
              //}
            }
          }
        }
      }
    }

    &.opened {
      & > .family-tree-entry-children {
        padding: $card-spacer-y / 2 $card-spacer-x / 2;
      }
    }

    & > a {
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &:not(.active) {
        @include hover-focus-active() {
          margin-left: 4px;
        }
      }

      &.active {
        font-style: italic;
        font-weight: 600;

        &:before {
          background-color: $gray-600;
        }

        & + .family-tree-entry-children {
          padding: $card-spacer-y / 2 $card-spacer-x / 2;
        }
      }
    }
  }

  & >.family-tree-entry {
    &-shipping_location {
      & > a {
        transition: margin-bottom ease-in 400ms;
        color: $text-color;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        text-indent: 10px;
        line-height: 1.6;

        & > span {
          text-transform: uppercase;
        }

        background-color: $gray-100;
        padding: $card-spacer-y $card-spacer-x;
        border-bottom: $card-border-width solid $card-border-color;

        .app-icon {
          margin-right: .5rem;
        }

        & > .app-icon {
          font-size: 1.8rem;
          margin-right: 0;
          line-height: 1;
          text-indent: 5px;
        }

        @include hover-focus-active() {
          background-color: $primary;
          color: white;
        }

        &.active {
          background-color: $primary;
          color: white;
        }

        + .family-tree-entry-children {
          margin-left: 0!important;
        }
      }

      &.opened {
        & > a {
          margin-bottom: $card-spacer-x;

          + .family-tree-entry-children {
            padding: 0 $card-spacer-x;
          }
        }
      }
    }
  }
}