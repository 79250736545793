.product-list-sidebar {
  background-color: $white;

  @include media-breakpoint-up(md) {
    //position: sticky;
    transition: top ease-in $animate-css-animation-duration;
  }

  //@include media-breakpoint-up(md) {
  //  top: 90px;
  //}

  //@include media-breakpoint-up(lg) {
  //  top: 190px;
  //  &.sticked {
  //    top: 90px;
  //  }
  //}
}

#page-wrap {
  &.sticked {
    .product-list-sidebar {
      top: 90px;
    }
  }
}