.page-product-detail {
  #page-wrap {
    background-color: $white;
  }
}

.product-detail {
  & > div:first-child {
    //margin-bottom: $spacer;
  }

  .product-member-information {
    //max-width: 156px;

    & > .content {
      padding-left: 0;
    }
  }

  .product-detail-content {
    & >div:last-child {
      margin-top: $spacer;
    }
  }

  .btn-scroll-to-cross-sell {
    color: $text-color;
    font-size: .9rem;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    .app-icon {
      &:before {
        transform: rotate(90deg);
      }
      margin-right: $spacer / 2;
    }

    span {
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  .favorite {
    display: inline-block;
    color: $text-color;
  }

  .product-name {
    font-size: 1.2em;
    font-weight: 600;
  }

  .product-image-section {
    .product-image-slide-thumb {
      cursor: pointer;
      img {
        border: 1px solid transparent;
        transition: border-bottom-color ease-in $animate-css-animation-duration,
        border-left-color ease-in $animate-css-animation-duration,
        border-right-color ease-in $animate-css-animation-duration,
        border-top-color ease-in $animate-css-animation-duration
      ;
      }

      @include hover-focus-active() {
        img {
          border-color: $primary;
        }
      }

      &.swiper-slide-thumb-active {
        img {
          border-color: $primary;
        }
      }
    }

    button {
      box-shadow: none;
      outline: none;
    }

    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-left");
          color: $text-color;
          font-size: 1.8em;
        }
      }

      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-right");
        }
      }
    }

    .swiper-thumbs {
      .swiper-button-prev,
      .swiper-button-next {
        height: calc(var(--swiper-navigation-size) / 44 * 27);
        transform: translateY(25%);
        &.swiper-button-disabled {
          display: none!important;
        }
      }
    }

    &-multiple {
      .swiper {
        .swiper-button-prev,
        .swiper-button-next {
          display: inline-block;
        }
      }
    }

    .copyright {
      margin-top: 1rem;
      font-size: 12px;
      line-height: 1.2;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .product-image {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
      background-size: 80%;
    }

    .swiper {
      height: 100%;

      .swiper-button-prev,
      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-left");
          color: $text-color;
          font-size: 1.8em;
        }
      }

      .swiper-button-next {
        &:after {
          font-family: $font-Flaticon-family;
          content: map-get($flaticon-map, "arrow-right");
        }
      }
    }

    &+.swiper {
      &-initialized {
        margin-top: $spacer;
      }
    }

    &-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      img {
        &.swiper-lazy {
          transition: opacity ease-in $animate-css-animation-duration;
          animation-duration: $animate-css-animation-duration;
          animation-fill-mode: both;
          opacity: 0;

          &-loaded {
            opacity: 1;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      &-detail {
        border: 8px solid $gray-100;
      }
    }
  }

  .product-brand {
    &-text {
      font-size: 1rem;
      text-transform: uppercase;
      color: $gray-600;
    }
    &-image {
      max-height: 20px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .product-detail-base-info {
    display: table;
    border-collapse: separate;
    vertical-align: center;

    & > div {
      display: table-row;
      & > span {
        display: table-cell!important;
        vertical-align: middle;

        &.lbl {
          font-size: 0.96em;
          padding-left: 0;
          text-transform: uppercase;

          &:after {
            content: " : ";
            font-size: .85em;
            margin-right: .5rem;
          }
        }
      }

      &.product-shipping-location {

        @include media-breakpoint-down(sm) {
          .app-icon {
            display: none!important;
          }
        }

        //.lbl {
        //  text-transform: none;
        //}

        &.with-label {
          &.with-icon {
            .app-icon {
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }

  .btn-product-image-zoom {
    transition: background-color ease-in $animate-css-animation-duration;
    position: absolute;
    right: $spacer / 2;
    bottom: $spacer / 2;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    z-index: 100;

    @include hover-focus-active() {
      background-color: $black;
    }
  }

  .cart-item-errors {
    margin: $spacer / 2 0;
    & > p {
      &:first-child {
        margin-top: 0;
      }

      &.invalid-feedback {
        display: block;
        margin-bottom: 0;
        font-weight: bold;
        color: darken($warning, 10%)!important;
      }
    }
  }

  &-placeholder {
    .rect-shape,
    .round-shape,
    .text-row,
    .rect-shape,
    .round-shape,
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }

    .product-image {
      .rect-shape {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      margin-bottom: $spacer / 2;
    }

    .text-row {
      margin-top: .2rem!important;
      max-height: .6rem!important;
      overflow: hidden!important;
    }

    .product-name-placeholder {
      margin-top: 0!important;
    }

    .product-price-placeholder,
    .product-reference-placeholder {
      max-width: 120px!important;
    }

    .product-price-placeholder {
      height: 15px!important;
      margin-bottom: 1rem;
    }

    .product-ean-placeholder {
      max-width: 140px!important;
    }

    .product-shipping-location-placeholder {
      max-width: 160px!important;
    }

    .product-brand-placeholder {
      height: 55px!important;
      max-width: 250px;
    }

    .product-quantity-placeholder {
      height: 35px!important;
    }
  }

  .product-planograms {

    h2 {
      text-transform: uppercase;
      font-size: 1rem;
    }

    th, td {
      text-align: left;
      vertical-align: middle;
    }

    .planogram-name {
        font-size: 1rem;
        margin-bottom: 0;
    }

    .catalog-image {
      width: 50px;
      border: 1px solid $gray-200;
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .quantity-selector {
    max-width: 208px;
    margin-left: 0;
    margin-right: auto;

    &.with-packing {
      .quantity-selector-packing {
        .input-group-prepend {
          .app-icon.equal {
            @include media-breakpoint-down(sm) {
              margin: 0 .3rem;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: 260px;
    }

    &.with-price {
      @include media-breakpoint-up(sm) {
        max-width: 350px;
      }

      &.multiple {
        .quantity-selector-quantity {
          max-width: 124px;

          .btn-multiple,
          & > .input-group {
            max-width: 100%;
          }
        }
        .quantity-selector-packing {
          min-width: 80px;
        }

        .quantity-selector-price {
          & > .input-group {
            & > .form-control {
              padding-left: 0;
            }
          }
        }
      }


      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        .quantity-selector-price {
          //flex: 1 1 auto;
          //width: 100%;

          .product-quantity-total-amount {
            border-left: 0 none;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.product-detail-page {
  .product-detail-placeholder,
  .product-detail-content {
    @include make-container();
    @include make-container-max-widths();
  }

  .product-detail-footer {
    @include make-container();

    .row-cross-selling {
      background-color: $gray-300;
    }
  }
}

.product-detail-modal {
  overflow-x : visible!important;

  .modal-dialog {
    overflow : visible!important;
  }

  .product-scroll-buttons {
    display: none;
    position: absolute;
    right: -15px;
    top: 0;

    &-inner {
      display: flex;
      flex-direction: column;
      position: fixed;
      height: calc(100vh - #{$modal-dialog-margin-y-sm-up});
    }

    .btn-product-detail-scroll {
      z-index: 1035;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      outline: none;
      box-shadow: none;

      &-next {
        margin-top: auto;
        margin-bottom: $modal-dialog-margin-y-sm-up;
      }
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }

    @include media-breakpoint-up(xxxl) {
      right: -30px;
    }
  }

  .modal-header {
    border-bottom: 0 none;
  }

  .modal-footer {
    border-top: 0 none;
  }

  .modal-dialog {
    border-radius: 10px;
    overflow: hidden;
    max-width: 96%;

    @include media-breakpoint-up(xl) {
      max-width: 90%;
    }

    @include media-breakpoint-up(xxxl) {
      max-width: map_get($container-max-widths, 'xl');
    }
  }

  .modal-body,
  .modal-footer,
  .modal-header {
    padding: 0;

  }

  .modal-footer,
  .modal-header {
    padding: 0;
    overflow: hidden;
    & > * {
      margin: 0;
    }
  }

  .modal-body {
    position: relative;
    overflow: hidden;

    .product-detail-content {
      padding: $modal-inner-padding;
    }

    .product-heading {
      padding-right: 1em;
    }

    .close {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 2rem;
      font-weight: 100;
      z-index: 100;
    }
  }
}