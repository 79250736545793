.import-modal {
  .modal-header {
    background-color: $gray-dark;
    color: $white;
    padding-top: $card-spacer-y;
    padding-bottom: $card-spacer-y;

    .modal-title {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    button {
      color: $white;
      opacity: 1;
    }

    .app-icon {
      margin-right: $spacer;
    }
  }

  .modal-content {
    border-color: $gray-dark;

    .modal-body {
      @include media-breakpoint-up(md) {
        padding-top: $modal-inner-padding * 2;
        padding-bottom: $modal-inner-padding * 2;
      }
    }
  }

  .quantity-selector {
    margin-left: 0;
  }

  .invalid-feedback {
    display: block;
  }

  .alert {
    p {
      margin-bottom: 0;
    }
  }

  .import-success-section,
  .import-loading-section {
    text-align: center;

    .svg-inline--fa {
      font-size: 3rem;
      color: $primary;
    }

    .title {
      margin-top: 1rem;
    }
  }

  .import-success-section {
    .svg-inline--fa {
      color: $success;
    }
  }

  .shop-import-help-card {
    margin-top: $spacer;

    .card-header {
      background-color: transparent;
      border-bottom: 0 none;
      padding: $card-spacer-x $card-spacer-y;
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      .card-title {
        flex: 0 0 auto;
      }

      .btn-help {
        margin-left: auto;
      }
    }

    .svg-inline--fa {
      margin-right: $spacer / 2;
    }

    .card-header + .card-body {
      padding-top: 0;
    }

    ol {
      padding-left: 0;
      list-style-position: inside;
    }

    .btn-link {
      padding: 0;
      color: $text-color;
    }

    .btn-show-stores,
    .btn-matrix-file-download {
      line-height: 1;
      text-decoration: underline;
      @include hover-focus-active() {
        text-decoration: underline;
      }
    }

    .btn-help {
      cursor: pointer;
      font-size: .9em;
      color: $gray-600;

      @include hover-focus-active() {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: $spacer;
      height: 100%;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;

      .card-header {
        padding: 0 $card-spacer-x $card-spacer-y;
      }
    }
  }
}

.customer-import-section {
  .customer-import-list {

    .table {

      .actions {
        .btn {
          color: #6c6d76;
          font-size: 1.2rem;
          padding: 0;
        }
      }

      .has-callback {
        cursor: pointer;
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .hv {
        flex: 0 0 auto;
      }

      .dropdown {
        margin-left: auto;
      }
    }
  }
}

.customer-import-page {
  .shop-import-errors {
    .badge {
      font-size: .8rem;
      .app-icon {
        margin-right: .4rem;
      }
    }
    //.danger {
    //  background-color: lighten($danger, 20%);
    //  color: darken($danger, 10%);
    //}
    //.warning {
    //  background-color: lighten($warning, 30%);
    //  color: darken($warning, 10%);
    //}

    .restock-alert {
      button {
        .app-icon {
          margin-right: .2rem;
        }
      }
    }
  }
}

.shop-import-errors {
  margin-top: $spacer;
}