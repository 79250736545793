.account {
  &-banner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $spacer;

    .customer-avatar {
      display: none;
      background-color: $white;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: $spacer;
    }

    .customer-business-name,
    .customer-code,
    .customer-address-code {
      text-align: center;
    }

    .customer-business-name {

      display: flex;
      align-items: center;
      justify-content: center;

      .value {
        .business-name {
          color: $primary;
          text-transform: uppercase;
        }
      }
    }

    .separator {
      display: block;
      margin:0 $spacer / 2;
    }

    @include media-breakpoint-up(md) {
      .customer-avatar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .customer-business-name,
      .customer-code,
      .customer-address-code {
        text-align: left;
      }
    }

    & > span {
      display: block;
    }
  }

  &.dropdown {

    button {
      outline: none;
      box-shadow: none;

      small {
        position: relative;
        top: -1px;
      }
    }

    .dropdown-menu {
      border-radius: $border-radius;
      width: $customer-dropdown-nav-width;
      padding-top: 0;
      padding-bottom: 0;
    }

    .logged-account-card {


      .customer-menu {
        .menu-item-nav {
          cursor: pointer;
          color: $text-color;
          text-decoration: none;

          .sub-icon {
            display: none;
          }

          .app-icon {
            margin-right: $spacer / 2;
          }
        }
      }

      .card-header {
        .logged-account-preview {
          display: block;
          width: 40px;
          height: 40px;
          background-color: $white;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
        }

        .row {
          align-items: center;
        }

        button {
          padding: 0;
          color: $text-color;
          text-decoration: none;
          position: relative;
        }


        .current-account-code,
        .current-account-address-code,
        .main-account,
        .selected-store,
        {
          display: block;
          text-align: left;
        }

        .current-account-code,
        .current-account-address-code,
        .selected-store {
          font-size: .9em;
          color: lighten($text-color, 20%);
        }

        .app-icon {
          position: absolute;
          right: 0;
          top: 8px;
        }

      }

      .card-body {
        overflow: hidden;
        max-height: $customer-dropdown-nav-body-height;
        padding-left: 0;
        padding-right: 0;

        .logged-account-part-list {
          overflow: hidden;

          &-inner {
            transition: transform $animate-css-animation-duration, opacity $animate-css-animation-duration * 1.2, height $animate-css-animation-duration * 1.2;
            animation-duration: $animate-css-animation-duration;
            animation-fill-mode: both;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            width: $customer-dropdown-nav-width * 2;
          }

          .logged-account-part {
            text-align: left;
          }

          .btn {
            text-align: center;
          }

          &.part-active {
            transform: translateX(0);

            &-multi-store {
              .logged-account-part-list-inner {
                transform: translateX(-$customer-dropdown-nav-width);
                .logged-account-part-account {
                  opacity: 0;
                }
                .logged-account-part-multi-store {
                  opacity: 1;
                  height: auto;
                }
              }
            }

            &-account {
              .logged-account-part-multi-store {
                overflow: hidden;
                height: 0;
                opacity: 0;
              }
            }
          }
        }

        .logged-account-part {
          width: $customer-dropdown-nav-width;
          flex: 0 0 $customer-dropdown-nav-width;

          .logged-account-part-heading {
            margin-bottom: $spacer / 2;
            text-transform: uppercase;
          }

          &-account {
            padding-left: $card-spacer-x;
            padding-right: $card-spacer-x;
          }

          &-multi-store {
            .multi-store-section {
              .btn-back {
                padding-left: 0;
                padding-right: 0;
                text-align: left;
              }
            }

            .logged-account-part-heading {
              padding-left: $card-spacer-x;
              padding-right: $card-spacer-x;
              font-weight: 600;
            }

            .multi-store-list {
              overflow: hidden;
              overflow-y: scroll;
              max-height: $customer-dropdown-nav-body-height - 50px;

              .btn-store {
                padding: 0;
                color: $text-color;
                text-align: left;
              }

              .multi-store-row {
                padding-left: $card-spacer-x;
                padding-right: $card-spacer-x;
                padding-top: $card-spacer-y / 2;
                padding-bottom: $card-spacer-y / 2;
                border-bottom: 1px solid $gray-200;

                .business-name {
                  display: block;
                }

                .account-address {
                  color: lighten($text-color, 20%);
                  display: block;
                  > span {
                    display: block;
                  }
                }

                @include hover-focus-active() {
                  background-color: $gray-100;
                }
                button {
                  text-decoration: none;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.account-card {
    .business-name {
      display: block;
      .name {
        display: block;
      }
    }

    &.principal {
      .business-name {
        .bdge {
          text-transform: uppercase;
          //font-size: 1rem;
          display: block;
        }
        .name {
          display: none;
        }
      }
    }

    .account-address {
      color: lighten($text-color, 20%);
      display: block;
      > span {
        display: block;
      }

      .city-country {
      }
    }
  }
}
