.customer-salesmen {
  &.member-card {
    .store-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      & > .app-icon {
        font-size: 16px;
        line-height: 1;
        margin-right: $spacer / 2;
        color: $black;
        display: none;
      }

      .account-card {
        .business-name {
          font-weight: bold;
        }

        .business-name,
        .account-address {
          display: inline-block;
        }

        .account-address {
          &:before {
            content: "-";
            display: inline-block;
            margin-left: $spacer / 4;
            margin-right: $spacer / 4;
          }
        }
      }


      @include media-breakpoint-up(lg) {
        .app-icon {
          display: inline-block;
        }
      }
    }

    .shipping-location-salesmen {
      margin-bottom: $spacer * 2;

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $spacer / 4;

        .app-icon {
          line-height: 1;
          font-size: 18px;
          margin-right: $spacer;
        }

        .lbl {
          font-size: 1rem;
          font-weight: bold;
        }

        color: $primary;
      }

      .list {
        border-left: 1px solid $gray-400;
        margin-left: $spacer / 3;
        padding: $spacer;

        .salesman {
          margin-bottom: $spacer * 2;
        }

        & > div:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // INNER MODAL
  .advice-modal & {
    background: transparent;
    border: 0 none;
    .card-header {
      text-align: center;
      background: transparent;
      border: 0 none;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // INNER CUSTOMER AREA
  .customer-salesmen-section & {
    .store-info {
      margin-top: $spacer / 4;
      margin-bottom: $spacer;
      justify-content: flex-start;
    }
  }
}