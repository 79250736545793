.multi-store-cart-table {
  .action-show {
    min-width: 214px;

    .btn + .btn {
      margin-top: $spacer / 2;
    }
  }

  tr.locked {
    //font-weight: bold;
    text-align: center;
    background-color: lighten($success, 40%);
    //color: darken($success, 20%);
  }
}