.catalog-list-page {
  background-color: transparent;

  & > .breadcrumb-section {
    margin-bottom: 0;

    .breadcrumb {
      margin-bottom: 0;
    }
  }

  .catalog-list-page-content {
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
    }

    & > [class*="col-"],
    & > .col {
      @include media-breakpoint-up(lg) {
        padding-top: 1rem;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
      }
    }

    .col-sidebar {
      padding-top: 0;
      background-color: white;
      display: none;
      position: relative;
      @include catalog-list-sidebar-width();

      @include media-breakpoint-up(lg) {
        display: block;
      }

      .btn-toggle-collapse {
        display: none;
        color: $gray-900;
        text-decoration: none;
        font-size: 1rem;
        position: absolute;
        right: 10px;
        top: 4px;
      }

      .cms-widget-simple_highlight {
        padding: $spacer / 2;
        cursor: pointer;

        & + .cms-widget-simple_highlight {
          margin-top: $spacer / 2;
        }

        &.cms-widget-theme {
          &-default {
            background-color: $gray-100;
          }
        }
      }
    }

    .col-catalog-resource-list {
      background-color: $gray-100;
    }
  }
}

.catalog-filter-list {

  // DEBUG GLOBAL

  .heading {
    .badge {
      background-color: $gray-800;
      color: $white;
    }

    &:after {
      display: inline-block;
      font-family: $font-Flaticon-family;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }
  }

  .catalog-filter {
    .content {
      mark {
        padding: 0;
      }
    }

    .action {

      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: nowrap;

      .btn-save,
      .btn-cancel {
        border-top: 2px solid $gray-800;
        border-radius: 0;
        outline: none;
        box-shadow: none;
        text-decoration: none;
        padding: $spacer / 2 $spacer;
        font-size: 1.2em;
      }

      .btn-save {
        background-color: $gray-800;
        color: $white!important;

        &:not([disabled]) {
          @include hover-focus-active() {
            background-color: lighten($gray-800, 10%);
            border-top-color: lighten($gray-800, 10%);
          }
        }
      }

      .btn-cancel {
        border-right: 1px solid $gray-800;

        &:not([disabled]) {
          @include hover-focus-active() {
            background-color: $gray-100;
          }
        }
      }

      .btn-link {
        color: $text-color;
      }
    }

    &-type-range {
      .content {
        .filter-price-value {
          padding: 1rem;
        }

        .row {
          align-items: center;
        }
        .col-separator {
          padding: 0;
        }

        .separator {
          height: 2px;
          width: 40px;
          background-color: $gray-300;
          display: block;
        }

        .input-group {
          border: 1px solid $gray-300;

          input {
            font-size: 1.2em;
            margin-left: 0;
            outline: none;
            box-shadow: none;
          }

          .input-group-text {
            font-size: 1.4em;
          }

          input,
          .input-group-text,
          .input-group-append {
            background: transparent;
            border: 0 none;
            border-radius: 0;
          }
        }
      }
    }

    &-type-order,
    &-type-option {

      .catalog-filter-search {
        //margin: 0 2px 0 2px;
        box-shadow: inset 0 -1px 0 0 $gray-300;

        & > .form-group {
          padding: .4rem $spacer;
          margin-bottom: 0;

          .app-icon {
            font-size: 1.4em;
          }

          input {
            font-size: 1.2em;
            margin-left: 0;
            padding-left: 0;
            outline: none;
            box-shadow: none;
          }

          input,
          .input-group-text,
          .input-group-append {
            background: transparent;
            border: 0 none;
            border-radius: 0;
          }
        }
      }

      .values {
        overflow-y: scroll;
        //margin: 0 2px 2px;

        label {
          cursor: pointer;
        }

        & > .entry {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          position: relative;
          box-shadow: inset 0 -1px 0 0 $gray-300;
          margin-right: 0;
          cursor: pointer;
          padding: $spacer;

          @include hover-focus-active() {
            background-color: $gray-200;
          }

          .app-icon {
            display: none;
          }

          &.is-selected {
            background-color: $gray-100;
            .form-label-text {
              font-weight: bold;
            }

            .app-icon-selected {
              display: block;
              position: absolute;
              right: 10px;
              top: 50%;
              font-size: 1.3em;
              transform: translateY(-50%);
            }
          }
        }

        .form-label-image,
        .form-label-color {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          margin-right: $spacer / 2;
          border: 1px solid $gray-500;

          & > span {
            display: block;
            position: relative;
            width: 18px;
            height: 18px;

            & > img {
              max-width: 100%;
            }

            & > img,
            & > span {
              display: block;
              width: 100%;
              height: 100%;
            }

            & > span {
              &.no-color {
                display: flex;
                align-items: center;
                justify-content: center;
                .app-icon {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }


  }

  // FIN GLOBAL

  &.desktop {
    position: relative;
    &.disabled {
      z-index: 0;
      opacity: .7;
    }

    margin-bottom: $spacer;

    .btn-reset-all {
      padding: 0;
      color: #333;
      text-decoration: underline;
    }

    .catalog-filter-list-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & > div {
        margin-top: $spacer / 4;
        margin-bottom: $spacer / 4;
        margin-right: $spacer / 2;
        &:last-child {
          margin-right: 0;
        }

        &.title {
          display: none;
          //display: flex;
          align-items: center;
          justify-content: center;

          span {
            &:after {
              content: " :";
            }
          }
        }
      }
    }

    .catalog-filter {
      z-index: 1;
      max-height: 500px;
      padding-bottom: 0;

      .heading {
        cursor: pointer;
        position: relative;
        font-size: 1.1em;
        background: $white;
        padding: $spacer / 2 $spacer;
        box-shadow: inset 0 0 0 1px $gray-300;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .app-icon {
          font-size: .9em;
          margin-left: $spacer / 2;
        }

        .subtitle {
          display: none;
        }

        &:after {
          margin-left: $spacer / 2;
          content: map-get($flaticon-map, "chevron-down");
        }

        @include hover-focus-active() {
          background-color: $gray-200;
          box-shadow: inset 0 0 0 2px $gray-800;
        }
      }

      &:not(.empty) {
        .heading {
          box-shadow: inset 0 0 0 2px $gray-800;
          font-weight: bold;
        }
      }

      &.open {
        z-index: 20;
        position: relative;
        .heading {
          box-shadow: inset -2px 0 0 0 $gray-800, inset 2px 0 0 0 $gray-800, inset 0 2px 0 0 $gray-800, inset 0 -2px 0 0 $white;
          &:after {
            content: map-get($flaticon-map, "chevron-up");
          }
        }
      }

      .content {
        min-width: 288px;
        position: absolute;
        top: 95%;
        left: 100%;
        transform: translateX(-100%);
        border: 2px solid $gray-800;
        background: #fff;
        z-index: -1;

        mark {
          padding: 0;
        }
      }

      &-type-order,
      &-type-option {
        .values {
          max-height: 280px;
          overflow-y: scroll;
        }
      }

      &-classification {
        .heading {
          box-shadow: inset 0 0 0 2px $green-dark;
        }
      }
    }
  }
}

.mobile-filter-page {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --page-height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));

  transition: all 250ms linear 50ms;
  position: fixed;
  z-index: 20000;
  left: 0;
  right: 0;
  top: 100%;
  opacity: 0;
  background-color: $white;
  height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));

  &.show {
    opacity: 1;
    top: 0;

    .root-page {
      .footer {
        position: fixed;
      }
    }
  }

  .root-page {
    height: 100%;

    .header {
      padding: $spacer / 2;
      box-shadow: inset 0 -1px 0 0 $gray-300;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      .btn {
        color: $text-color;
      }

      button:last-child {
        margin-left: auto;
      }
    }

    & > .content {
      & > .heading {
        display: block;

        .resource-list-counter {
          font-style: italic;
          text-align: center;
          padding: ($menu-mobile-link-padding-y + $nav-link-padding-y) / 2 ($menu-mobile-link-padding-x + $nav-link-padding-x) / 2;
          border-bottom: $menu-child-border-width solid $menu-child-border-color;
        }
      }
    }

    .catalog-filter-list {
      .catalog-filter-list-inner {
        & > .title {
          display: none;
        }

        & > .catalog-filter {
          border-bottom: $menu-child-border-width solid $menu-child-border-color;
          //padding: $menu-mobile-link-padding-y $menu-mobile-link-padding-x;
          color: $mobile-menu-link-color;

          .heading {
            color: inherit;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: $menu-mobile-link-padding-y + $nav-link-padding-y $menu-mobile-link-padding-x + $nav-link-padding-x / 2;

            .title {
              width: 75%;

              .lbl {
                font-weight: bold;
              }
            }

            .subtitle {
              margin-top: .2rem;
              display: block;
              font-size: .95em;
              color: lighten($text-color, 20%);
              @include truncate-text();
              white-space: nowrap !important;
              width: 100%;
            }

            &:after {
              margin-left: auto;
              content: map-get($flaticon-map, "chevron-right");
            }
          }

          @include hover-focus-active() {
            border-color: $mobile-menu-hover-bg-color;
            background-color: $mobile-menu-hover-bg-color;

            & > .nav-link {
              color: $mobile-menu-hover-color;
            }
          }
        }
      }
    }

    .footer {
      bottom: 0;
      left: 0;
      right: 0;

      .btn {
        border-radius: 0;
        outline: none;
        box-shadow: none;
        text-decoration: none;
        padding: $spacer / 2 $spacer;
        font-size: 1.2em;
      }
    }
  }

  .detail-page {
    transition: all 250ms linear 50ms;
    position: fixed;
    z-index: 21000;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: $white;

    .heading {
      padding: $spacer / 2;
      box-shadow: inset 0 -1px 0 0 $gray-300;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &:before {
        @include flat_icon();
        content: map-get($flaticon-map, "chevron-left");

        font-size: 1.4em;
        margin-right: $spacer / 2;
      }

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .badge {
          margin-left: .2rem;
        }
      }

      .lbl {
        font-size: 1.3em;
      }

      .subtitle {
        display: none;
      }
    }

    .catalog-filter {
      .values {
        flex-grow:1;
      }

      .action {
        display: block;
        .btn-save {
          border-top: 0 none;
          margin-top: 0;
        }
      }
    }

    &  > .content {
      height: 100%;

      .catalog-filter {
        height: 100%;
        display: flex;
        flex-direction: column;

        .content {
          flex: 1 0 0;
          height: calc(100% - 43px);
          position: relative;
          display: flex;
          flex-direction: column;

          .btn-cancel {
            z-index: 10;
            border: 0 none;
            position: fixed;
            right: 0;
            top: 0;
            display: inline-block;
            width: auto;
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
          }
        }

        &-type-range {
          .action {
            margin-top: auto;
          }
        }
      }
    }

    &.show {
      opacity: 1;
      transform: translateX(-100%);
    }
  }
}

.catalog-list-section {

  .catalog-list-footer,
  .catalog-list-content {
    @include media-breakpoint-down(lg) {
      padding-left: $grid-gutter-width / 4!important;
      padding-right: $grid-gutter-width / 4!important;
    }
  }

  .catalog-list-footer {
    margin-bottom: $spacer;
  }

  .alert {
    padding: $alert-padding-x;
    p {
      line-height: 1;
    }
  }

  .catalog-list-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    .catalog-list-filter-section {
      order: 3;
    }

    .catalog-list-action-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 2;

      & > .catalog-list-counter {
        margin: $spacer / 2 0;
      }

      & > .product-picker-action {
        width: 100%;

        .product-picker-action-content {
          margin-bottom: $spacer / 4;
          display: flex;
          flex-direction: row;

          .dropdown {
            .dropdown-item {
              .app-icon {
                margin-right: $spacer / 1.5;
              }
            }
          }

          & > button {
            flex: 1 1 auto;
          }

          .btn {
            .app-icon {
              margin-right: $spacer / 2;
            }
          }

          .btn-cancel {
            display: none;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        @include media-breakpoint-up(md) {
          width: auto;
          margin-right: $spacer / 2;
          margin-bottom: 0;
          display: block;
        }
      }

      .catalog-list-counter {
        &.disabled {
          opacity: .5;
        }
      }

      .catalog-list-actions {
        flex: 1 1 100%;
        width: 100%;
        max-height: 40px;

        border-top: 1px solid $gray-500;

        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;

        .btn-filter {
          text-align: left;
          border: 0 none;
          //border-right: 1px solid $gray-500;
          text-transform: uppercase;
          box-shadow: none;
          outline: none;

          svg {
            margin-right: .4rem;
            fill: $gray-600;
            color: $gray-600;
          }

          &.loading {
            .app-icon {
              display: none;
            }
          }

          &.active {
            border-bottom-color: transparent;
          }

          background-color: transparent;

          @include hover-focus-active() {
            background-color: transparent;
          }
        }

        .mobile-filter-list {
          &:not(.is-empty) {
            .btn-filter {
              font-weight: bold;
            }
          }
        }

        & > .form-inline {
          justify-content: flex-end;
          .catalog-list-sort {
            margin-right: 0;
          }
        }

        & > .form-inline {
          flex: 1 1 50%;
          min-width: 50%;
          padding: .4rem;
          border-bottom: 1px solid $gray-500;
        }

        & > .form-inline {
          @include media-breakpoint-down(sm) {
            flex: 1 1 auto;
            min-width: 1%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: nowrap;

            .catalog-list-action {
              .form-label {
                font-size: .85rem;
              }
            }
          }
        }

        .form-inline {
          margin-left: auto;

          & > div {
            margin-right: .5rem;
            &:last-child {
              margin-right: 0;
            }

            &.catalog-list-items-per-page {
            }

            &.catalog-list-sort {
              label {
                display: none;
              }
            }
          }
        }

        .catalog-list-action {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0;

          .form-label {
            font-size: .6rem;
            margin-bottom: 0;
          }

          position: relative;
          .form-control {
            width: auto;
          }

          select {
            appearance: none;
            border: 0 none;
            background-color: transparent;
            outline: 0;
          }

          &:after {
            content: map-get($flaticon-map, "chevron-down");
            font-family: $font-Flaticon-family;
            color: #000;
            display: inline-block;
            pointer-events: none;
            box-sizing: border-box;
          }
        }
      }

      .form-group {

      }

      .form-label {
        font-size: .8rem;
        text-transform: uppercase;
        margin-right: .5rem;

        &:after {
          content: " : ";
        }
      }

      .form-control {
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: nowrap;

        .catalog-list-actions {
          border-top: 0 none;
          margin-left: auto;
          flex: .1 1 52%;
          width: auto;

          .btn-filter {
            display: none;
          }

          .catalog-list-action {
            //display: block;
          }

          label {
            &.form-label {
              //display: none;
              font-size: .8rem!important;
            }
          }

          .form-inline {
            border-bottom: 0 none;

            justify-content: flex-end;
            & > div {
              &:first-child,
              &:last-child {
                flex: 0 0 auto;
                min-width: 0;
              }

              &.catalog-list-sort {
                label {
                  &.form-label {
                    display: inline-block;
                  }
                }
              }

              &.catalog-list-items-per-page {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: block!important;
      .catalog-list-actions {
        flex: .1 1 50%;
        max-height: none;
      }
    }
  }

  .catalog-list-content {
    padding: $spacer 0 0;
    position: relative;

    .catalog-list {
      margin-left: -$grid-gutter-width / 4;
      margin-right: -$grid-gutter-width / 4;

      & > [class*="col-"],
      & > .col {
        padding-left: $grid-gutter-width / 4;
        padding-right: $grid-gutter-width / 4;

        flex: 0 0 50%;
        max-width: 50%;

        @include media-breakpoint-up(sm) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }

        @include media-breakpoint-up(xl) {
          flex: 0 0 25%;
          max-width: 25%;
        }

        @include media-breakpoint-up(xxxl) {
          flex: 0 0 20%;
          max-width: 20%;
        }

        @include media-breakpoint-up(xxxl) {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }

        & > div {
          margin-bottom: $spacer;
        }
      }
    }

    .catalog-list-overlay {
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      bottom: 1rem;
      background: rgba(0, 0, 0, 0.2);
      z-index: 15;
      display: none;
      flex-direction: row;
      justify-content: center;

      .app-icon {
        margin-top: 5rem;
        font-size: 3rem;
        color: rgba(255, 255, 255, 0.7);
      }

      &.showing {
        top: 0;
        display: flex;
        animation-name: fadeInUp;
      }
    }
  }

  .catalog-list-footer {

  }
}

.catalog-list-section {

  .catalog-list-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    .catalog-list-action-section {
      @include media-breakpoint-between(md, lg) {
        .catalog-list-display-mode {
          .form-label {
            display: none;
          }
        }
      }
    }
  }

  .catalog-list-content {
    padding: $spacer 0 0;
    position: relative;
  }
}
