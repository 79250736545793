.customer-order-page {
  .table {
    tbody {
      td {
        text-align: left;
      }
    }
  }

  #member-area-row-top {
    flex-direction: row-reverse;

    .account-banner {
      justify-content: flex-end;
    }
  }
}

.order-detail {
  #dropdown-reorder {
    &:after {
      display: none;
    }
  }

  .account-card {
    .code {
      font-style: italic;
      font-size: .9em;
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }

  .member-card {
    &.resume {
      .card-header {
        .hv {
          .order-code {
            font-family: $headings-font-family;
            font-size: .6em;
          }
        }
      }

      table {
        tr > td:first-child {
          @include media-breakpoint-up(lg) {
            width: 200px;
          }
        }
      }
    }

    &.volume {
      margin: $spacer * 1.5 0;
      .container-volumes {
        border-top: 0 none;
        padding: 0;
        margin: 0;

        .container-volume-list {
          width: 100%;

          & > div {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        .resume {
          .lbl {
            display: none;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &.actions {

      .actions-separator {
        margin-top: $spacer;
        margin-bottom: $spacer;
        border-top: 1px solid $gray-500;
        width: 100%;
      }

      .re-order-action {
        width: 100%;
        margin-bottom: $spacer / 2;

        ~ .btn {

        }
      }

      .dropdown > button, .btn {
        padding-top: $btn-padding-y-lg;
        padding-bottom: $btn-padding-y-lg;
      }

      .dropdown {
        &.show {
          & > button {
            background-color: $gray-500;
            border-color: $primary;
            border-bottom: 0 none;
            outline: none;
            box-shadow: none;
          }

          & > .dropdown-menu {
            background-color: $white;

            &.show {
              right: 0!important;
              border-radius: 0;
              border-color: $primary;
              border-top: 0 none;
              transform: translate(0px, 38px)!important;
            }

            .dropdown-item {
              text-align: center;
              &:before {
                content: "> ";
              }

              @include hover-focus-active() {
                background-color: $gray-100;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        .card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > .dropdown, > button {
            display: block;
            width: 100%;
          }
        }
      }
    }

    &.items, &.invoices {
      margin-top: $grid-gutter-width;
    }

    &.invoices {
      .order {
        display: none;
        a {
          text-decoration: none;
          color: $text-color;
          pointer-events: none;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .row-order-detail-top {
      .col-volume-actions {
        display: flex;
        flex-direction: column;

        & > div {
          flex: auto;
        }
      }
    }
  }

  .order-detail-table {
    thead,
    tbody,
    tfoot {
      & > tr {
        th, td {
          text-align: center;
        }

        & > th:first-child,
        & > td:first-child {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        & > td:first-child,
        & > td:nth-child(2) {
          text-align: left;
        }

        & > td:last-child {
          font-weight: 600;
          font-size: 1.05em;
        }
      }
    }

    thead {
      tr {
        th, td {
          border-top: 0 none;
          font-weight: normal;
        }
      }
    }

    tfoot {
      tr {
        td {
          font-weight: 600;
        }

        td:first-child {
          font-weight: 600;
          text-transform: uppercase;
          color: $primary;
        }

        td:last-child {
          text-align: center;
          font-weight: 600;
          font-size: 1.05em;
          color: $primary;
        }
      }
    }

    .col-product,
    .product-heading {
      text-align: left;
    }

    .col-product-checkbox {
      //padding: 0;
      //text-align: center;
      //vertical-align: middle;
      padding-right: 0;

      & > div {
        text-align: center;
      }
    }

    .order-detail-quantity {
      width: 125px;
    }

    .product-name {
      font-size: .75rem;
      margin-bottom: 0;
    }

    .product-card {
      min-width: 60px;
    }

    .product-packing {
      text-align: center;
      font-weight: normal;
      .app-icon {
        font-size: 1.1rem;
        margin-left: .4rem;
      }
    }

    .product-field {
      display: block;
      font-size: .9em;
      .lbl {
        &:after {
          content: " : ";
          font-size: .85em;
          margin-right: .1rem;
        }
      }

      .value {
        font-weight: bold;
      }
    }
  }

  &-placeholder {
    .rect-shape,
    .round-shape,
    .text-row,
    .rect-shape,
    .round-shape,
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }

    .text-row {
      min-width: 140px;
    }

    .btn-fake {
      max-height: 44px;
    }

    .btn-fake + .btn-fake {
      margin-top: $spacer;
    }
  }

  .picture-export-section {
    .help-message {
      margin-bottom: $spacer;
      .app-icon {
        margin-right: $spacer / 1.2;
        color: $primary;
      }
    }

    .picture-export-actions {
      text-align: center;
    }
  }

  .heading,
  .product-export-section {
    .product-picker-action-button {
      .app-icon {
        margin-right: $spacer / 2;
      }
    }
  }

  @include media-breakpoint-up(md) {

    .product-card {
      min-width: 30px!important;
    }

    .heading,
    .product-export-section {
      .product-picker-action-button {
        font-size: 0.9rem;
      }
    }

    .picture-export-section {
      .picture-export-actions {
        text-align: right;
      }

      .help-message {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: row;

        .app-icon {
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.modal.order-pdf-modal {
  @include media-breakpoint-down(lg) {
    .embed-responsive {
      &:before {
        padding-top: 80vh;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-dialog {
      max-width: 890px;
    }
  }
}

.re-order-action-modal-buying-group,
.re-order-action-modal-multi-store {
  &:not(.duplicate-modal) {
    .table {
      td {
        padding: 0;
      }
    }
  }

  .buying-group-lbl,
  .multistore-lbl {
    margin-top: $spacer / 2;
    display: block;
  }
  .account-address {
    font-size: .8em;
  }
}