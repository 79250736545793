.member-top-bar {
  background: $white;
  border-bottom: 2px solid $primary;
  display: none;

  .account-picker,
  .account-resume {
    .form-label {
      margin-bottom: 0;
      margin-right: .5rem;
      flex: 0 0 auto;
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .app-icon {
        color: $primary;
        font-size: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: .3rem 0;
      }

      span {
        margin-left: .5rem;
      }
    }
  }

  .account-picker {
    margin-left: auto;
    max-width: 460px;
  }

  &.customer-top-bar {
    .account-selector {
      .account-card {
        color: $text-color;
        &.selected-card {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .account-address {
            font-size: .8rem;

            & > span {
              display: inline-block;
              vertical-align: middle;
              line-height: 1.2;
            }

            .city-country {
              .city {
                &:before {
                  padding-left: .2rem;
                  content: " - ";
                  padding-right: .2rem;
                }
              }

              .postcode {
                display: none;
              }

              .country-name {
                &:before {
                  padding-left: .2rem;
                  content: " - ";
                  padding-right: .2rem;
                }
              }
            }

            & > [class*="address-"] {
              display: none;
            }
          }
        }
      }

      .ac__control {
        min-height: auto;
        border-radius: 0;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-color: $input-border-color;
        box-shadow: none;

        &--is-focused {
          border-color: $input-border-color;
          box-shadow: none;
        }
      }

      .ac__menu {
        z-index: 9999;
        border-radius: 0;

        .ac__menu-list {
          border-radius: 0;

          .ac__option {
            .business-name {
              font-weight: bold;
            }

            .account-address {
              font-size: .8rem;
            }

            &--is-hovered,
            &--is-focused {
              cursor: pointer;
              background-color: lighten($primary, 35%);

              //.business-name,
              //.account-address {
              //  color: darken($white, 45%);
              //}
            }

            &--is-selected {
              background-color: $primary;
              .business-name {
                color: $white;
              }
              .account-address {
                color: darken($white, 5%);
              }
            }
          }
        }
      }

      .ac__indicators {
        .ac__indicator-separator {
          display: none;
        }

        .ac__indicator {
          padding: 0;
          border: 0 none;
          color: $primary;
        }
      }
    }
  }

  &.salesman-top-bar {
    .btn-personal-space,
    .btn-switch-account {
      color: $text-color;
      padding: 0;

      .app-icon {
        margin-right: $spacer;
      }

      .lbl {
        text-decoration: underline;
      }
    }

    .account-resume {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: .5em 0;

      .form-label {
        padding-right: $spacer / 2;
        border-right: 1px solid $input-border-color;
      }
    }

    .account-card {
      color: $text-color;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .account-address {
        margin-left: $spacer / 2;
      }

      padding-right: $spacer / 2;
      margin-right: $spacer / 2;
      border-right: 1px solid $input-border-color;
    }

    .order-mode {
      color: lighten($text-color, 20%);
      font-weight: bold;

      .form-group {
        margin-bottom: 0;
      }
    }

    &.order-mode-asset {
      background-color: $persian-green;
      border-color: darken($persian-green, 5%);
      color: white;

      .account-resume {
        .app-icon {
          color: darken($persian-green, 10%);
        }

        .form-label {
          text-transform: uppercase;
        }
      }

      .account-card,
      .account-resume,
      .btn-switch-account {
        color: white;
      }

      .account-address {
        color: darken(white, 5%);
      }

      .order-mode {
        .form-control {
          &:active,
          &:focus {
            border-color: darken($persian-green, 10%);
            box-shadow: 0 0 0 $input-btn-focus-width rgba(lighten($persian-green, 10%), .25);
          }
        }
      }
    }

    & > .container > .align-items-center {
      flex-wrap: nowrap;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}