.top-bar {
  background: $top-bar-bg;
  color: $top-bar-tint-color;
  font-size: 0.929em;

  & > .container {
    //padding-left: $grid-gutter-width / 4;
    //padding-right: $grid-gutter-width / 4;
    //
    //.row {
    //  margin-left: 0;
    //  margin-right: 0;
    //
    //  [class*="col-"],
    //  .col {
    //    padding-left: 0;
    //    padding-right: 0;
    //  }
    //}
  }

  .schedule-advice {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;

    .separator,
    .schedule {
      display: none;
    }

    .advice {
      cursor: pointer;

      .app-icon {
        margin-right: $spacer / 2;
        color: white;
      }
    }

    @include media-breakpoint-up(md) {
      .separator,
      .schedule {
        display: block;
      }

      .separator {
        margin: 0 1rem;
        &:after {
          content: "|";
          opacity: .6;
        }
      }

      .advice {
        a {
          .app-icon {
            display: inline-block;
          }
        }
      }
    }
  }

  .language-switcher {
    color: $top-bar-tint-color;
    text-transform: uppercase;
    font-size: 0.929em;
    margin-bottom: 0;

    .dropdown {

      & > button {
        padding-right: 0;
        & > .current-locale {
          display: flex;
        }
      }

      button {
        padding-top: 0;
        font-size: 1.2em;
        display: flex;
        flex-direction: row;

        .locale-item {
          font-size: inherit;

          span {
            font-size: inherit;
          }
        }

        &:after {
          color: $white;
          font-weight: $font-weight-normal;
          content: map-get($flaticon-map, "chevron-down");
          font-family: $font-Flaticon-family;
          border: 0 none;
          vertical-align: 0;
          margin-left: 0;
        }
      }
    }

    .dropdown-menu {
      min-width: 5rem;

      .dropdown-item {
        text-transform: uppercase;
        font-size: 0.929em;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      .dropdown {
        button {
          padding-right: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      + .dropdown-menu {
        min-width: 4rem;
        left: -10px!important;
      }
    }
  }
}