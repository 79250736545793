

.main-menu {
  background-color: $menu-bg;

  .nav-link {
    &.active {
      color: $primary;
    }
  }

  .nav-item-location {
    & > a {
      & > span {
        //text-transform: uppercase;
      }
    }
  }

  .nav-item-department {
    & > a {
      & > span {
        //text-transform: lowercase;
        //&:first-letter {
        //  text-transform: uppercase;
        //}
      }
    }
  }
}

#desktop-menu {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .nav-item {
    position: relative;

    & > .nav-link {
      & > .badge {
        position: absolute;
        top: 0;
        right: -2px;
      }
    }

    .nav-link {
      word-break: unset;
      white-space: nowrap;
      padding-top: 0;
      display: inline-block;
    }

    .nav-link:not(.active) {
      color: $menu-link-color;
    }

    @include hover-focus-active() {
       & > .nav-link:not(.active) {
        color: $menu-link-color-hover;
        &.no-url {
          color: $menu-link-color;
          cursor: default;
          pointer-events: none;
        }
      }
    }

    &.nav-item-discounts {
      .nav-link {
        span {
          color: $primary;
        }

        @include hover-focus-active() {
          span {
            color: darken($primary, 5%);
          }
        }
      }
    }
  }

  & > .navbar-nav > .nav-item {

    &.active {
      border-bottom: 1px solid $primary;
    }

    &.megamenu > .megamenu-heading > .nav-item > .nav-link,
    & > .nav-link {
      &.no-url {
        cursor: pointer;
        pointer-events: all;
      }
    }

    @include hover-focus-active() {
      &.megamenu > .megamenu-heading > .nav-item > .nav-link,
      & > .nav-link {
        color: $menu-link-color-hover;
      }
    }
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .nav-item {
      &.has-children {
        position: relative;

        & > .link-item-children {
          position: absolute;
          top: 94%;
          left: 0;
          display: none;
          background-clip: padding-box;
          text-align: left;
          list-style: none;
          z-index: $menu-child-link-z-index;
          min-width: $menu-child-link-min-width;
          padding: $menu-child-link-padding-y $menu-child-link-padding-x;
          margin: $menu-child-link-margin-y $menu-child-link-margin-x $menu-child-link-margin-x;
          font-size: $menu-child-link-font-size;
          color: $menu-child-link-color;
          background-color: $menu-child-bg-color;
          border: $menu-child-border-width solid $menu-child-border-color;
          border-top: 0;

          .link-item {
            position: relative;
            display: block;
            width: 100%;
            padding: $menu-child-link-padding-y $menu-child-link-padding-x;
            clear: both;
            font-weight: 400;
            color: $menu-child-link-color;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
          }

          .link-item-children {
            top: 0;
            left: 100%;
            border: $menu-child-border-width solid $menu-child-border-color;
            min-width: $menu-sub-child-link-min-width;
          }
        }

        //&:hover,
        //&:focus,
        //&:active,
        &.open {
          & > .link-item-children {
            display: block;
          }
        }
      }
    }

    & > .nav-item {
      display: block;
      padding: .2rem 1rem;

      & > .nav-link,
      .megamenu-nav-item {
        text-transform: uppercase;
      }

      &:hover,
      &:focus,
      &:active,
      &.open {
        background: $white;
      }

      &.show {
        background: $white;
      }
    }

    .nav-link {
      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .megamenu {
    position: static!important;
    padding-bottom: 1px;

    &:after {
      content: " ";
      background-color: red;
      display: block;
    }
    & > .link-item {
    }

    .megamenu-container {
      padding: $spacer * 2 0;
      border: $menu-child-border-width solid $menu-child-border-color;
    }

    .megamenu-content {
      display: none;
      position: absolute;
      top: 98%;
      left: 0;
      width: 100%;
      z-index: $megamenu-z-index;
      background: $megamenu-bg-color;

      .megamenu-content-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        & > div {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
          padding: 0 $grid-gutter-width;
        }
      }

      .section-item {
        .section-item-heading {
          & > .nav-item {
            & > .nav-link {
              padding: 0;
              display: flex;
              align-items: center;
              flex-direction: row;
              flex-wrap: nowrap;
              color: $menu-link-color!important;
            }

            font-size: $section-heading-font-size-base;
            display: block;
            margin-bottom: $section-heading-margin-y;
            padding-bottom: $section-heading-padding-y;
            border-bottom: $section-heading-border-width solid $section-heading-border-color;

            span {
              font-size: $section-heading-font-size-title;
            }

            i {
              margin-right: $section-heading-margin-x-icon;
              font-size: $section-heading-font-size-icon;
            }
          }
        }

        &.section-item-shipping-location {
          .section-item-shipping-location {
            .section-item-heading {
              & > .nav-item {
                font-size: $section-heading-font-size-base * 0.8;
                border-width: $section-heading-border-width / 2;
              }
            }
          }
        }

        .section-item-content {
          margin-top: $spacer;


          .section-item-shipping-location {
            flex-basis: 0;
            flex-grow: 1;
            //min-width: 0;
            max-width: 100%;

            & > .section-item-content {
              display: flex;
              flex-direction: row;

              & > div {
                margin-left: $spacer * 2;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }

          &.section-item-shipping-location {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            & > div {
              margin: 0 .4rem;
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }

              & > .section-item-heading {
                .app-icon {
                  display: none;
                }
              }
            }
          }

          .nav-item {
            &:not(.nav-item-location) {
              .nav-link {
                margin-left: 18px;

                & > i {
                  position: absolute;
                  left: 0;
                  top: 0;
                  display: inline-block;
                  color: $menu-child-link-color;
                  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
                  opacity: 0;
                  width: 0;
                }

                & > span {
                  transition: color 0.3s ease-in-out;
                  display: inline-block;
                  position: relative;

                  &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $menu-child-link-color-hover;
                    visibility: hidden;
                    transition: all 0.2s ease-in-out;
                  }
                }

                @include hover-focus-active() {
                  & > i {
                    opacity: 1;
                    width: 18px;
                    color: $menu-child-link-color-hover;
                  }

                  & > span {
                    color: $primary;

                    &:before {
                      visibility: visible;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cms-widget-simple_highlight {
        background-color: transparent;
        .embed-responsive {
          &:before {
            padding-top: (276 * 100% / 330);
          }
        }

        .heading {
          display: none;
        }
      }

      .megamenu-content-preview {
        position: relative;

        &.has-department {
          .cms-block {
            display: none;
          }
        }

        .department-preview {
          width: 100%;
          display: block;
          overflow: hidden;
          position: relative;

          &:after {
            content: '';
            display: block;
            padding-top: 172%;
          }

          &-inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
            background-size: 80%;
            z-index: 4;
          }

          img {
            height: 100%;
            transition: $animate-css-animation-duration filter linear;
            object-fit: cover;

            &.placeholder {
              filter: blur(1rem);
            }

            &.picture {
              filter: blur(0);
            }
          }
        }
      }

      .megamenu-content-list {
        & > .section-item:last-of-type {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }
      }

      .list-item-children {
        display: none;
        margin-left: $spacer / 2;
      }
    }

    //&:hover,
    //&:focus,
    //&:active,
    &.open {
      .megamenu-content {
        display: block;
      }
    }
  }
}

.bm-menu-wrap {
  z-index: 1039!important;
}
.bm-overlay {
  z-index: 1038!important;
}

.bm-menu-wrap {
  background-color: $white;
  padding-top: $menu-sticky-height;
  @include media-breakpoint-up(md) {
    padding-top: $menu-sticky-height * 1.16;
  }
}

.bm-cross-button {
  display: none;
}