.loader-page {
  animation-delay: 100ms;
  animation-duration: 300ms;
  position: fixed;
  z-index: 20000;
  left: 0;
  right: 0;
  top: 0;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(189,190,196,1) 100%);

  .logo {
    &-container {
      max-width: 80%;
      .logo {
        max-width: 100%;
      }

      @include media-breakpoint-up(md) {
        max-width: 350px;
      }
    }
  }
  .svg-inline--fa {
    font-size: 3rem;
    color: $primary;
  }

  .title {
    margin-top: 1rem;
  }

  &.theme-white {
    color: $white;
    .svg-inline--fa {
      color: $white;
    }
  }
}

.loader-part {
  text-align: center;
  padding: $spacer * 2 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    &-container {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      .logo {
        max-width: 100%;
      }

      @include media-breakpoint-up(md) {
        max-width: 350px;
      }
    }
  }

  .svg-inline--fa {
    font-size: 3rem;
  }

  .title {
    margin-top: 1rem;
  }
}

.loader-page-inner {
  padding: $spacer * 2 0;
  @include make-container();
  @include make-container-max-widths();

  .loader-page-inner-title {
    margin-bottom: $spacer;
  }

  .loader-page-image {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
      //background-size: contain;
      z-index: 4;
    }
  }

  .rect-shape,
  .round-shape,
  .text-row,
  .rect-shape,
  .round-shape,
  .text-row {
    background-color: gray;
    animation: react-placeholder-pulse 1.5s infinite;
  }

  .loader-page-image {
    .rect-shape {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    margin-bottom: $spacer / 2;
  }
}