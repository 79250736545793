.store-quantity-modal {

  .modal-dialog {
    max-width: 600px;
  }

  .store-quantity-list {
    margin-left: auto;
    margin-right: auto;

    & > div {
      //padding-left: $card-spacer-x;
      //padding-right: $card-spacer-x;
      padding-top: $card-spacer-y / 2;
      padding-bottom: $card-spacer-y / 2;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: 0 none;
      }

      .store-quantity-selector {
        .quantity-selector {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    //@include media-breakpoint-up(md) {
    //  display: table;
    //  width: 100%;
    //
    //  & > div {
    //    display: table-row;
    //    border: 0 none;
    //    padding-top: 0;
    //    padding-bottom: 0;
    //
    //    & > div {
    //      padding-left: $grid-gutter-width / 2;
    //      padding-right: $grid-gutter-width / 2;
    //      display: table-cell;
    //
    //      padding-top: $card-spacer-y / 2;
    //      padding-bottom: $card-spacer-y / 2;
    //      border-bottom: 1px solid $gray-200;
    //    }
    //  }
    //}
  }

  .alert-out-of-stock {
    p {
      margin-bottom: 0;
    }
  }

  .store-quantity-product {
    font-size: .85em;
    border-bottom: 1px solid $gray-400;
    padding: .5rem 0;
    margin-bottom: $spacer;

    .product-image {
      max-width: 120px;
      margin-left: auto;
      margin-right: auto;
    }

    .product-name {
      margin-bottom: $spacer / 2;
    }

    .product-field {
      display: block;
      font-size: 1em;
      .lbl {
        font-size: .8rem;
        &:after {
          content: " : ";
          margin-right: .1rem;
        }
      }

      .value {
        font-weight: bold;
      }
    }

    .product-price-list {
      display: table;
      & > span {
        display: table-row;

        .lbl,
        .price {
          //font-size: .9rem;
        }

        .lbl {
          text-transform: uppercase;
        }

        & > span {
          display: table-cell;

          &.value {
            text-align: right;
            padding-left: $spacer * 1.5;
          }
        }

        &.product-price-buying {
          .lbl,
          .price {
            //font-size: 1rem;
          }

          .lbl {
            font-weight: bold;
          }
          .price {
            font-weight: bold;
            color: $primary;
          }
        }
      }
    }
  }

  .store-quantity {
    .quantity-selector {

      &.with-packing {
        max-width: 260px;
      }

      &-quantity {
        //max-width: none;
      }

      &-packing {
        .input-group-prepend {
          + input {
            max-width: none;
          }
        }
      }
    }

    &.placeholder {

      .rect-shape,
      .round-shape,
      .text-row,
      .rect-shape,
      .round-shape,
      .text-row {
        background-color: gray;
        animation: react-placeholder-pulse 1.5s infinite;
      }

      .text-row {
        height: 12px!important;
      }

      .product-name-placeholder {
        margin-bottom: $spacer / 2;
        .text-row {
          width: 90px!important;
        }
      }

      .product-quantity-placeholder {
        height: 35px!important;
        max-width: 140px;
      }

      .product-reference-placeholder {
        .text-row {
          width: 60px!important;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .store-quantity-product {
      .product-image {
        width: 120px;
        max-width: none;
      }
    }

    .product-price-list {
      margin-top: $spacer / 2;
    }
  }
}