.customer-import-detail-section {
  .customer-store {

    .switch-store-action {
      margin-top: 1rem;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;

      .switch-store-action {
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
      }
    }
  }
}