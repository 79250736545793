.mobile-filter-opened,
.search-opened,
.app-loading {
  overflow: hidden;
  #u10-app {
    overflow: hidden;
    position: relative;
  }
}

.animated {
  animation-duration: $animate-css-animation-duration;
}


.modal + .modal-backdrop + .modal + .modal-backdrop {
  z-index: 1051;
}
.modal + .modal-backdrop + .modal {
  z-index: 1052;
  background-color: rgba(0,0,0,0.40);
}

#u10-app,
html {
  height: 100%;
}

body {
  min-height: 100%;
}

.container-boxed {
  max-width: map_get($container-max-widths, xxl);
  margin: $spacer auto;
}

.btn-outline-primary {
  text-decoration: none!important;
  @include hover-focus-active() {
    color: $white;
  }
}

.btn-back {
  color: $text-color;
}