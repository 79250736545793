.top-head-bar {
  transition: box-shadow ease-in $animate-css-animation-duration;
  animation-duration: $animate-css-animation-duration;
  animation-fill-mode: both;
  box-shadow: 0 0 0 0 transparent;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;

  @include media-breakpoint-down(lg) {
    box-shadow: $box-shadow-lg;
  }

  @include media-breakpoint-up(lg) {
    animation-name: fadeIn;

    &.sticky {
      animation-name: slideInDown;
      box-shadow: $box-shadow-lg;
      #desktop-menu {
        position: static!important;

        & > .navbar-nav {
          height: 100%;

          > .nav-item {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;

            & > .nav-link {
              padding-bottom: 0;

              & > .badge {
                top: 6px;
                right: 0;
              }
            }

            .megamenu-nav-item {
              & > .nav-link {
                padding-bottom: 0;
              }
            }

            &.active {
              border-bottom: 0 none;
            }
          }
        }
      }

      .head-bar-top {
        display: none;
      }

      .head-bar-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0;

        @include make-container();
        @include make-container-max-widths();

        position: static;

        & > .logo-container {
          display: flex;
          max-width: 100px;
          align-items: center;
          padding: $spacer / 2 0;
          margin-right: 2rem;

          .logo {
            max-width: 100%;
          }
        }

        & > .icon-nav {
          display: flex;

          .nav-item {
            .lbl {
              display: none;
            }

            &.cart {
              text-decoration: none;
              .badge {
                right: -5px;
                top: -5px;
              }
            }
          }
        }
      }

      .main-menu {
        background-color: transparent;
      }
    }
  }

  .hamburger {
    display: block;
  }

  &.sticky {
    .logo-container {
      flex: 0 0 100px;
    }
  }

  @include media-breakpoint-down(md) {
    .product-search {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .logo {
      width: 90px;
    }
  }

  &:not(.sticky) {
    @include media-breakpoint-up(md) {
      .logo {
        width: 160px;
      }
    }

    @include media-breakpoint-up(lg) {
      .logo {
        width: 170px;
      }
    }

    @include media-breakpoint-up(lg) {
      .hamburger {
        display: none;

        .logo {
          width: 170px;
        }
      }
    }
  }


  .head-bar {
    background: $gray-light;
  }

  .head-bar-top {
    padding: $spacer * 2 0 0;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);


    .logo-container {
      text-align: center;
      margin-bottom: $spacer / 2;
    }

    @include media-breakpoint-down(md) {
      padding: $spacer / 2 0;

      & > .container {
        padding-left: $grid-gutter-width / 4;
        padding-right: $grid-gutter-width / 4;

        & > .row {
          margin-left: 0;
          margin-right: 0;
          align-items: center;

          & > [class*="col-"],
          & > .col {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .head-bar-top-first {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .logo-container {
        margin-bottom: 0;
      }
    }
  }

  .head-bar-content {
    & > .icon-nav,
    & > .logo-container {
      display: none;
    }
  }

  .icon-nav {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .nav-item {
      margin: 0 $spacer / 3;
      @include media-breakpoint-up(md) {
        margin: 0 $spacer / 4;
      }

      color: $text-color;
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        font-size: 1rem;
      }

      .lbl {
        display: none;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }

      &.listed-only {
        @media screen and (max-width: 375px) {
          margin: 0;
        }

        .custom-control-label {
          outline: none;
          box-shadow: none;
        }

        .lbl {
          margin-top: .2rem;

          .app-icon {
            font-size: .9rem;
          }

          label {
            margin-bottom: 0;
          }
        }

        .listed-only-check {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          flex-wrap: nowrap;

          .app-icon {
            margin-right: $spacer / 2;
          }
        }
      }

      &.favorite {
        @media screen and (max-width: 374px) {
          display: none;
        }
      }

      &.dropdown {
        #account-dropdown {
          &-mobile, &-desktop {
            padding: 0;
            color: $text-color;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;

            i {
              font-size: 1rem;
            }

            & > .lbl {
              .business-name,
              .selected-store {
                display: block;
                line-height: 1;
              }

              .selected-store {
                display: none;
                font-size: .8em;
                color: lighten($text-color, 20%);
              }
            }

            &:after {
              display: none;
            }
          }
        }
      }

      &.cart {
        position: relative;
        text-decoration: none;

        .badge {
          font-size: .5em;
          position: absolute;
          right: -3px;
          top: -3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:not(.sticky) {
      .icon-nav {
        align-items: flex-start;
      }
    }
  }
}

