.btn-scroll-to-top {
  border-radius: 50%;
  transition: bottom linear $animate-css-animation-duration, background-color linear $animate-css-animation-duration;
  animation-duration: $animate-css-animation-duration;
  animation-fill-mode: both;
  position: fixed;
  right: 10px;
  bottom: 45px;
  margin-bottom: env(safe-area-inset-bottom);
  z-index: 1029;
  width: 40px;
  height: 40px;
  outline: none;
  box-shadow: none;
  &.hide {
    bottom: -80px!important;
  }
}
