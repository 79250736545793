$order-return-preview-gutter: $grid-gutter-width / 4;
$order-return-preview-max-width: 90px;

.order-return-modal {
  .modal-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;

    .modal-title {
      margin-bottom: 0;
      margin-top: $spacer;
      flex: 1 1 auto;
    }

    .close {
      color: $text-color;
      opacity: 1;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 2rem;
      font-weight: 100;
      z-index: 100;
    }

    .modal-title, p {
      text-align: center;
    }

    p {
      margin-top: $spacer;
    }
  }


  .order-return-list {
    [role="table"] {
      display : flex;
      flex-direction : column;
      border: 1px solid #f2f2f2;
      font-size: 1rem;
      margin: 0.5rem;
      line-height: 1.5;
      justify-content : space-between;
    }

    [role="rowgroup"] {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      max-width: 100%;

      &:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
      }

      [role="columnheader"],
      [role="cell"] {
        display : flex;
        flex: 1 1 auto;
        width: 100%;

        word-break: break-word;
        padding: $table-cell-padding-sm;

        &.grow-1 {
          flex-grow: 0.5;
        }

        &.grow-2 {
          flex-grow: 2.1;
        }

        &.grow-3 {
          flex-grow: 3;
        }

        &.grow-4 {
          flex-grow: 4;
        }

        &.grow-5 {
          flex-grow: 5;
        }

        &.grow-6 {
          flex-grow: 6;
        }

        &.grow-7 {
          flex-grow: 7;
        }
      }

      &.header {
        text-transform: uppercase;
        font-size: .9rem;

        [role="columnheader"],
        [role="cell"] {
          align-items: center;
          justify-content: center;
        }
      }
    }

    .footer {
      padding: $spacer / 2;
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      [role="rowgroup"] {
        [role="columnheader"],
        [role="cell"] {
          padding: $table-cell-padding;
        }
      }
    }

    @include media-breakpoint-between(md, lg) {
      [role="rowgroup"] {
        &.header {
          display: none;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      [role="table"] {
        max-height: 500px;
        overflow: scroll;
      }

      [role="rowgroup"] {
        //flex-wrap: nowrap;
        padding-bottom: 0;

        [role="columnheader"],
        [role="cell"] {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;

          .form-group {
            margin-bottom: 0;
          }

          &:last-of-type {
            justify-content: flex-end!important;
          }
        }

        &.header {
          [role="columnheader"] {
            justify-content: flex-start;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .product-info {
    .product-name {
      font-weight: bold;
      margin-bottom: $spacer / 2;
    }

    .product-field {
      justify-content: flex-start;
      font-size: .9em;

      &.ordered-quantity {
        .value {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .separator {
            padding: 0 $spacer / 4;
            display: inline-block;
          }

          .value {
            margin-left: $spacer / 8;
          }
        }
      }
    }
  }

  .flex-table {
    border-bottom: $table-border-width solid $table-border-color;
    padding-bottom: $spacer;
    margin-bottom: 0!important;

    &.drag {
      .dropzone {
        opacity: 1;
        z-index: 14;
      }
    }
  }

  .flex-row {
    &.quantity {
      font-size: .9em;
      .quantity-inner {
        display: flex;
        width: 100%;

        & > * {
          margin-left: $grid-gutter-width / 4;
          margin-right: $grid-gutter-width / 4;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        label {
          font-weight: bold;
        }
      }
    }

    &.comment {
      .form-group {
        width: 100%;
      }
    }

    &.picture {
      .preview {
        max-width: 140px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(xl) {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .previews {
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 0;

    .flex-row {
      padding-top: 0!important;
    }

    .preview-section {
      display: flex;
      width: 100%;

      align-items: center;
      justify-content: flex-start;

      & > * {
        flex-basis: 0;
        flex-grow: 1;
        min-width: $order-return-preview-max-width / 1.2;
        max-width: $order-return-preview-max-width / 1.2;

        &:last-child {
          padding-right: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }
  }

  .preview {
    position: relative;
    width: 100%;
    padding-right: $order-return-preview-gutter;
    padding-left: $order-return-preview-gutter;
    margin-bottom: $order-return-preview-gutter;
    flex-basis: 0;
    flex-grow: 1;
    max-width: $order-return-preview-max-width / 1.5;

    .progress {
      position: absolute;
      left:  $order-return-preview-gutter + 1;
      right:  $order-return-preview-gutter + 1;
      bottom: 0;
      z-index: 11;
      height: $progress-height / 2;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .close {
      color: $white;
      opacity: 1;
      position: absolute;
      right: $order-return-preview-gutter * 2;
      top: 0;
      font-size: 1.5rem;
      font-weight: 100;
      z-index: 100;
    }

    .error-icon {
      color: $white;
      opacity: 1;
      position: absolute;
      left: $order-return-preview-gutter* 2;
      top: $order-return-preview-gutter;
      font-size: .9rem;
      font-weight: 100;
      z-index: 100;
    }

    .preview-inner {
      border-radius: 10px;
      border: 1px solid $gray-400;
      img {
        object-fit: cover;
      }
    }

    &.add {
      max-width: 81px!important;
      margin-right: -$order-return-preview-gutter;

      .preview-inner {
        border-color: $black;
        cursor: pointer;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          .app-icon {
            font-size: 1.6rem;
            line-height: 1;
          }

          .lbl {
            font-size: .7rem;
          }
        }

        @include hover-focus-active() {
          border-color: lighten($text-color, 20%);

          .app-icon,
          .lbl {
            color: lighten($text-color, 20%);
          }
        }
      }
    }
  }

  .dropzone {
    opacity: 0;
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: rgba($white, .8);
    //border-color: $gray-400;
    //color: $gray-600;
    border-color: $primary;
    color: $primary;
    outline: none;
    transition: border .24s ease-in-out, opacity .24s ease-in;

    .dropzone-message {
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }

    p {
      margin-bottom: 0;
    }

    &.active,
    &:hover {
      border-color: $primary;
      color: $primary;

      .btn {
        border-color: $primary;
        color: $primary;

        @include hover-focus-active() {
          background-color: $white;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .product-info {
      display: flex;

      & > * {
        margin-left: $grid-gutter-width / 2;
        margin-right: $grid-gutter-width / 2;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      & > .product-card {
        width: 180px;
      }

      & > .info-card {
        flex: 1 1 auto;
      }
    }

    .flex-table:not(.header) {
      &.picture,
      &.comment {
        width: 50%;
      }

      &.picture {
        .preview {
          max-width: 86px;
          margin-left: $spacer / 2;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-info {
      & > .product-card {
        min-width: 70px;
        max-width: 70px;
      }

      .product-name {
        font-size: .9rem;
        line-height: 1.1;
        margin-bottom: 0;
      }

      .complaint-quantities,
      .product-quantities,
      .product-attributes {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        & > *:last-child {
          &:before {
            content: " | ";
            display: inline-flex;
            margin: 0 $spacer / 2;
          }
        }
      }

      .complaint-quantities {
        & > *:last-child {
          &:before {
            content: "";
          }
        }
      }

      .product-quantities {
        & > *:last-child {
          &:before {
            display: none;
          }
        }

        .product-field {
          .lbl {
            font-style: italic;
          }

          & + .product-field {
            &:before {
              content: " - ";
              display: inline-block;
              margin-left: $spacer / 2;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1440px) {
    [role="rowgroup"] {
      [role="columnheader"],
      [role="cell"] {
        &.flex-row {
          &.picture,
          &.comment {
            width: 50%;
          }

          &.picture {
            justify-content: flex-start;

            .preview.add {
              margin-left: 0;
              max-width: 90px!important;
            }
          }
        }
      }
    }

    .previews {
      .preview-section {
        justify-content: flex-start;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    [role="rowgroup"] {
      [role="columnheader"],
      [role="cell"] {
        &.flex-row {
          &.product {
            min-width: 590px;
            max-width: 590px;
          }

          &.quantity {
            min-width: 100px;
            max-width: 200px;
          }

          &.picture {
            min-width: 100px;
            max-width: 102px;
          }
        }
      }
    }
  }
}