//import des configurations de l'application
@import 'fonts/flaticon-helpers';
@import 'fonts/flaticon-variables';
@import "config/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "config/helpers";
@import "config/safe-area";
@import "~hamburgers/_sass/hamburgers/hamburgers";

@import "fonts";

@import "~hamburgers/_sass/hamburgers/base";

//import version non compilée de bootstrap pour exploiter les variables, utilitaires et mixins
//IMPORTANT : à importer APRES la définition des bootstrap-variables de notre application
@import '~bootstrap/scss/bootstrap.scss';

@import "config/vendors";

@import '~animate.css';
@import '~react-placeholder/lib/reactPlaceholder';
@import '~react-image-lightbox/style.css';
@import '~react-perfect-scrollbar/dist/css/styles.min.css';
@import '~react-toastify/scss/main';
@import '~react-datepicker/dist/react-datepicker.css';
@import "~@grubersjoe/slide-menu/src/styles/slide-menu";

// flatpicker
@import '~flatpickr/dist/themes/airbnb.css';

// swiper
@import '~swiper/swiper';
@import '~swiper/modules/a11y/a11y';
@import '~swiper/modules/lazy/lazy';
@import '~swiper/modules/navigation/navigation';
@import '~swiper/modules/pagination/pagination';
@import '~swiper/modules/thumbs/thumbs';
@import '~swiper/modules/zoom/zoom';

@import '~react-dropdown-tree-select/dist/styles.css';

// nano scrollbar
@import '~react-nano-scrollbar/dist/index.css';

@import "config/global";

//components
@import "components/Typography";
@import "components/Grid";
@import "components/Form";
@import "components/Callout";
@import "components/Loader";
@import "components/CatalogPicture";
@import "components/Button";
@import "components/MainMenu";
@import "components/MobileMenu";
@import "components/Heading";
@import "components/Carousel";
@import "components/Universe";
@import "components/Product";
@import "components/Breadcrumb";
@import "components/Pagination";
@import "components/Sidebar";
@import "components/ReactModal";
@import "components/Cart";
@import "components/CartResume";
@import "src/assets/scss/components/MemberMenu";
@import "components/Customer";
@import "components/Orders";
@import "components/FamilyTree";
@import "components/Widgets";
@import "components/ShippingLocation";
@import "components/SlideMenu";
@import "components/ScrollToTop";
@import "components/OrderStepper";
@import "components/SweetAlert";
@import "components/Export";
@import "components/Import";
@import "components/CustomerSalesman";
@import "components/ContainerVolume";
@import "components/ProductsPickerBanner";
@import "components/Table";
@import "components/Modal";
@import "components/NetworkReachability";
@import "components/Catalog";

//containers
@import "containers/PageWrap";
@import "containers/LoginWindow";
@import "containers/Header";
@import "containers/MobileHeader";
@import "containers/Footer";
@import "containers/TopBar";
@import "containers/Home";
@import "containers/Products";
@import "containers/Product";
@import "containers/ErrorPage";
@import "containers/CartsBanner";
@import "containers/Carts";
@import "containers/Checkout";
@import "containers/Confirmation";
@import "containers/ProductSearch";
@import "containers/CrossSelling";
@import "containers/LangSwitcher";
@import "src/assets/scss/containers/MemberArea";
@import "containers/CustomerOrders";
@import "containers/OrderDetail";
@import "containers/CustomerLogin";
@import "containers/StoreQuantity";
@import "containers/MobileSideMenu";
@import "containers/MassAddToCart";
@import "containers/Import";
@import "containers/CmsPage";
@import "containers/CustomerImport";
@import "containers/MemberTopBar";
@import "containers/CustomerSalesmen";
@import "containers/AssistanceModal";
@import "src/assets/scss/containers/MemberStatistics";
@import "containers/SalesmanArea";
@import "containers/FavoriteDepartment";
@import "containers/OrderAnomaly";
@import "containers/PictureExport";
@import "containers/CustomerProductExports";
@import "containers/CustomerCarts";
@import "containers/Planograms";
@import "containers/Planogram";

//components
//@import "components/*";
