$font-Flaticon-family: "Flaticon";

$flaticon-map: (
  "information": "\f101",
  "time-left": "\f102",
  "contact": "\f103",
  "settings": "\f104",
  "calendar": "\f105",
  "heart-o": "\f106",
  "list": "\f107",
  "home": "\f108",
  "handshake": "\f109",
  "delivery-truck": "\f10a",
  "guarantee": "\f10b",
  "phone-o": "\f10c",
  "discount": "\f10d",
  "emergency": "\f10e",
  "box": "\f10f",
  "delivery-truck-2": "\f110",
  "check": "\f111",
  "trash-alt": "\f112",
  "minus": "\f113",
  "plus": "\f114",
  "chevron-right": "\f115",
  "arrow-right": "\f116",
  "shopping-cart": "\f117",
  "user": "\f118",
  "heart-o-2": "\f119",
  "search": "\f11a",
  "chevron-down": "\f11b",
  "chevron-up": "\f11c",
  "chevron-left": "\f11d",
  "arrow-left": "\f11e",
  "heart": "\f11f",
  "loader-reload": "\f120",
  "loader-loading-stripes": "\f121",
  "menu": "\f122",
  "warning": "\f123",
  "danger": "\f124",
  "arrow-right-angle": "\f125",
  "warehouse-2": "\f126",
  "warehouse": "\f127",
  "logout": "\f128",
  "receipt": "\f129",
  "pencil": "\f12a",
  "delivery-truck-1": "\f12b",
  "delivery": "\f12c",
  "export": "\f12d",
  "import": "\f12e",
  "shop": "\f12f",
  "shopping-store": "\f130",
  "check-1": "\f131",
  "barcode": "\f132",
  "information-1": "\f133",
  "bell": "\f134",
  "info": "\f135",
  "question": "\f136",
  "phone": "\f137",
  "phone-small": "\f138",
  "closed-envelope": "\f139",
  "calendar-o": "\f13a",
  "camera": "\f13b",
  "upload": "\f13c",
  "link": "\f13d",
  "freight-container": "\f13e",
  "lift-container-2": "\f13f",
  "lift-container-1": "\f140",
  "container-crane": "\f141",
  "container": "\f142",
  "logout-bold": "\f143",
  "logout-1": "\f144",
  "id-card": "\f145",
  "statistics": "\f146",
  "download": "\f147",
  "tree-structure": "\f148",
  "budget": "\f149",
  "add-to-cart": "\f14a",
  "unavailable-box": "\f14b",
  "trolley": "\f14c",
  "panel-open": "\f14d",
  "panel-close": "\f14e",
  "available-box": "\f14f",
);