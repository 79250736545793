.salesman {
  & > .row {
    align-items: center;
    justify-content: center;
  }

  .preview {
    display: block;
    width: 80px;
    height: 80px;
    background-color: $gray-200;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    overflow: hidden;
    //box-shadow: 0 0 4px 3px #d8d6d6;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .full-name {
    font-weight: 500;
    margin-bottom: $spacer / 2;
  }

  .email, .phone, .download {
    margin-bottom: $spacer / 6;

    button,
    a {
      padding: 0;
      color: $text-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      text-decoration: none;

      .app-icon {
        color: $primary;
        margin-right: $spacer / 2;
      }

      @include hover-focus-active() {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}