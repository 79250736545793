.top-network-reachability {
  transition: top ease-in $animate-css-animation-duration / 2;
  position: fixed;
  top: -26px;
  left: 0;
  right: 0;
  z-index: 9999;
  height: 24px;
  background: darken($danger, 20%);
  color: $white;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  span,
  svg,
  i {
    display: inline-block;
  }

  .lbl {
    margin-left: .5rem;
  }

  &.online {
    top: -26px;
  }

  &.offline {
    top: 0;
  }
}
