.export-modal {
  .modal-header {
    background-color: $gray-dark;
    color: $white;
    padding-top: $card-spacer-y;
    padding-bottom: $card-spacer-y;

    .modal-title {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    button {
      color: $white;
      opacity: 1;
    }

    .app-icon {
      margin-right: $spacer;
    }
  }

  .modal-content {
    border-color: $gray-dark;

    .modal-body {
      @include media-breakpoint-up(md) {
        padding-top: $modal-inner-padding * 2;
        padding-bottom: $modal-inner-padding * 2;
      }
    }
  }

  .quantity-selector {
    margin-left: 0;
  }

  .invalid-feedback {
    display: block;
  }

  .alert {
    p {
      margin-bottom: 0;
    }
  }

  .export-success-section,
  .export-loading-section {
    text-align: center;

    .svg-inline--fa {
      font-size: 3rem;
      color: $primary;
    }

    .title {
      margin-top: 1rem;
    }
  }

  .export-success-section {
    .svg-inline--fa {
      color: $success;
    }
  }
}

.import-product-table {
  .actions {
    text-align: center;
    padding: 0.55rem;

    .btn {
      color: lighten($text-color, 20%);
      font-size: 1.2rem;
      padding: 0;

      + .btn {
        margin-left: $spacer * 0.8;
      }

      @include hover-focus-active() {
        color: $text-color;
      }
    }
  }
}