.universe-carousel {
  overflow: hidden;
  .swiper {
    overflow: visible;
  }

  .carousel-app-item {
    display: block;
    opacity: 0.45;
    transition: opacity 500ms ease-in-out;
    position: relative;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    a {
      display: block;
    }

    .btn {
      position: absolute;
      bottom: 2rem;
      z-index: 6;
      left: 0;
      right: 0;
      margin: 0 2rem;
      opacity: 0;
    }

    .btn {
      @include hover-focus-active() {
        background: color-yiq($dark);
        border-color: color-yiq($dark);
        color: $dark;
      }
    }
  }

  .swiper-slide {
    width: 50%;
    pointer-events: none;

    &-active {
      .carousel-app-item {
        opacity: 1;
        pointer-events: auto;
        @include hover-focus-active() {
          .btn {
            animation-name: fadeInDown;
          }
        }
      }

      + div {
        .carousel-app-item {
          opacity: 1;
          pointer-events: auto;
          @include hover-focus-active() {
            .btn {
              animation-name: fadeInDown;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      width: 33.33%;

      &-active {
        @include hover-focus-active() {
          .btn {
            animation-name: fadeInDown;
          }
        }

        + div,
        + div + div {
          .carousel-app-item {
            opacity: 1;
            pointer-events: auto;
            @include hover-focus-active() {
              .btn {
                animation-name: fadeInDown;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      width: 25%;

      &-active {
        @include hover-focus-active() {
          .btn {
            animation-name: fadeInDown;
          }
        }

        + div,
        + div + div,
        + div + div + div {
          .carousel-app-item {
            pointer-events: auto;
            opacity: 1;
            @include hover-focus-active() {
              .btn {
                animation-name: fadeInDown;
              }
            }
          }
        }
      }
    }
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}