.catalog-image {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("./../../img/product-list-placeholder.png") no-repeat center center $white;
    background-size: 80%;
    z-index: 4;
  }

  img {
    width: 100%;
    transition: $animate-css-animation-duration filter linear;
    object-fit: cover;

    &.placeholder {
      filter: blur(1rem);
    }

    &.picture {
      filter: blur(0);
    }
  }

  &-list {
    .catalog-image-inner {
      img {
        transition: top ease-in $animate-css-animation-duration;

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 1;

        + img {
          top: 100%;
        }
      }
    }
  }
} // .catalog-image
