.member-statistics-section {
  background: $white;

  .card {
    box-shadow: none;
    .card-header {
      padding-left: $card-spacer-x / 1.5;
      padding-right: $card-spacer-x / 1.5;
    }

    .card-body {
      padding: $card-spacer-x / 1.5;
    }
  }

  .statistics-card {
    margin-bottom: $spacer * 1.5;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;

      .statistics-filters-resume {
        display: none;
        font-size: 12px;
        margin-top: $spacer / 2;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-left: auto;
        }

        .departments {

        }
      }
    }

    &.statistics-data-card {
      .card-header {
        padding-top: 0;

        .h5 {
          color: $gray-600;
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 0;
    }
  }

  .statistics-filters {

    .input-group-text,
    .input-group,
    .form-control {
      border-radius: 0;
      border-color: $primary;
    }

    .form-inline {

      & > * {
        margin-top: $input-padding-y;
      }

      @include media-breakpoint-down(md) {
        width: 100%;

        & > .form-group {
          flex: 1 1 auto;

          .react-datepicker-wrapper {
            flex: 1 1 auto;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        flex-flow: row nowrap;
      }

      @include media-breakpoint-up(xxl) {
        align-items: flex-start;
      }

      & > .form-group {
        & > label, &.input-group {
          margin: .5rem;

          @include media-breakpoint-up(lg) {
            margin: 0 1rem;
          }
          @include media-breakpoint-up(xl) {
            margin: 0 1.5rem;
          }
        }

        & > label {
          &:first-child {
            @include media-breakpoint-up(xxl) {
              margin-left: 0;
            }
          }
        }
      }


      .input-group {
        .form-control {
          border-right-width: 0;
        }

        .input-group-text {
          border-left-width: 0;
          background-color: transparent;
        }
      }

      .customer-stats-family-tree-field {
        flex: 1 1 auto;

        .form-label {
          padding-left: $input-padding-x;
          padding-bottom: $input-padding-y;
        }

        .bs-tree-select {
          min-width: 200px;

          label {
            display: inline-flex;
          }

          .node {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .classification-item-shipping_location {
            & > label {
              pointer-events: none;
              & > .checkbox-item {
                display: none;
              }
            }
          }

          .dropdown {
            width: 100%;

            .dropdown-content {
              left: 0;
              right: 0;
              z-index: 10;
            }
          }

          .dropdown-trigger {
            text-decoration: none;
            border-color: $primary;
            color: $text-color;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            &:after {
              vertical-align: -2px;
              margin-left: auto;
              font-family: $font-Flaticon-family;
              content: map-get($flaticon-map, "chevron-down");
            }

            &.top {
              &:after {
                content: map-get($flaticon-map, "chevron-up");
              }
            }

            .tag-item {
              .tag {
                font-weight: 500;
                font-size: .9rem;
              }
            }
          }

          .search {
            border-bottom: 0 none;
          }

          .dropdown-trigger > span:after {
            font-size: 12px;
            color: #555;
          }

          .toggle {
            color: black;
            font: normal normal normal 12px/1 FontAwesome;

            &:after {
              vertical-align: -2px;
              font-family: $font-Flaticon-family;
              content: map-get($flaticon-map, "minus");
            }

            &.collapsed {
              &:after {
                content: map-get($flaticon-map, "plus");
              }
            }
          }

          .root {
            padding: 0;
            margin: 0;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-top: $spacer / 2;
          width: 100%;
        }

        @include media-breakpoint-up(md) {

          margin-left: .5rem;

          .bs-tree-select {
            min-width: 300px;
          }

          .form-label {
            height: $input-height;
            padding: $input-padding-y $input-padding-x;
            padding-left: 0;
          }
        }

        @include media-breakpoint-up(lg) {
          margin-left: 1rem;
          .form-label {
            padding-left: 0;
            //color: $gray-600;
            &:after {
              content: ": ";
            }
          }
        }

        @include media-breakpoint-up(xl) {
          margin-left: 1.5rem;

          & > .form-group {
            align-items: flex-start;
            flex-flow: row nowrap;
          }
        }
      }
    }

    .tags {
      margin-top: .5rem;
      button {
        font-size: 0.713rem;
        margin-bottom: .5rem;

        .app-icon {
          @include size(0.7rem, 0.7rem);
          display: inline-block;
          margin-left: .5rem;
        }

        & + button {
          margin-left: .5rem;
        }
      }
    }

    .custom-control {
      @include media-breakpoint-up(xl) {
        margin-top: $spacer / 2;
        margin-left: 1.5rem;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: auto;
        margin-top: 0;
      }
    }
  }

  .statistics-top-products-card {

    &.statistics-context-salesman {
      .product-card {
        cursor: default;
      }

      .table {
        .details {
          cursor: default;
        }
      }
    }

    .top-products-list-content {
      .table-app-section {
        .table-app-filter-row {
          &.table-app-filter-row-top {
            display: none;
          }
        }
      }

      .table {
        tbody {
          .product-packing {
            font-size: .95rem;
            margin-top: $spacer / 2;
            text-align: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            .app-icon {
              line-height: 1;
              font-size: 1em;
              margin-left: .4rem;
            }
          }

          .details {
            cursor: pointer;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .product-name {
              font-size: .75rem;
              margin-bottom: 0;
            }

            .product-field {
              color: $gray-500;

              .lbl {
                text-transform: uppercase;
                &:after {
                  content: " : ";
                  font-size: .85em;
                  margin-right: .1rem;
                }
              }

              .value {
                font-weight: normal;
                font-size: .9em;
              }
            }

            .image {
              flex: 0 0 50px;
              max-width: 50px;
              padding: 0;
              margin-right: $spacer;
            }

            .product-heading {
              text-align: left;
              flex: 1 1 auto;
            }

            @include media-breakpoint-up(md) {
              flex-direction: row;
            }
          }

          @include media-breakpoint-up(md) {
            .quantity {
              min-width: 130px;
            }
          }
          @include media-breakpoint-up(lg) {
            .quantity {
              min-width: 140px;
            }
          }
        }
      }
    }

    .table {
      thead {
        background: $gray-100;
        th {
          font-weight: normal;
          padding: .2rem .3rem;
        }
      }
    }

    //.cart-table {
    //  text-align: center;
    //}
    //
    //.cell.description {
    //  display: flex;
    //  flex-direction: row;
    //  justify-content: flex-start;
    //
    //  .image {
    //    flex: 0 0 50px;
    //    max-width: 50px;
    //    padding: 0;
    //    margin-right: $spacer;
    //  }
    //
    //  .product-heading {
    //    text-align: left;
    //    flex: 1 1 auto;
    //  }
    //}
    //
    //.cart-table.single-cart-table > .throw {
    //
    //  > div.cell.description {
    //    font-size: 12px;
    //
    //    .product-field {
    //      color: $gray-500;
    //      .lbl {
    //        text-transform: uppercase;
    //      }
    //
    //      .value {
    //        font-weight: normal;
    //      }
    //    }
    //  }
    //
    //  &.cart-item {
    //    padding-bottom:  $spacer / 3;
    //    padding-top:  $spacer / 3;
    //
    //    .cell {
    //      padding-top: $spacer / 4;
    //      padding-bottom: $spacer / 4;
    //    }
    //
    //    > div.cell.quantity,
    //    > div.cell.total-price {
    //      font-size: 1rem;
    //    }
    //  }
    //
    //  > div.cell.quantity {
    //    text-align: center;
    //  }
    //
    //  > div.cell.total-price {
    //    text-align: right;
    //
    //    .price {
    //      text-align: right;
    //    }
    //  }
    //}
    //
    //@include media-breakpoint-up(xxl) {
    //  .cart-table.single-cart-table > .throw {
    //    justify-content: flex-start;
    //  }
    //
    //  .cart-table.single-cart-table > .throw > div.cell.description {
    //    flex: 0 0 330px;
    //  }
    //  .cart-table.single-cart-table > .throw > div.cell.quantity {
    //    flex: 1 1 auto;
    //    //max-width: none;
    //  }
    //  .cart-table.single-cart-table > .throw > div.cell.total-price {
    //    flex: 1 1 auto;
    //    max-width: none;
    //  }
    //}
  }
}