.cart-list-banner {
  transition: bottom linear $animate-css-animation-duration;
  z-index: 1030!important;
  padding: $spacer / 4;
  padding-top: 0;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: $gray-300;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -200px;

  & > .row {
    & > [class*="col-"],
    & > .col {
      display: flex;
    }
  }

  @include media-breakpoint-down(xl) {
    &.even {
      & > .row {
        & > div {
          flex: 1 1 50%;
          &:last-child {
            flex: 1 1 100%;
          }
        }
      }
    }
  }

  .cart-banner {
    animation-duration: $animate-css-animation-duration;
    animation-fill-mode: both;
    transition: background-color ease-in $animate-css-animation-duration / 2;
    cursor: pointer;
    flex: 1;
    padding: $spacer / 2 $spacer;
    background-color: $danger;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin: 0 .1rem;
    margin-top: $spacer / 4;

    @include hover-focus-active() {
      background-color: lighten($danger, 10%);
    }

    .price {
      margin-left: .4rem;
    }

    .app-icon {
      font-size: 1rem;
    }

    .cart-banner-total {
      line-height: 1;

      .cart-remaining-amount {
        margin-left: .4rem;
      }
    }

    .cart-remaining-amount {
      font-size: .9em;
      position: relative;
      top: 1px;
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }

      .price {
        margin-left: .4rem;
      }
    }

    .cart-banner-minimum-amount {
      display: none;

      .lbl {
        & > span {
          &:not(.abbr) {
            display: none;
          }
        }
      }
    }

    .cart-banner-minimum-amount,
    .cart-remaining-amount {
      .price {
        margin-left: .1rem;
      }
    }

    .cart-banner-minimum-amount,
    .cart-banner-total,
    .product-shipping-location {
      flex-direction: row;
      align-items: center;

      .app-icon {
        margin-right: .4rem;
      }

      .value {
        text-transform: uppercase;
      }
    }

    .cart-banner-total,
    .product-shipping-location {
      display: flex;
    }

    .cart-banner-resume {
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .cart-remaining-amount {
      transition: width linear $animate-css-animation-duration;
      width: auto;
    }

    .cart-banner-minimum-amount {
      transition: color linear $animate-css-animation-duration;
      //color: lighten($danger, 15%);
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;

      .cart-banner-minimum-amount-icon {
        position: relative;
      }

      .checked {
        transition: opacity linear $animate-css-animation-duration;
        opacity: 0;
        position: absolute;
        left: -8px;
        top: -2px;
        font-size: .8em;
      }

      &:after {
        font-size: 1.1rem;
        margin: 0 .4rem;
      }
    }

    .checked {
      display: none;
    }

    .cart-remaining-amount {
      display: none;
    }

    @include media-breakpoint-up(md) {
      font-size: .8rem;
      flex-direction: row;

      .cart-banner-resume {
        flex-direction: row;
        align-items: center;
        margin-left: auto;
      }

      .cart-banner-minimum-amount {
        display: flex;
        &:after {
          content: " / ";
        }
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: .75rem;
      flex-direction: row;

      .checked {
        display: inline-block;
      }

      .cart-remaining-amount {
        display: inline-block;
      }

      margin-bottom: 0;
      .app-icon {
        font-size: 1.2rem;
      }

      .cart-banner-resume {
        margin-left: auto;
        align-items: center;
      }
    }

    @include media-breakpoint-up(xl) {
      font-size: .8rem;
      flex: 1;
      .cart-banner-minimum-amount {
        .lbl {
          & > span.full {
            display: inline-block;
          }
          & > span.abbr {
            display: none;
          }
        }
      }
    }

    &.remaining-amount-reached {
      background-color: lighten($success, 10%);
      color: $gray-dark;

      .cart-remaining-amount {
        width: 0;
        overflow: hidden;
      }

      .cart-banner-minimum-amount {
        position: relative;
        .checked {
          opacity: 1;
        }
      }

      @include hover-focus-active() {
        background-color: $success;
      }
    }

    &.cart-updated {
      animation-name: pulse;
      background-color: darken($danger, 10%);
      &.remaining-amount-reached {
        background-color: $success;
      }
    }
  }

  &.even {
    .cart-banner-minimum-amount,
    .cart-banner-total,
    .product-shipping-location {
      .value {
        text-transform: none;
      }
    }

    @media screen and (min-width: 1500px) and (max-width: 1700px) {
      .cart-banner-minimum-amount {
        & > .lbl {
          & > .full {
            display: none!important;
          }
          & > .abbr {
            display: inline-block!important;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $spacer / 4;
  }

  @include media-breakpoint-up(lg) {
    .row > div {
      //&:first-child {
      //  padding-right: $grid-gutter-width / 6;
      //}
      //
      //&:last-child {
      //  padding-left: $grid-gutter-width / 6;
      //}
    }
  }

  &.visible {
    bottom: 0;
  }
}