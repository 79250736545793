$enable-responsive-font-sizes: true;

// gray
$gray-100: #f2f2f2;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-550: #898a8c;
$gray-600: #7e7f82;
$gray-700: #495057;
$gray-800: #3b3c41;
$gray-900: #212529;
$gray-950: #131314;

//thème de l'application
$orange:#f15d17;
$torch-red: #E60023;
$gray-dark: $gray-800;
$gray-light:$gray-100;
$green:#5ec835;
$green-dark: #07484e;
$white:#fff;
$red: #ff6060;
$yellow: #ffea00;
$cyan: #64ceca;
$gold: #dc9f11;
$persian-green: #00A695;
$lavender: #CBD4F4;
$pale-turquoise: #CBF0F4;
$carousel-pink: #F7D9E3;
$hawkes-blue: #dfe3f3;

//overrides des variables bootstrap
$body-bg: $gray-100;
$body-color: $gray-dark;
$primary: $torch-red;
$secondary: $gray-800; // $gray-600

$danger: $red;
$warning: $yellow;
$success: $green;
$text-color: #3b3c41;

$animate-css-animation-duration: 300ms;

$box-shadow-color: rgba($gray-950, .11);
$box-shadow-sm:               1px 1px 7px $box-shadow-color;
$box-shadow:                  1px 1px 7px $box-shadow-color;
$box-shadow-lg:               0 4px 13px 4px $box-shadow-color;

$custom-file-text: (
  en: "Browse",
  fr: "Parcourir"
);
/****************************************************************
 * SIZE / GRID
 ****************************************************************/

$sizes: (
        10: 10%,
        20: 20%,
        30: 30%,
        40: 40%,
        60: 60%,
        70: 70%,
        80: 80%,
        90: 90%
);

$grid-gutter-width: 20px;
$grid-columns: 24;
$container-max-widths: (
        sm: 540px,
        md: 750px,
        lg: 960px,
        xl: 1200px,
        xxl: 1500px,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px,
        xxxl: 1800px,
);

/****************************************************************
 * MODAL
 ****************************************************************/
$modal-xl: 1200px;
$modal-xxl: 1400px;
$modal-xxxl: 1600px;

/****************************************************************
 * NAVBAR
 ****************************************************************/
$navbar-nav-link-padding-x: 2rem;

/****************************************************************
 * FONT
 ****************************************************************/

$font-size-base: (14 / 16) * 1rem;
$font-family-sans-serif: 'Lato', sans-serif;
$headings-font-family: 'Lato', cursive;
$headings-variant-font-family: 'Caveat', cursive;

$h1-font-size:            $font-size-base * 2.333;
$h2-font-size:            $font-size-base * 2;
$h3-font-size:            $font-size-base * 1.867;
$h4-font-size:            $font-size-base * 1.75;
$h5-font-size:            $font-size-base * 1.5;
$h6-font-size:            $font-size-base * 1.25;

$h1-hv-font-size:            $font-size-base * 2.667;
$h2-hv-font-size:            $font-size-base * 2.3;
$h3-hv-font-size:            $font-size-base * 2.133;
$h4-hv-font-size:            $font-size-base * 1.75;
$h5-hv-font-size:            $font-size-base * 1.5;
$h6-hv-font-size:            $font-size-base * 1.25;

$body-letter-spacing:     0.84px;

$font-family-font-awesome: "Font Awesome 5 Pro";

/****************************************************************
 * Buttons
 ****************************************************************/

/*
$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;
 */
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-border-radius:           .1rem;
$btn-border-radius-lg:        .1rem;
$btn-border-radius-sm:        .1rem;

/****************************************************************
 * TOP BAR
 ****************************************************************/

$top-bar-bg: $gray-dark;
$top-bar-tint-color: $white;

/****************************************************************
 * HEADER => Customer Dropdown
 ****************************************************************/
$customer-dropdown-nav-width: 270px;
$customer-dropdown-nav-body-height: 270px;

/****************************************************************
 * WIDGET
 ****************************************************************/

$cms-widget-light-bg: $white;
$cms-widget-light-tint-color: $text-color;
$cms-widget-dark-bg: $gray-dark;
$cms-widget-dark-tint-color: $white;
$cms-widget-inner-padding-y: 1rem * 2.5;
/****************************************************************
 * MENU
 ****************************************************************/
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-color: #333;
$hamburger-active-layer-color: #000;
$hamburger-padding-x: 0;
$hamburger-padding-y: 7px;

$menu-desktop-base-height: 190px;
$menu-sticky-height: 82px;
//$menu-sticky-height-md: 92px;
$menu-sticky-height-md: 110px;

$menu-bg: transparent;

$menu-link-color: $text-color;
$menu-link-color-hover: darken($text-color, 30%);

$menu-child-link-color: #212529;
$menu-child-link-color-hover: $primary;
$menu-child-link-font-size: 1rem;

$menu-mobile-link-padding-x: 1.5rem;
$menu-mobile-link-padding-y: .25rem;
$menu-mobile-link-margin-y: .125rem;
$menu-mobile-link-margin-x: 0;

$menu-child-link-z-index: 1000;
$menu-child-link-padding-x: 1.5rem;
$menu-child-link-padding-y: .25rem;
$menu-child-link-margin-y: .125rem;
$menu-child-link-margin-x: 0;
$menu-child-link-min-width: 100%;
$menu-sub-child-link-min-width: 10rem;

$menu-child-bg-color: $white;
$menu-child-border-width: 1px;
$menu-child-border-color: rgba(0,0,0,.15);

$megamenu-bg-color: $menu-child-bg-color;
$megamenu-z-index: $menu-child-link-z-index;

$section-heading-font-size-base: 1.2rem;
$section-heading-font-size-title: 1em;
$section-heading-font-size-icon: 1.2em;
$section-heading-padding-y: 0.2em;
$section-heading-margin-y: 0;
$section-heading-border-width: 6px;
$section-heading-border-color: $gray-light;
$section-heading-margin-x-icon: .4rem;

$mobile-menu-link-color: #333;
$mobile-menu-hover-bg-color: $gray-100;
$mobile-menu-hover-color: $text-color;
$mobile-menu-selected-bg-color: $gray-300;
$mobile-menu-selected-color: $text-color;

/****************************************************************
 * Pagination
 ****************************************************************/
$pagination-color:                  $text-color;
$pagination-bg:                     transparent;
$pagination-border-color:           transparent;

$pagination-hover-color:            $gray-600;
$pagination-hover-bg:               $white;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $gray-600;
$pagination-active-bg:              $white;
$pagination-active-border-color:    $gray-300;

$pagination-disabled-color:         $gray-500;
$pagination-disabled-bg:            transparent;
$pagination-disabled-border-color:  transparent;

/****************************************************************
 * Table
 ****************************************************************/
$table-accent-bg: $gray-100;
$table-hover-bg: darken($gray-100, 1%);
//$table-striped-order: even;

/****************************************************************
 * Breadcrumb
 ****************************************************************/
$breadcrumb-section-margin-bottom:  1rem;
$breadcrumb-font-size:              .8rem;

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              1rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-top:             .5rem;
$breadcrumb-margin-bottom:          .5rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-550;
$breadcrumb-active-color:           $gray-550;
$breadcrumb-divider:                quote(">");

$breadcrumb-border-radius:          0;

// Custom control
$custom-control-indicator-size: 1.4rem;
$custom-control-indicator-checked-color: $text-color;
$custom-control-indicator-checked-border-color: $gray-400;
$custom-control-indicator-checked-bg: $white;
$custom-checkbox-indicator-indeterminate-bg: $white;
$custom-checkbox-indicator-indeterminate-border-color: $gray-400;

$custom-control-indicator-checked-border-color: $text-color;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxN3B0IiB2aWV3Qm94PSIwIC00NiA0MTcuODEzMzMgNDE3IiB3aWR0aD0iNDE3cHQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE1OS45ODgyODEgMzE4LjU4MjAzMWMtMy45ODgyODEgNC4wMTE3MTktOS40Mjk2ODcgNi4yNS0xNS4wODIwMzEgNi4yNXMtMTEuMDkzNzUtMi4yMzgyODEtMTUuMDgyMDMxLTYuMjVsLTEyMC40NDkyMTktMTIwLjQ2ODc1Yy0xMi41LTEyLjUtMTIuNS0zMi43Njk1MzEgMC00NS4yNDYwOTNsMTUuMDgyMDMxLTE1LjA4NTkzOGMxMi41MDM5MDctMTIuNSAzMi43NS0xMi41IDQ1LjI1IDBsNzUuMTk5MjE5IDc1LjIwMzEyNSAyMDMuMTk5MjE5LTIwMy4yMDMxMjVjMTIuNTAzOTA2LTEyLjUgMzIuNzY5NTMxLTEyLjUgNDUuMjUgMGwxNS4wODIwMzEgMTUuMDg1OTM4YzEyLjUgMTIuNSAxMi41IDMyLjc2NTYyNCAwIDQ1LjI0NjA5M3ptMCAwIi8+PC9zdmc+");

$embed-responsive-aspect-ratios: (
  (8 7),
);

/****************************************************************
 * Card
 ****************************************************************/
$card-border-radius: 0;

/****************************************************************
 * PRODUCT LIST
 ****************************************************************/
$product-list-filter-max-width: 300px;
