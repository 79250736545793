.customer-login-page {
  .modify-login-form {
    max-width: 400px;
  }

  .helper-card {
    border-left: 1px solid $gray-300;

    & > .row {
      & > div + div {

      }
    }

    .card-header {
      .card-title {
        margin-bottom: 0;
      }
    }
    .card-body {
      padding-top: $card-spacer-y;
    }
  }

  .modify-login-section {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      max-width: 90%;
    }

    .row {
      align-items: center;
    }
  }
}
