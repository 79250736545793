.order-step {
  padding: 0 $spacer * 2;
  padding-bottom: $spacer * 3;

  .order-step-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .order-step-item {
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
      text-align: right;

      &:not(.first) {
        .order-step-item {
          &-inner {
            position: relative;
            &:before {
              content: " ";
              width: 100%;
              height: 4px;
              position: absolute;
              left: 0;
              right: 0;
              top: 50%;
              margin-top: -2px;
              background-color: $gray-400;
            }
          }
        }
      }

      &-content {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
      }

      &-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $gray-400;
        text-align: center;

        .app-icon {
          visibility: hidden;
          font-size: .85em;
        }
      }

      &-label {
        margin-top: $spacer / 2;
        font-size: 0.95em;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        text-align: center;
        width: 80px;
      }

      &.first {
        max-width: none;
        flex: 0 0 auto;
        text-align: left;
      }

      &.last {
        .order-step-item {
          &-inner {
            display: flex;
            justify-content: flex-end;
          }

          &-content {
            align-items: flex-end;
          }
        }
      }

      &.done {
        .order-step-item {
          &-inner {
            &:before {
              background-color: $success;
            }
          }

          &-label {
            color: $success;
          }

          &-icon {
            background-color: $success;
            color: $white;

            .app-icon {
              visibility: visible;
              display: inline-block;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .order-step-item {
        &-label {
        }
      }
    }
  }
}