#footer {
  #footer-top {
    background-color: $white;
  }

  .product-carousel {
    padding-top: $cms-widget-inner-padding-y;
    padding-bottom: $cms-widget-inner-padding-y;

    .carousel-app-content {
      margin-bottom: 0;
    }
  }

  .cms-widget {
    &-reinsurance, &-wysiwyg {
      .cms-widget-inner {
        @include make-container();
        @include make-container-max-widths();
      }
    }

    &-wysiwyg {
      font-size: 0.933em;
      .container-small {
        max-width: 96%;
        margin-left: auto;
        margin-right: auto;
      }

      .h1 {
        font-size: 15px;
      }

      .h2 {
        &.hv {
          font-size: $h1-hv-font-size;
        }
      }

      .h3 {
        font-size: 1rem;
      }

      .h4 {
        font-size: 0.933rem;
        margin-bottom: 0;
      }

      .h1, .h2, .h3, .h4, .h5, .h6 {
        text-transform: uppercase;
        & + hr {
          margin-left: 0;
          width: 50px;
        }
      }

      ul {
        margin-bottom: $spacer;
        li {
          margin-bottom: $spacer / 3;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#page-wrap {
  &.has-app-error {
    #footer {
      display: none;
    }
  }
}

.cms-page-footer {
  .cms-widget {
    &-wysiwyg {
      ul {
        @include list-unstyled();
      }

      &.cms-widget-theme {
        &-dark {
          a {
            color: $cms-widget-dark-tint-color;
          }
        }
        &-light {
          a {
            color: $cms-widget-light-tint-color;
          }
        }
      }
    }
  }
}