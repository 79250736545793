.planogram-list-section .catalog-list-content .catalog-list > [class*="col-"], .planogram-list-section .catalog-list-content .catalog-list > .col {
  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.planogram {
  &-card {
    .planogram-head {
      position: relative;
    }

    .planogram-heading {
      padding: $spacer * 0.5 0;
      & > .planogram-name, & > .planogram-reference {
        margin-bottom: 0;
      }

      &-inner {
        height: calc(26px + #{$spacer/2});
      }
    }

    .planogram-content {
      background: #e9e9e9;
      padding: $spacer / 2 $spacer / 2 $spacer;

      .row {
        & > .col + .col {
          margin-left: $spacer;
        }
      }
    }

    .planogram-name {
      //@include truncate-text();
      @include font-size(.9em);
      font-weight: bold;
      text-transform: uppercase;

      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .planogram-actions {
      padding: 0 $spacer;

      .btn {
        .app-icon {
          margin-right: $spacer / 2;
        }
      }

      & > div {
        margin-bottom: $spacer / 2;

        &:last-child {
          margin-bottom: 0;
        }

        &.disabled {
          position: relative;
          &:before {
            display: block;
            position: absolute;
            content: " ";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 4;
          }

          .btn {
            z-index: -1;
          }
        }
      }
    }

    &-placeholder {
      .rect-shape,
      .round-shape,
      .text-row,
      .rect-shape,
      .round-shape,
      .text-row {
        background-color: gray;
        animation: react-placeholder-pulse 1.5s infinite;
      }

      .planogram-image {
        .rect-shape {
          width: 100%;
          height: 100%;
          margin: 0;
        }

        margin-bottom: $spacer / 2;
      }

      .text-row {
        margin-top: .2rem!important;
        max-height: .6rem!important;
        overflow: hidden!important;
      }

      .planogram-name-placeholder {
        margin-top: 0!important;
      }

      .planogram-reference-placeholder {
        max-width: 90px!important;
      }

      .planogram-quantity-placeholder {
        height: 35px!important;
      }
    }

    .catalog-image {
      .catalog-image-inner {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &.has-overlay {

      .planogram-overlay {
        transition: top linear $animate-css-animation-duration, bottom linear $animate-css-animation-duration;
        animation-duration: $animate-css-animation-duration;
        animation-fill-mode: both;
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        bottom: auto;
        padding: $spacer;
        z-index: 4;
        opacity: 0;

        &-inner {
          height: 100%;
          padding: $spacer / 2;
          background: $white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .planogram-heading {
          max-width: 100%;
        }

        .planogram-name {
          white-space: normal;
          text-align: center;
        }
      }

    }

    .planogram-layer {
      transition: top linear $animate-css-animation-duration, bottom linear $animate-css-animation-duration;
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $spacer;
      z-index: 4;

      &-inner {
        height: 100%;
        padding: $spacer / 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-out-of-stock {
        .planogram-layer-inner {
          .planogram-customer-arrival {
            margin-top: .4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .lbl {
              &:after {
                content: " : ";
              }
            }

            span {
              + span {
                margin-top: .1rem;
              }
            }
          }
        }

        p {
          border-radius: 5px;
          padding: $spacer / 2 $spacer;
          background-image: linear-gradient(120deg, #dfdfe0 16.67%, #f2f2f2 16.67%, #f2f2f2 50%, #dfdfe0 50%, #dfdfe0 66.67%, #f2f2f2 66.67%, #f2f2f2 100%);
          background-size: 17.32px 30.00px;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: $font-size-base;
        }
      }
    }

    @include hover-focus-active() {

      &:not(.products-list-item) {
        &.has-overlay {
          .favorite {
            color: $white;
            top: .5rem;
          }
        }
      }

      .planogram-overlay {
        animation-name: fadeIn;
        top: 0;
        bottom: 0;
        opacity: 1;
      }

      &:not(.planogram-card-mode-picker) {
        .planogram-layer {
          animation-name: fadeOut;
          top: 100%;
          bottom: auto;
          opacity: 0;
        }
        .planogram-layer {
          display: none;
        }
      }
    }

    &.out-of-stock {
      .planogram-heading,
      .planogram-image-inner {
        transition: opacity linear $animate-css-animation-duration;
        opacity: 0.4;
      }

      &:active,
      &:hover,
      &:focus,
      &.picked {
        .planogram-heading,
        .planogram-image-inner {
          opacity: 0.9;
        }
      }
    }

  } // -.card

  .planogram-content {
    .planogram-heading {
      .planogram-name {
        ;
      }
    }
  }
}