@mixin callout-variant($color) {
  background-color: #fff;
  border-left-color: $color;
  color: $text-muted;

  .heading-title,
  h4 {
    color:$color;
  }

  hr {
    border-top-color: darken($color, 5%);
  }
}

@mixin truncate-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin supports-safe-area-insets {
  @supports (padding-top: env(safe-area-inset-top)) {
    @content;
  }
}

@mixin catalog-list-sidebar-width($width: $product-list-filter-max-width) {
  width: 100%;
  flex: 1 1 100%;
  transition: max-width ease-in $animate-css-animation-duration;

  @include media-breakpoint-up(lg) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  @include media-breakpoint-up(xl) {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  @include media-breakpoint-up(xxxl) {
    flex: 0 0 20.83333%;
    max-width: $width;
  }

  &.sidebar-mode {
    &-collapsed {
      flex: 0 0 60px;
      max-width: 60px;

      .product-list-sidebar {
        display: none;
      }
    }
  }
}
