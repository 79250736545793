.favorite-department-manager-modal {
  .modal-header {
    border-bottom: 0;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;

    .modal-title {
      margin-bottom: 0;
      margin-top: $spacer;
      flex: 1 1 auto;
    }

    .close {
      color: $text-color;
      opacity: 1;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 2rem;
      font-weight: 100;
      z-index: 100;
    }
  }

  .modal-footer {
    justify-content: center;

    .btn {
      border-radius: 5px;
      background-color: darken($secondary, 7.5%);
    }
  }

  .favorite-department-list {

    .content {
      display: flex;
      flex-direction: column;
    }

    .favorite-department {
      margin-bottom: $spacer / 1.5;
      border: 1px solid $gray-400;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      position: relative;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      text-transform: uppercase;

      .btn-edit {
        position: absolute;
        right: 21px;
        top: -3px;
        margin-left: auto;
        text-decoration: none;
        color: $text-color;

        @include hover-focus-active() {
          color: darken($text-color, 10%);
        }
      }

      .btn-delete {
        position: absolute;
        right: 0;
        top: -3px;
        margin-left: auto;
        text-decoration: none;
        color: lighten($danger, 5);

        @include hover-focus-active() {
          color: darken($text-color, 10%);
        }
      }

      @include transition($badge-transition);

      padding: $badge-pill-padding-x / 1.5;
      @include border-radius($badge-pill-border-radius);

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: 0 0 0 $badge-focus-width rgba($white, .5);
      }

      &.selected {
        outline: 0;
        box-shadow: 0 0 0 $badge-focus-width rgba($text-color, .1);
        border-color: $text-color;
      }

      @include hover-focus-active() {
        cursor: pointer;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .footer {
      margin-top: $spacer / 2;
      text-align: center;

      button {
        padding: 0;
        outline: none;
        line-height: 1.4;
        color: $text-color;
        text-decoration: underline;
      }
    }
  }
}