.cart-list-section,
.sub-cart-modal {
  .minimum-amount-alert {
    position: relative;
    background: $white;
    border-radius: 0;
    border-width: 2px;
    font-size: 0.89em;
    margin-bottom: 0;

    &.has-callback {
      &:not(.alert-success) {
        cursor: pointer;
      }
    }

    &.alert-success {
      border-color: lighten($success, 20%);
      color: darken($success, 10%);
    }
    &.alert-warning {
      border-color: lighten($warning, 20%);
      color: darken($warning, 10%);
    }

    .app-icon {
      line-height: 1;
      &:not(.checked) {
        margin-right: .4rem;
        font-size: 1.2rem;
      }

      &.checked {
        transition: opacity linear $animate-css-animation-duration;
        opacity: 0;
        position: absolute;
        left: 9px;
        top: 9px;
        color: darken($success, 10%);
        font-size: .9em;
      }
    }

    &.alert-success {
      .checked {
        opacity: 0;
      }
    }
  }
}

.alert-cart-locked {
  font-weight: bold;
  margin-bottom: 1rem!important;

  .app-icon {
    margin-right: $spacer / 2;
  }
}

.sub-cart,
.cart-list-section {

  button {
    box-shadow: none;
    outline: none;
  }

  .cart-card {

    & > .collapse,
    & > .collapsing {
      & > .card-body {
        padding: 0;
      }
    }


    &.has-errors {
      .card-header {
        background-color: lighten($warning, 30%);
        border-color: lighten($warning, 20%);
        color: darken($warning, 10%);


        .cart-header{
          color: darken($warning, 10%);
        }

        button {
          color: darken($warning, 10%);
          @include hover-focus-active() {
            color: darken($warning, 30%);
          }
        }
      }
    }
  }

  .btn {
    .app-icon {
      margin-right: .4rem;
    }
  }

  .btn-validate {
    &.inactive {
      opacity: $btn-disabled-opacity;
      cursor: default;
    }

    &.btn-warning {
      background-color: lighten($warning, 30%);
      border-color: lighten($warning, 20%);
      color: darken($warning, 10%);
    }
    &.btn-secondary {
      background-color: lighten($secondary, 30%);
      border-color: lighten($secondary, 20%);
      color: darken($secondary, 10%);
    }
  }

  .btn-process,
  .btn-validate:not(.btn-sm) {
    font-size: .8rem;
    .app-icon {
      margin-right: .4rem;
    }
  }

  @include media-breakpoint-up(md) {
    .btn-process,
    .btn-validate:not(.btn-sm) {
      font-size: $font-size-sm * 1.1;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }

  @include media-breakpoint-up(lg) {
    .btn-process,
    .btn-validate:not(.btn-sm) {
      font-size: $font-size-lg;
    }
  }
  @include media-breakpoint-up(xl) {
    .cart-list-content {
      .cart-card {
        background: $gray-200;
      }
    }
  }
}

.card {
  &.cart-card,
  &.single-cart-form,
  &.single-cart-resume {
    .card-title {
      margin-bottom: 0;
    }
  }

  &.cart-card {
    & > .card-header {
      color: $white;
      background-color: $gray-800;
      padding: $card-spacer-y $card-spacer-x / 4;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .btn-link {
        padding: 0;
        display: block;
        margin-left: 0;
        margin-right: 0;
        flex: 1 1 auto;

        @include hover-focus-active() {
          text-decoration: none;
          color: $gray-300;
        }
      }

      .cart-header-actions {
        flex: 0 0 auto;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        & > button {
          + button {
            margin-left: $spacer / 2;
          }
        }
      }

      .btn-export,
      .btn-clean {
        font-size: .8rem;

        .lbl {
          display: none;
        }

        @include media-breakpoint-up(lg) {
          .lbl {
            display: inline-block;
          }
        }
      }

      .cart-header,
      .btn-link {
        color: $white;
      }
    }

    &.cart-resume {
      border-width: 2px;
      border-color: $gray-800;

      .btn-success {
        background-color: darken($success, 5%);
      }

      & > .card-header {
        .app-icon {
          margin-right: .8rem;
        }
      }

      .card-footer {
        border: 0 none;
        padding-top: $spacer;
      }

      & > .card-footer,
      & > .card-body {
        background: $body-bg;
      }

      &  > .card-body {
        background: $body-bg;
        padding-bottom: 0;

        & > .cart-list {
          & > .cart-list-content{
            & > div {
              margin-bottom: $spacer;

              //&:last-child {
              //  margin-bottom: 0;
              //}
            }
          }

          .cart-list-footer {
            padding: $spacer 0;
            padding-top: 0;
          }
        }
      }

      .fade-exit-done {
        display: none;
      }

      .card-footer {
        overflow: hidden;

        .card-footer-component {
          button {
            box-shadow: none;
            &.btn-link {
              cursor: pointer;
              color: $text-color;
            }

            @include media-breakpoint-up(md) {
              &.btn-link {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }

          & > .row {
            flex-direction: column-reverse;
            @include media-breakpoint-up(xxl) {
              flex-direction: row;
              align-items: center;
              justify-content: center;
              flex-wrap: nowrap;
              & > [class*="col-"],
              & > .col {
                //flex: 0 0 auto;
                //width: auto;
                max-width: 100%;
              }

              & > div:first-child {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
              }
            }
          }

          &.show {
            .card-body,
            .card-footer {
              overflow: visible;
            }
          }
        }
      }

      .btn {
        outline: none;
        @include box-shadow(none);
      }

      .single-cart-resume {
        height: auto;
        overflow: hidden;
        animation-duration: $animate-css-animation-duration * 2;
        animation-fill-mode: both;

        transition:
                opacity linear $animate-css-animation-duration * .1,
                box-shadow linear $animate-css-animation-duration * .1,
                height linear $animate-css-animation-duration * .1,
                visibility linear $animate-css-animation-duration * .1
        ;

        .card-body {
          overflow: hidden;
        }

        &.editing {
          overflow: visible;
        }

        &.is-empty {
          .btn-validate {
            //pointer-events: none;
            cursor: default;
          }
        }
      }

      &.editing {
        .single-cart-resume {
          &:not(.editing) {
            animation: slideInUp;
            height: 0;
            opacity: .6;
          }

          &.editing {
            box-shadow: $box-shadow-lg;
          }
        }

        .card-body,
        .card-footer {
          overflow: visible;
        }
      }

      &.editing-all {
        & > .card-body {
          display: none;
        }
      }

    }
  }

  &.single-cart-form,
  &.single-cart-resume {
    & > .card-header,
    & > .card-footer {
      background: transparent;
      border: 0 none;
    }

    & > .card-header {
      cursor: pointer;
    }

    & > .card-footer {
      padding-top: 0;
    }

    & > .card-body {
      padding-top: $card-spacer-y;
      padding-bottom: $card-spacer-y;

      & > .single-cart-form-fields {
        & > div:last-child {
          margin-bottom: 0;
        }
      }

      p {
        margin-bottom: 0;

        .lbl {
          &:after {
            content: " : ";
            font-size: .85em;
            margin-right: .1rem;
          }
        }

        .price {
          color: #000;
        }

        &.cart-total {
          .lbl,
          .price {
            font-weight: bold;
          }
        }

        &.cart-total-eco-tax {
          font-size: .8rem;
          font-style: italic;

          .value {
            white-space: break-spaces;
          }
        }
      }

      .alert {
        margin-right: 0;

        p {
          margin-top: $spacer / 4;
          margin-bottom: 0;
          line-height: 1;
        }

        &.alert-danger {
          color: $danger;
          border-color: $danger;
        }
      }
    }

    &.minimum-reached {
      &:not(.has-errors) {
        &:not(.is-empty) {
          &:not(.locked) {
            .card-body {
              p.cart-total {
                .price {
                  color: $success;
                }
              }
            }
          }
        }
      }
    }

    .row {
      align-items: flex-start;
    }

    .minimum-amount-alert {
      margin: 0 $spacer / 2 0 $spacer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: $alert-padding-y/2 $alert-padding-x/2;

      @include media-breakpoint-down(md) {
        margin: 0;
        margin-top: $spacer;
      }
    }

    .single-cart-light-section {
      margin-top: $spacer;

      @include media-breakpoint-up(xl) {
        background: lighten($gray-100, 3%);
      }

      &:not(.show) {
        border: 0 none;
        .card-header,
        .card-body {
          border: 0 none;
        }

        .card-header {
          border: 1px solid $primary;
        }
      }

      .card-header {
        .card-title {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          font-size: 1rem;
          .app-icon {
            margin-left: auto;
          }
        }
      }

      .card-body {
        display: none;
      }

      &.show {
        border-color: $primary;
        .card-header {
          border: 0 none;
          border-bottom: 1px solid $primary;
        }

        .card-body {
          display: block;
          padding-left: 0;
          padding-right: 0;
        }

        .cart-light-list {
          max-height: 460px;
          overflow-y: auto;
          overflow-x: hidden;
          font-size: .85rem;

          .cart-light-item {
            padding-left: 0;
            padding-right: 0;
            padding-top: $card-spacer-y / 2;
            padding-bottom: $card-spacer-y / 2;
            border-bottom: 1px solid $gray-400;

            .account-card {
              .label {
                margin-bottom: 0;
              }
            }

            .locked-icon-container {
              display: inline-block;
              padding: 0;
              color: $text-color;
              line-height: 1;
              position: relative;
              margin-right: .4rem;

              .app-icon {
                margin: 0;
              }
            }

            .btn {
              svg + .app-icon {
                display: none;
              }
            }

            .account-card {
              i {
                font-size: .9em;
                color: lighten($text-color, 20%);
                font-style: normal;
              }
            }

            .resume-quantity {
              .app-icon {
                margin-left: .2rem;
              }
            }

            .alert {
              //padding: 0;
              margin: $spacer / 2 0;
              //justify-content: flex-start;
              flex-direction: row;
              //border: 0 none;
              //background: transparent;
            }

            .total-amount {
              .price {
                font-weight: bold;
              }
            }
          }

        }


        .scrollbar-container {
          &.ps--active-y {
            & > .cart-light-list {
              margin-right: 15px;
            }
          }
        }
      }

      &.disabled {
        opacity: $btn-disabled-opacity;
        pointer-events: none;
        cursor: default;

        .app-icon {
          display: none;
        }
        .card-header {
          border-color: lighten($primary, 30%);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.cart-card {
      & > .card-header {
        padding: $card-spacer-y $card-spacer-x;
      }

      &.cart-resume {
        .card-header {
          padding: $card-spacer-y $card-spacer-x / 2;
        }

        .card-body {
          //padding: $card-spacer-y / 4 $card-spacer-x / 4;
        }

      }
    }

    .single-cart-form {
      .card-header {
        padding: $card-spacer-y $card-spacer-x / 2;
      }

      .card-body {
        padding: $card-spacer-y / 4 $card-spacer-x / 4;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.cart-card {
      &.cart-resume {
        .card-header {
          padding: $card-spacer-y $card-spacer-x;
        }

        .card-body {
          //padding: $card-spacer-y $card-spacer-x;
        }

      }
    }

    .single-cart-form {
      .card-header {
        padding: $card-spacer-y $card-spacer-x;
      }

      .card-body {
        padding: $card-spacer-y $card-spacer-x;
      }
    }
  }
}

.cart-header {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-items: center;
  font-size: 0.6rem;

  .separator {
    margin: 0 $spacer / 2;
  }

  .cart-number {
    text-transform: uppercase;
  }

  .app-icon {
    display: none;
    margin-right: .4rem;
    line-height: 1;

    &.locked-icon {
      font-size: 1.1rem;
    }

    & + .locked-icon {
      margin-right: .4rem;
    }
  }

  .value {
    text-transform: uppercase;
  }

  .product-shipping-location {
    display: flex;
    flex-direction: row;
    align-items: center;

    .lbl {
      display: none;
      &:after {
        content: " : ";
        margin-right: .5rem;
        margin-left: .2rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 0.75rem;
    justify-content: flex-start;
    text-align: left;

    .app-icon {
      display: block;
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.875rem;
  }
  @include media-breakpoint-up(xl) {
    .product-shipping-location {
      .lbl {
        display: inline-block;
      }
    }
  }
}

.cart-list {
  .accordion {
    .card-header {
      cursor: pointer;
    }

    & > div {
      margin-bottom: $spacer /2;
      border-bottom: $card-border-width solid $card-border-color!important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.single-cart {
  padding: $card-spacer-x $card-spacer-y;
  padding-bottom: 0;

  .minimum-amount-alert {
    padding: 0.60rem .40rem;
  }

  & > .single-cart-footer {
    border-bottom: 1px solid $gray-600;
    margin-bottom: 1rem;

    .col-alert {
      margin-bottom: $spacer;
    }
  }

  @include media-breakpoint-up(xl) {

    & > .single-cart-footer {
      border-bottom: 0 none;
      margin-bottom: 0;
      padding-bottom: $card-spacer-y;

      .btn-process,
      .btn-validate {
        font-size: 1rem;
        padding: $btn-padding-y-lg $btn-padding-x-lg/2;
      }

      .minimum-amount-alert {
        font-size: .9em;
      }

      & > .row {
        align-items: center;

        & > div {
          margin-bottom: 0;
          &:first-child {
            padding-right: $grid-gutter-width / 10;
          }
          &:last-child {
            padding-left: $grid-gutter-width / 10;
          }
        }

        & > [class*="col-"],
        & > .col {
        }
      }
    }
  }
}

.cart-table {

  .inner-alert {
    display: flex;
    flex: 1 1 auto;
  }

  &.single-cart-table {
    .head {
      display: none;
    }

    .cart-item {
      font-size: .85em;
      border-bottom: 1px solid $gray-400;
      padding: .8rem 0;

      .product-heading-inner {
        position: relative;
        .app-icon {
          display: none;
          line-height: 1.2;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.has-errors {
        background-color: lighten($warning, 30%);

        .product-heading-inner {
          .app-icon {
            color: darken($warning, 30%)!important;
          }
        }

        .product-name {
          font-weight: bold;
        }

        .cart-item-errors {
          margin-bottom: .5rem;
          & > p {
            &:first-child {
              margin-top: 0;
            }
          }
        }
        .invalid-feedback {
          display: block;
          margin-bottom: 0;
          font-weight: bold;
          font-size: $font-size-sm;
        }
      }

      .product-name {
        margin-bottom: $spacer / 2;
      }

      @include clearfix();

      & > .packing,
      & > .price {
        display: none;
      }

      .price {
        .product-prices {
          .unit-strike-price {
            font-size: .7rem!important;
            text-decoration: line-through;
          }
        }
      }

      .image,
      .quantity,
      .cart-item-prices {
        float: left
      }

      .image {
        width: 40%;
        padding: $spacer / 2;
      }

      .quantity {
        .product-customer-stock {
            margin-top: $spacer / 2;
            display: block;

          .date {
            line-height: 1;
            font-size: .9em;
            font-style: italic;
            margin-left: .3rem;
          }
        }

        .quantity-selector {
          max-width: 124px;

          .app-icon {
            margin-right: 0;
          }

          .btn {
            outline: none;
            padding: 0.175rem 0.35rem;
          }

          .btn-multiple {
            display: none!important;
            background-color: transparent;
            color: lighten($text-color, 20%);
            box-shadow: none;
            outline: none;
            border: 0 none;
          }

          &:not(.multiple) {
            .input-group {
              background: $white;
              position: relative;
              overflow: hidden;

              & > .app-icon-inner {
                transition: opacity linear $animate-css-animation-duration;
                position: absolute;
                z-index: 5;
                top: -500em;
                left: -500em;
                opacity: 0;
              }

              .form-control {
                text-align: left;
              }
            }
          }

          &.multiple {
            background-color: transparent;
            .quantity-selector-quantity {
              background-color: transparent;
              .input-group {
                background-color: transparent;
              }
            }

            .input-group {
              align-items: center;
              @include media-breakpoint-up(xl) {
                justify-content: center;
              }

              & > .input-group-content {
                align-items: center;
                justify-content: center;

                & > .app-icon-inner {
                  flex: 0 1 18px;
                  margin-left: 0;

                  & > .app-icon {
                    display: none;

                    &.svg-inline--fa {
                      margin-top: 0;
                    }

                    &.app-icon-multiple {
                      display: block;
                      flex: 0 1 auto;
                      margin-left: 0;
                      cursor: pointer;
                    }
                  }
                }

                & > .form-control-plaintext,
                & > input[readonly] {
                  flex: 0 0 50px;
                  width: 50px;
                  max-width: 50px;
                  padding: 0;
                  padding-left: .5rem;
                  box-shadow: none;
                  cursor: pointer;
                }
              }
            }
          }

          .quantity-selector-quantity {
            max-width: 164px;
          }

          &.saving {
            .input-group {
              position: relative;
              & > .app-icon-inner {
                opacity: 1;
                position: absolute;
                top: .7rem;
                left: 1.5rem;
              }
            }
          }
        }
      }

      .footer {
        display: none;
      }

      .cart-item-prices {
        float: left;
        width: 56%;
        margin-top: $spacer / 2;
        font-size: .85rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;

        .cart-item-product-prices {
          display: none;
        }

        .total-price {
          color: $primary;
        }

        .app-icon {
          margin-left: .2rem;
        }

        .product-packing {
          &:after {
            content: " / ";
            margin-right: .4rem;
          }
        }
      }

      .product-field {
        display: none;
        .lbl {
          &:after {
            content: " : ";
            font-size: .85em;
            margin-right: .1rem;
          }
        }

        .value {
          font-weight: bold;
          font-size: .9em;
        }
      }
    }

    .footer {
      display: none;
    }

    .cart-totals {
      background: $gray-800;
      padding: $spacer;
      margin-bottom: $spacer;
      table {
        color: $white;
        margin-bottom: 0;

        th, td {
          text-align: right;
          border: 0 none;
        }

        .cart-totals-price {
          font-size: 1.1rem;

          td, th {
            border-top: 1px solid $gray-400;
          }

          td {
            color: darken($primary, 5%);
          }
        }
      }

      .app-icon {
        margin-left: .4rem;
      }
    }

    .footer,
    .cart-totals {
      .total-eco-tax {
        color: $text-color;
        font-size: .75rem;
        font-style: italic;
        font-weight: normal;
        text-transform: none;
        line-height: 1.2;

        .lbl,
        .value {
          white-space: nowrap;
        }

        .value {
          white-space: break-spaces;
        }

      }
    }
    .cart-totals .total-eco-tax {
      color: $white;
    }

    @include media-breakpoint-up(lg) {
      .cart-item .quantity .quantity-selector.saving .input-group > .app-icon {
        top: -0.015em;
        left: 1.9em;
      }
    }
    @include media-breakpoint-up(xl) {
      .cart-item .quantity .product-customer-stock {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        //right: 0;
        top: 76%;
        white-space: nowrap;

        //&.has-date {
        //  bottom: -38px;
        //}
      }


      .cart-item .quantity .quantity-selector.saving .input-group > .app-icon {
        top: -.01em;
        left: 1.8em;
      }
    }
    @include media-breakpoint-up(xxxl) {
      .cart-item .quantity .quantity-selector.saving .input-group > .app-icon {
        left: 3em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.single-cart-table {
      .cart-item {
        font-size: 1em;

        .image {
          width: 20%;
        }

        .cart-item-prices {
          width: 60%;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &.single-cart-table {
      //structure
      display: flex;
      flex-flow: row wrap;
      width:100%;

      > .throw {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-bottom:1px solid $gray-500;
        margin: 0;
        padding-bottom: .5rem;
        width: 100%;

        > div.cell {
          flex: 1 1 auto;
          padding: .5rem;

          &.description {
            .product-name {
              font-size: .75rem;
              margin-bottom: 0;
            }

            .product-field {
              display: block;
            }
          }

          &.description,
          &.product {
            flex: 1 1 auto;
          }

          &.image {
            flex: 0 0 70px;
            max-width: 70px;
            padding: 0;
          }

          &.price {
            flex: 0 0 98px;
            max-width: 98px;
            text-align: center;
            .price {
              text-align: center;
            }
          }

          &.packing {
            flex: 0 0 70px;
            max-width: 70px;

            text-align: center;
            .product-packing {
              text-align: center;
            }

            .app-icon {
              font-size: 1.1rem;
              margin-left: .4rem;
            }
          }
          &.quantity {
            flex: 0 0 140px;
            max-width: 140px;
            position: relative;
          }
        }
      }

      .head,
      .footer {
        border-bottom: 0 none;
      }

      .footer,
      .head {
        background: white;
        margin-bottom: .5rem;
        padding-bottom: 0;
        .quantity {
          text-align: center;
        }

        & > div {
          font-size: .8em;
          text-transform: uppercase;
          padding: .2rem .5rem!important;
        }
      }

      .head {
        padding-bottom: 0;
      }

      .footer {
        margin: .5rem 0;
        & > div {
          font-size: 1.05em;

          &.total-price {
            color: $primary;
          }
        }
      }

      .cart-item {

        &.has-errors {
          .product-heading-inner {
            padding-left: 20px;
            .app-icon {
              display: block;
            }
          }
          .cart-item-errors {
            margin-bottom: .2rem;
          }
        }

        .cart-item-prices {
          display: none!important;
        }

        .packing,
        .price {
          display: block;
        }

        .quantity,
        .price,
        .image {
          float: none;
          width: auto;
        }

        .product-eco-tax,
        .total-eco-tax {
          font-size: 85%;
          font-style: italic;
          line-height: 1.2;

          .lbl,
          .value {
            white-space: nowrap;
          }
        }
      }

      .cart-totals {
        display: none!important;
      }
    }
  }
}

.sub-cart-modal {

  .cart-table {
    .throw.head {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .sub-cart {
    .item-quantity {
      text-align: center;

      &.has-errors {
        color: darken($warning, 10%);
        .app-icon {
          margin-right: .4rem;
        }
      }
    }

    .container-volumes {
      display: flex;
      max-width: 540px;
      flex-direction: column;
      margin-left: auto;
      justify-content: flex-end;
      align-items: flex-end;

      & > div {
        flex: 1 1 auto;
        min-width: 100%;
        justify-content: flex-end;

        .resume {
          text-align: right;
        }
      }

      &.top {
        margin-top: 0;
        border-top: 0 none;
      }

      &.bottom {
        padding-top: 1.1rem;
      }

      @include media-breakpoint-down(xl) {
        &.top {

        }

        &.bottom {
          border-top: 0 none;
          padding-top: 0;
        }
      }
    }
  }

  .cart-item {
    padding-top: $spacer / 2!important;
    .quantity {
      .product-customer-stock {
        bottom: -20px!important;
      }
    }
  }

  .single-cart-footer {
    border: 0 none;
  }

  @include media-breakpoint-up(md) {
    .alert {
      margin-bottom: 0;
    }
  }
}

.cart-item-parent-errors-popover {
  border-color: darken($warning, 10%);

  .popover-body {
    &> p {
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .invalid-feedback {
    display: block;
    color: darken($warning, 20%);
  }
}

.create-order-modal {
  .modal-body {
    padding: 0;
  }

  .loading-part {
    padding: $card-spacer-y $card-spacer-x;
    text-align: center;
    .app-icon {
      font-size: 2rem;
    }
  }

  .alert {
    margin: $card-spacer-y $card-spacer-x;
  }

  .card-header {
    pointer-events: none;
    cursor: default;
  }

  &:not(.global) {
    .create-order-form {
      &:not(.multiple) {
        .card-header {
          display: none;
        }
      }
    }
  }

  .create-order-form {
    .card {
      border-width: 0;
    }

    &.multiple {
      .card {
        border-top-width: 1px;
      }
    }

    //.strict-minimum-amount-form-group,
    .split-fields-form-group {
      padding: 1rem;
      padding-bottom: 0;
    }
  }

  .create-order-form-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: $modal-inner-padding - $modal-footer-margin-between / 2;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    @include border-bottom-radius($modal-content-inner-border-radius);

    // Place margin between footer elements
    // This solution is far from ideal because of the universal selector usage,
    // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
    // stylelint-disable-next-line selector-max-universal
    > * {
      margin: $modal-footer-margin-between / 2;
    }
  }
}

.cart-light-unlock-popover {
  text-align: center;
  .btn-unlock {
    margin-top: $spacer / 2;
    .app-icon {
      margin-right: $spacer / 2;
    }
  }
}

#cart-alert-tooltip {
  p {
    margin-bottom: $spacer / 2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}