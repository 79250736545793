textarea {
  &.no-resize {
    resize: none;
  }
}

.react-datepicker-popper[data-placement^="top"] {
  left: 30px!important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

[class*="col-"],
.col {
  .form-label {
    margin-bottom: 0;
  }
}


.input-group {
  & > .react-datepicker-wrapper {
    width: auto!important;
  }
}
.react-datepicker__monthPicker {
  .react-datepicker__month-wrapper {
    text-align: center;

    .react-datepicker__month-text {
      display: inline-block;
      width: 3rem;
      margin: 2px;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
    }
  }
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $orange;
}

.input-group-search {
  .input-group-prepend,
  .input-group-append {
    .input-group-text {
      background-color: transparent;
      border-color: $text-color;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-radius: 0;

      .app-icon {
        + span {
          margin-left: .2rem;
        }
      }
    }
  }

  & > .react-autosuggest__container {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    position: relative;
    input {
      outline: none;
      box-shadow: none;
    }

    .react-autosuggest__suggestions-container {
      position: absolute;
      z-index: 500;
      left: 0;
      right: 0;
      overflow: hidden;

      &--open {
        background: $white;
        max-height: 200px;
        overflow-y: auto;

        .react-autosuggest__section-container {
          & > .react-autosuggest__section-title {
            background-color: $gray-light;
            padding: $spacer / 2;
            text-transform: uppercase;
            color: $primary;

            h4 {
              padding: 0;
              margin: 0;
            }
          }

          & > .react-autosuggest__suggestions-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            box-shadow: $box-shadow-lg;

            li {
              list-style-type: none;
              transition: background-color linear 300ms / 2;
              background-color: white;
              cursor: pointer;
              padding: $spacer;
              margin: 0;
              border-bottom: 1px solid $gray-300;

              &:last-child {
                margin-bottom: 0;
              }

              @include hover-focus-active() {
                background-color: $gray-200;
              }
            }
          }
        }
      }
    }
  }

  .form-control {
    background-color: transparent;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    border-color: $text-color;
    box-shadow: none;

    // Placeholder
    &::placeholder {
      color: darken($gray-400, 18%);
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }
  }
}

.form-group-phone {
  .form-phone-input {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    .input-group-prepend {
      position: relative;
    }

    .input-group-text {
      cursor: pointer;
      display: flex;
      align-items: center;
      .flag {
        position: relative;
        top: 2px;
        margin-right: .5rem;
      }
    }

    .country-autosuggest {
      input {
        outline: none;
        box-shadow: none;
        width: 0;
        height: 0;
        border: 0 none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        padding: 0;
      }
    }

    .country-select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      outline: none;
      box-shadow: none;
      border: 0 none;
      opacity: 0;
    }

    .react-autosuggest {
      &__container {

      }

      &__suggestions-container {
        display: none;

        &--open {
          display: block;
          z-index: 8;
          background: $white;
          border: 1px solid $gray-200;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;

          ul {
            @include list-unstyled();
            max-height: 200px;
            overflow-y: auto;

            & > li {
              cursor: pointer;
              display: block;
              padding: $nav-link-padding-y * 1.5 $nav-link-padding-x;
              text-decoration: if($link-decoration == none, null, none);
              border-bottom: 1px solid $gray-200;

              @include hover-focus-active() {
                background-color: $gray-200;
              }
            }
          }
        }
      }
    }
  }
}

.form {
  .flatpickr-input {
    background-color: $input-bg;
  }
}

form, .form, .form-group {
  table {
    tbody {
      td {
        text-align: left;
      }
    }
  }
}

.country-selector .country__menu {
  z-index: 6;
}