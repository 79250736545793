.login-page {
  background-color: $white;

  .login-window-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    h1, h6 {
      text-transform: none;
    }

    h1 {
      font-size: 1.9em;
      font-weight: 500;
    }

    .user-name-heading {
      cursor: pointer;
      display: inline-block;
      padding: .3rem 2rem;
      border: 1px solid $gray-400;
      border-radius: $border-radius * 4;
      font-size: .8rem;

      .app-icon {
        margin-right: .4rem;
      }

      .value {
        font-weight: 600;
      }
    }

    .login-window {
      display: block;
      width: 90%;
      max-width: 448px;
      margin: 0 auto 0;
      color: $black;

      .login-window-2fa-mode {
        font-style: italic;
      }

      .language-switcher {
        text-align: left;
        margin-bottom: 4em;
      }

      .login-window-body-heading {
        text-align: center;
        padding: 10px 0;
        margin-bottom: $spacer * 1.2;

        img {
          width: 60%;
          max-width: 240px;
          margin-bottom: $spacer / 2;
        }
      }

      .login-window-body {

        padding: 4em 1em;
        border-radius: $border-radius * 2;
        border: 1px solid $gray-400;

        .login-step-list {
          .login-step-item {
            height: 0;
            visibility: hidden;
            animation-duration: $animate-css-animation-duration * 2;
            animation-fill-mode: both;

            transition:
                    opacity linear $animate-css-animation-duration * .1,
                    height linear $animate-css-animation-duration * .1,
                    visibility linear $animate-css-animation-duration * .1
          ;

            &.from-prev {
              animation-name: slideInLeft;
            }

            &.from-next {
              animation-name: slideInRight;
            }

            &.inactive {
              visibility: hidden;
              height: 0;
              opacity: 0;
            }

            &.active {
              visibility: visible;
              height: auto;
              opacity: 1;
            }
          }
        }

        input::-ms-reveal,
        input::-ms-clear {
          display: none;
        }

        .input-group {
          .form-control {
            border-right: 0 none;
          }
          .input-group-prepend {
            background-color: transparent;
          }

          .input-group-text {
            background: transparent;
            border-left: 0 none;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }

        .login-form-step-footer {
          margin-top: $spacer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          button {
            border-radius: $border-radius;
          }

          .btn-prev {
            padding-left: 0;
            color: $text-color;
          }

          .btn-next {
            margin-left: auto;
            font-weight: 500;
            font-size: .95rem;
            padding: .3rem 1.2rem;
          }
        }

        .form-group {
          &-inner {
            position: relative;
          }

          .form-control {
            transition: background-color $animate-css-animation-duration / 2 cubic-bezier(0.4,0,0.2,1);
            background-color: transparent;
            z-index: 6;

            &-placeholder {
              transition: transform $animate-css-animation-duration / 2 cubic-bezier(0.4,0,0.2,1), background-color $animate-css-animation-duration / 2 cubic-bezier(0.4,0,0.2,1), color $animate-css-animation-duration / 2 cubic-bezier(0.4,0,0.2,1);
              left: 0;
              display: block;
              position: absolute;
              font-size: 1.05rem;
              color: lighten($text-color, 30%);
              background-color: transparent;
              z-index: 8;
              pointer-events: none;
            }

            &.is-invalid {
              + .form-control-placeholder {
                color: lighten($danger, 5%);
              }

              ~ .input-group-prepend {
                & > .input-group-text {
                  border-color: $danger;
                }
              }
            }

            &-lg {
              + .form-control-placeholder {
                padding: 0 $input-padding-x-lg;
                top: 50%;
                // values based on $input-height-lg
                margin-top: add(-$input-line-height-lg / 4 * 1em, add(-$input-padding-y-lg / 2, -$input-height-border, false));
              }

              @mixin placeholder-info {
                box-shadow: none;
                &:not(.is-invalid) {
                  border-color: $primary;
                }

                + .form-control-placeholder {
                  color: $primary;
                  background-color: $white;
                  transform: scale(.75) translateY(add(-$input-line-height-lg / 2 * 1em, add(-$input-padding-y-lg * 2, -$input-height-border, false))) translateX(add(-$input-padding-x-lg * 1.5, -$input-height-border));
                }

                &.is-invalid {
                  + .form-control-placeholder {
                    color: $danger;
                  }
                }
              }

              // , &:autofill, &:-webkit-autofill, &:-moz-autofill
              &.has-value, &:focus, &:active {
                @include placeholder-info;

                & ~ .input-group-prepend {
                  .input-group-text {
                    @include placeholder-info;
                  }
                }
              }

              &:autofill {
                @include placeholder-info;
              }

              &:-moz-autofill {
                @include placeholder-info;
              }
              &:-ie-autofill {
                @include placeholder-info;
              }

              &:-webkit-autofill {
                @include placeholder-info;
              }
            }
          }
        }
      }

      .login-window-footer {
        .language-switcher {

          .dropdown-toggle {
            &:after {
              top: 3px;
              position: relative;
            }
          }

          .dropdown-menu {
            .name {
              position: relative;
              top: .15em;
            }
          }

          .name {
            margin-left: $spacer / 4;
            font-style: italic;
            font-size: .7rem;
            &:before {
              content: "(";
            }
            &:after {
              content: ")";
            }
          }
        }

        .col-login-window-help {
          text-align: right;
          .btn {
            padding-right: 0;
            color: $gray-600;
            outline: none;
            text-shadow: none;
            box-shadow: none;
          }
        }
      }

      @include media-breakpoint-up(md) {
        .login-window-body {
          padding: 2em 4em;
        }
      }
    }
  }
}

.swal-login-error {
  & > .swal-logo {
    max-width: 180px;
    margin: 1rem auto;
  }

  & > h2 {
    display: none;
  }
}
