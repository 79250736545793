/****************************************************************
 * GLOBAL
 ****************************************************************/
.cms-widget {
  .heading {
    margin-bottom: $cms-widget-inner-padding-y / 3;

    & > p:first-child {
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 2.667rem;
      }
    }

    & > p + p {
      font-size: 1rem;
    }

    p + p {
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 1.1px;
    }
  }

  &-spacing {
    padding-top: $cms-widget-inner-padding-y;
    padding-bottom: $cms-widget-inner-padding-y;
  }

  &-theme {
    &-default {
      background-color: transparent;
    }

    &-dark {
      background-color: $cms-widget-dark-bg;
      color: $cms-widget-dark-tint-color;
      * {
        &:not(.btn) {
          color: $cms-widget-dark-tint-color;
        }
      }

      hr {
        border-color: rgba($cms-widget-dark-tint-color, 0.5);
      }
    }

    &-light {
      background-color: $cms-widget-light-bg;
      color: $cms-widget-light-tint-color;
      * {
        &:not(.btn) {
          color: $cms-widget-light-tint-color;
        }
      }

      hr {
        border-color: $cms-widget-light-tint-color;
      }
    }
  }

  &.cms-container,
  &.cms-container-fluid {
    & > .cms-widget-inner {
      @include make-container();
    }
  }

  &.cms-container {
    & > .cms-widget-inner {
      @include make-container-max-widths();
    }
  }
}
/****************************************************************
 * LEADERBOARD / INTERACTIVE SLIDER
 ****************************************************************/
$leaderboard-slider-padding: $grid-gutter-width / 2;
$leaderboard-button-widths: (
        xs: 30px,
        sm: 46px,
        md: 46px,
        lg: 46px,
        xl: 46px,
        xxl: 46px,
);

.cms-widget-app-slider {
  position: relative;

  button {
    outline: none;
    box-shadow: none;
    border: 0 none;
  }

  .app-slider-navigation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .app-slider-slide {
    display: block;
    flex-grow: 1;
    opacity: 0.45;
    transition: opacity 500ms ease-in-out;
    position: relative;
    overflow: hidden;

    button {
      outline: none;
      box-shadow: none;
      border: 0 none;
      display: block;
    }

    img {
      width: 100%;
    }
  }

  .swiper-pagination {
    width: 100%;
    bottom: 0;

    .swiper-pagination-bullet {
      border: 1px solid $gray-dark;
      background-color: $white;
      opacity: 1;
      width: 8px;
      height: 8px;
      margin: 0 .2rem;

      @include media-breakpoint-up(md) {
        width: 12px;
        height: 12px;
      }

      &-active {
        background-color: $gray-dark;
      }
    }
  }

  .swiper-slide {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        width: $container-max-width - map_get($leaderboard-button-widths, $breakpoint) * 2 - $leaderboard-slider-padding * 2;
      }
    }

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    &-active {
      .app-slider-slide {
        opacity: 1;
      }
    }
  }

  [class*="swiper-button-"] {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 200;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms ease-in-out;

    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        width:  map_get($leaderboard-button-widths, $breakpoint)
      }
    }

    border: 0 none;
    color: $white;

    &:after {
      content: "";
    }

    i {
      font-size: 1.2rem;

      @include media-breakpoint-up(sm) {
        display: block;
        position: absolute;
        font-size: .9rem;
      }

      @include media-breakpoint-up(md) {
        bottom: 2px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        bottom: 10px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.2rem;
      }
    }

    span {
      display: none;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      font-weight: bold;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      font-size: .6em;
      color: inherit;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        font-size: .8em;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1em;
      }
    }

    &.disabled {
      opacity: .45;
      cursor: default;
      pointer-events: none;
    }
  }

  .swiper-button-prev {
    left: 0;

    span {
      transform: rotate(180deg);
    }
  }

  .swiper-button-next {
    right: 0;
  }


  &.app-slider-slide-full-width {
    .swiper-slide {
      width: 100%!important;

      button {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;

        img {
          object-fit: cover;
        }
      }
    }
  }
}

.cms-widget-interactive_slide {

  .app-slider-navigation {
    button {
      background-color: $gray-800;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $white;
    fill: $white;

    i,span {
      color: inherit;
    }
  }

  .interactive-slide {
    display: flex;
    flex-direction: column;

    .interactive-slide-content {
      width: 100%;
      max-width: 100%;
      flex: 1 0 0;

      position: relative;

      .interactive-slide-tag {
        position: absolute;
        z-index: 2;

        .btn-tag {
          display: block;
          transform: translate(-50%, -50%);
          transition: opacity ease-in 300ms;
          opacity: .7;

          .app-icon {

            img {
              width: 50px;
              height: 50px;

              &.hover {
                display: none;
              }
            }
          }

          &:hover, &:focus, &:active {
            cursor: pointer;
            opacity: 1;

            .app-icon {
              img {
                display: none;
                &.hover {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    .interactive-slide-footer {
      flex: 0 0 auto;
      width: auto;

      .btn {
        border: 0 none;
        padding: $btn-padding-y-lg * 1.2 $btn-padding-x;
        text-transform: uppercase;
        font-weight: bold;
        .app-icon {
          color: inherit;
          margin-left: $spacer / 2;
        }
      }
    }
  }
}

.interactive-slide-tag-popover {
  min-width: 81%;
  max-width: 91%;
  z-index: 1049;
  border-radius: 0;
  box-shadow: 0 0 15px 2px rgba(0,0,0,0.5);

  .popover-body {
    padding: 0;
  }

  .product-card {
    &.product-card-mode-expanded {
      max-width: none;
      padding: 0 20px;

      .product-content {
        padding: 0;
      }

      .product-detail-base-info {
        font-size: 0.75rem;
      }

      .quantity-selector.with-packing .form-control {
        max-width: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    min-width: 0;
    max-width: 650px;


    .product-card {
      &.product-card-mode-expanded {

        .product-content {
          padding: $spacer / 1.8 0;
          padding-left: $spacer;
        }

        .product-detail-base-info {
          font-size: 0.875rem;
        }
      }
    }
  }
}

/****************************************************************
 * TabbedCarousel
 ****************************************************************/
.cms-widget-highlight_category {
  .heading {
    text-align: center;
  }

  .nav-pills {
    display: none;
    margin-bottom: $spacer * 1.5;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .nav-item {
      text-transform: uppercase;

      .nav-link {
        color: $gray-600;
        background: none;
        padding: $nav-link-padding-y / 2 $nav-link-padding-x;
        position: relative;
        @include media-breakpoint-up(sm) {
          padding-top: 0;
          padding-bottom: $spacer / 2;
        }

        &:before {
          content: "";
          position: absolute;
          height: 2px;
          bottom: 0;
          left: $nav-link-padding-x;
          right: $nav-link-padding-x;
          background-color: $gray-600;
          transition: all 0.2s ease-in;
        }

        &:focus,
        &:active,
        &:hover,
        &.active {
          color: $primary;

          &:before {
            background-color: $primary;
          }
        }
      }
    }
  }

  .carousel-app-footer {
    margin-bottom: 0;
  }

  .show-detail {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 0 none;
  }

  &.multiple-category {
    .nav-pills {
      display: flex;
    }
  }
}

/****************************************************************
 * Inspiration
 ****************************************************************/
.cms-widget-inspiration {

  padding-bottom: .1rem;
  padding-top: 1rem;

  &-carousel {
    overflow: hidden;
    .swiper {
      overflow: visible;
    }

    .carousel-app-content {
      margin-bottom: 0;
    }

    .carousel-app-item {
      display: block;
      opacity: 0.45;
      transition: opacity 500ms ease-in-out;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }

      button {
        width: 100%;
        display: block;
        outline: none;
        box-shadow: none;
        border: 0 none;
        margin: 0;
        padding: 0;
      }

      .btn {
        position: absolute;
        bottom: 2rem;
        z-index: 6;
        left: 0;
        right: 0;
        margin: 0 $btn-padding-x-lg * 3;

        border-radius: $border-radius-lg;
        padding: $btn-padding-y-lg * 1.5 $btn-padding-x;
        line-height: $btn-line-height-lg;
      }

      &:not(:active) {
        &:not(:hover) {
          &:not(:focus) {
            .btn {
              background: color-yiq($dark);
              border-color: color-yiq($dark);
              color: $dark;
            }
          }
        }
      }

      @include hover-focus-active() {
        .btn {
          background: $dark;
          border-color: $dark;
          color: $white;
        }
      }
    }

    .swiper-slide {
      width: 50%;
      pointer-events: none;

      &-active {
        .btn {
          animation-name: fadeInUp;
        }

        .carousel-app-item {
          opacity: 1;
          pointer-events: auto;

          .btn {

          }

          @include hover-focus-active() {
            .btn {
              opacity: 1;
            }
          }
        }

        + div {
          .carousel-app-item {
            opacity: 1;
            pointer-events: auto;
            @include hover-focus-active() {
              .btn {
                //animation-name: fadeInDown;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(sm) {
        width: 33.33%;

        &-active {
          @include hover-focus-active() {
            .btn {
              //animation-name: fadeInDown;
            }
          }

          + div,
          + div + div {
            .carousel-app-item {
              opacity: 1;
              pointer-events: auto;
              @include hover-focus-active() {
                .btn {
                  //animation-name: fadeInDown;
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        width: 25%;

        &-active {
          @include hover-focus-active() {
            .btn {
              //animation-name: fadeInDown;
            }
          }

          + div,
          + div + div,
          + div + div + div {
            .carousel-app-item {
              pointer-events: auto;
              opacity: 1;
              @include hover-focus-active() {
                .btn {
                  //animation-name: fadeInDown;
                }
              }
            }
          }
        }
      }
    }

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .carousel-app-content {

    [class*="swiper-button-"] {
      &:not(:disabled) {
        color: lighten($primary, 5%);
        i {
          color: lighten($primary, 5%);
        }
      }
    }
  }
}

/****************************************************************
 * Collection
 ****************************************************************/
.cms-widget {
  &-highlight_collection {
    padding-top: $cms-widget-inner-padding-y / 2;
    padding-bottom: $cms-widget-inner-padding-y / 2;

    .collection-carousel {

      .carousel-app-content {
        margin-bottom: 0;
      }

      .carousel-app-item {
        cursor: pointer;
        .collection-name {
          margin-bottom: 0;
          margin-top: .5rem;
          font-size: 1rem;

          strong {
            font-weight: bold;
            display: inline-block;
            text-transform: uppercase;
          }
        }

        .carousel-item-content {
          display: flex;
          flex-direction: column;
          margin-left: -$grid-gutter-width / 2;
          margin-right: -$grid-gutter-width / 2;

          .collection-picture {
            flex: 1 0 0;
            padding: $grid-gutter-width / 2;

            img {
              object-fit: cover;
            }

            .embed-responsive {
              height: 250px;
            }
          }

          .collection-product-list {

            .product-overlay {
              animation: none;
              opacity: 1;
              background: none;
              background-color: transparent;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;

              &-inner {
                background: none;
                background-color: transparent;
              }

              .btn-primary {
                border-radius: $border-radius-lg;
                padding: $btn-padding-y-lg * 1.5 $btn-padding-x;
                line-height: $btn-line-height-lg;
                font-size: 1rem;
                text-decoration: none;
              }
            }

            .embed-responsive-item {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;

              > div {
                flex: 0 0 50%;
                padding: $grid-gutter-width / 2;
              }
            }

            .is-ie & {
              .embed-responsive-item {
                & > div {
                  flex: 0 0 46%;
                  padding: 8px;
                }
              }
            }
          }

          @include media-breakpoint-up(sm) {
            flex-direction: row;
            .collection-product-list {
              max-width: 52%;
              flex: 0 0 52%;
              margin-left: auto;
            }

            .collection-picture {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-right: 10px;
              position: relative;

              .embed-responsive {
                height: 100%;
              }
            }
          }

          @include media-breakpoint-up(xl) {
            .collection-product-list {
              flex: 0 0 41%;
            }
          }
        }
      }
    }
  }
}


/****************************************************************
 * Divider
 ****************************************************************/
.cms-widget-divider {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}

/****************************************************************
 * Simple Highlight
 ****************************************************************/
.cms-widget-simple_highlight {
  padding: $spacer / 2;
  cursor: pointer;

  & + .cms-widget-simple_highlight {
    margin-top: $spacer / 2;
  }

  &.cms-widget-theme {
    &-default {
      background-color: $gray-100;
    }
  }

  .heading {
    text-align: center;

    .h1 {
      font-size: 2.133em!important;
      margin-bottom: $spacer / 3;
    }

    .h6 {
      font-size: 0.867em;
    }
  }

  .embed-responsive-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 60%;

      position: relative;
      bottom: 2rem;
      z-index: 6;
      margin: 0 $btn-padding-x-lg * 3;

      border-radius: $border-radius-lg;
      padding: $btn-padding-y-lg * 1.5 $btn-padding-x;
      line-height: $btn-line-height-lg;
    }

    & > img {
      transition: $animate-css-animation-duration filter linear;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &.placeholder {
        filter: blur(1rem);
      }

      &.picture {
        filter: blur(0);
      }
    }
  }

  @include hover-focus-active() {
    button {
      background: $dark;
      border-color: $dark;
      color: $white;
    }
  }
}

/****************************************************************
 * Réassurance
 ****************************************************************/
.cms-widget-reinsurance {
  .heading {
    text-align: center;
  }

  &-item-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
    border-bottom: 1px solid $gray-600;
    margin-bottom: $spacer;
    padding-bottom: $spacer / 2;

    .reinsurance-icon {
      margin-bottom: $spacer / 2;
      max-height: 28px;
    }

    .title {
      color: $primary;
      text-transform: uppercase;
      font-size: 0.933em;
      margin-bottom: $spacer / 2;
    }

    .content {
      font-size: 0.867em;
      text-align: center;
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &-item-list {
      flex-direction: row;
      justify-content: space-between;
    }

    &-item {
      border-bottom: 0 none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

/****************************************************************
 * Wysiwyg
 ****************************************************************/
.cms-widget {
  &-wysiwyg {
    img {
      max-width: 100%;
    }

    &.cms-widget-theme {
      &-dark {
        a {
          color: lighten($primary, 20%);
        }
      }
      &-light {
        a {
          color: $primary;
        }
      }
    }
  }
}

/****************************************************************
 * Video
 ****************************************************************/
.cms-widget {
  &-video {
    padding-bottom: 1rem;
    padding-top: .6rem;
    text-align: center;

    .heading {
      text-align: center;
    }

    .container {
      position: relative;
    }

    .btn-detail {
      @include font-size(1em);
      margin-top: $spacer * 1.1;
      padding: $btn-padding-y-lg * 1.5 $btn-padding-x-lg * 3;
      &.btn-primary {
        span {
          color: white!important;
        }
      }
    }

    .cms-widget-video-element {
      --app-cms-widget-video-ratio: 56.25%;

      overflow: hidden;
      pointer-events: none;
      position: relative;
      z-index: 0;

      &:before {
        display: block;
        padding-top: var(--app-cms-widget-video-ratio);
        content: "";
      }

      .cms-widget-video-element-container {
        position: absolute;
        transform: translate(-50%,-50%);
        width: 100%;
        top: 50%;
        left: 50%;
        z-index: 1;

        &:before {
          display: block;
          padding-top: var(--app-cms-widget-video-ratio);
          content: "";
        }

        & > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .cms-widget-video-element-overlay {
      display: none;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &.cms-widget-video-mode-background {
      .cms-widget-video-element-overlay {
        display: block;
      }

      &.with-link {
        .cms-widget-video-element-overlay,
        .cms-widget-video-element {
          pointer-events: initial;
          cursor: pointer;
        }
      }
    }
  }
}