.assistance-modal {
  .modal-header {
    justify-content: center;
    border: 0 none;
    padding-bottom: 0;

    .modal-title {
      flex: 1;
      font-family: $headings-variant-font-family;
      @include font-size($h3-hv-font-size);
      text-align: center;
    }
  }

  .modal-body {
    font-size: 0.913rem;
    padding-top: 0;
    padding-left: $modal-header-padding-x * 2;
    padding-right: $modal-header-padding-x * 2;

    .login-help-message {
      margin-top: $spacer;
    }
  }
}