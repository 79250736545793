.member-area-menu {
  .menu-item {
    cursor: pointer;
    background-color: $gray-dark;

    .menu-item-nav {
      transition: background-color ease-in $animate-css-animation-duration / 2;
      padding: $spacer;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      color: $white;

      .menu-item-icon {
        position: relative;

        .app-icon {
          transition: background-color ease-in $animate-css-animation-duration / 2;

          &.main-icon {
            font-size: 2rem;
          }

          &.sub-icon {
            line-height: 1;
            background-color: $gray-dark;
            font-size: 1rem;
            position: absolute;
            right: -.50rem;
            top: .1em;
          }
        }
      }

      .menu-item-title {
        margin-bottom: 0;
        font-size: 0.733em;
        text-transform: uppercase;
        text-align: center;
      }

      @include hover-focus-active() {
        background-color: $primary;

        .app-icon {
          &.sub-icon {
            background-color: $primary;
          }
        }
      }

      &.active {
        background-color: $primary;

        .app-icon {
          &.sub-icon {
            background-color: $primary;
          }
        }
      }
    }

    &-orders {
      .sub-icon {
        color: $success;
      }
    }

    &-favorites {
      .sub-icon {
        color: $danger;
      }
    }
  }
}